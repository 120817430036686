const specialCharactersMap = {
    'usdt': 'USD₮'
};
export const getSearchValue = (value) => {
    let modifiedValue = value.toLowerCase();
    Object.keys(specialCharactersMap).forEach(key => {
        if (modifiedValue.includes(key)) {
            modifiedValue = modifiedValue.replace(new RegExp(key, 'gi'), specialCharactersMap[key].toLowerCase());
        }
    });
    return modifiedValue;
};
