import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { Address } from 'ton-core';
import { Modal, getSearchValue } from '@/shared';
import { TokenList } from '../../../profile-modal';
import { AssetsSearch } from '../AssetsSearch';
import { selectAssetsModalStoreLoading, toggleSelectAssetsModal, toggleSelectAssetsModalLoading, } from '@/features/select-assets-modal/model';
import { swapToken1Store, swapToken2Store, isFromAsset, currentDexStore, } from '@/features/swap/model';
import { userBaseInfo } from '@/features/user/model';
import { tokensInfo } from '@/features/tokens/model';
export const AssetsModalInner = ({ isModalOpen }) => {
    const [assets, setAssets] = useState([]);
    const loading = useUnit(selectAssetsModalStoreLoading);
    const isFromAssetVal = useUnit(isFromAsset);
    const token1 = useUnit(swapToken1Store);
    const token2 = useUnit(swapToken2Store);
    const handleClose = () => {
        toggleSelectAssetsModal();
    };
    const userInfo = useUnit(userBaseInfo);
    const allTokensInfo = useUnit(tokensInfo);
    const currentDex = useUnit(currentDexStore);
    const [searchValue, setSearchValue] = useState('');
    const specificTonAddress = 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c';
    useEffect(() => {
        const getLocalAssets = () => {
            // setLocalLoading(true)
            const sourceTokens = currentDex === 'DeDust' ? allTokensInfo.dedustTokens : allTokensInfo.stonTokens;
            const finalAssets = sourceTokens.reduce((acc, token) => {
                if (token.symbol === 'TON') {
                    const hasSpecificTon = acc.some((existingToken) => existingToken.symbol === 'TON' && Address.parse(existingToken.address).toString() === Address.parse(specificTonAddress).toString());
                    if (token.address === specificTonAddress && !hasSpecificTon) {
                        acc.push(token);
                    }
                }
                else {
                    acc.push(token);
                }
                return acc;
            }, []).map((token) => {
                // const address_one = Address.parse(asset.tokenAddress[0]).toRawString()
                const addressTwo = Address.parse(token.address).toRawString();
                const userAsset = userInfo.assets.find((asset) => Address.parse(asset.tokenAddress[0]).toRawString() === addressTwo);
                const amount = userAsset ? userAsset.amount : '0';
                const amountUSD = userAsset ? userAsset.amountUSD : '0';
                const decimals = userAsset ? userAsset.decimals : 9;
                return {
                    token: token?.symbol,
                    tokenLogo: token?.cdnUrl ?? token?.imageUrl,
                    amount,
                    amountUSD,
                    priceUSD: (token.currentPrice ?? 0).toString(),
                    priceDiff: token?.changePrice?.diff_24h ?? '0',
                    tokenAddress: [token?.address],
                    decimals,
                };
            });
            setAssets(finalAssets);
            // console.log(finalAssets.find(token => token.token === 'TON'), 'finalAssets TON')
            toggleSelectAssetsModalLoading(false);
        };
        if (isModalOpen) {
            getLocalAssets();
        }
        else {
            toggleSelectAssetsModalLoading(true);
        }
    }, [isModalOpen]);
    const tokensData = searchValue.length !== 0
        ? assets?.filter((el) => {
            const token = el?.token?.toLowerCase();
            const searchVal = getSearchValue(searchValue);
            return token.includes(searchVal) || token.includes(searchValue.toLowerCase());
        }) ?? []
        : isFromAssetVal
            ? assets?.filter((el) => !el?.token?.toLowerCase()?.includes(token2?.token?.toLowerCase())) ?? []
            : assets?.filter((el) => !el?.token.toLowerCase()?.includes(token1?.token?.toLowerCase())) ?? [];
    return (_jsxs(Modal, { isOpen: isModalOpen, onClose: handleClose, title: "Select asset", children: [_jsx(AssetsSearch, { value: searchValue, setValue: setSearchValue }), _jsx(TokenList, { tokensData: tokensData, tokensLoading: assets === undefined || assets.length === 0 || loading, notFound: searchValue.length !== 0 && assets
                    ? assets?.filter((el) => el?.token?.toLowerCase()?.includes(searchValue.toLowerCase()))
                        ?.length === 0
                    : false })] }));
};
