/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { createEvent, createStore, combine } from 'effector';
import { Address } from 'ton';
import { updateDex, updateSwapToken1, updateSwapToken2 } from '../swap/model';
import { AggregatedTokensApiService } from '@/entities/token';
import { litePairs } from '@/shared/mock/lite-pairs';
const tokensApi = new AggregatedTokensApiService();
export const currentPairStore = createStore(null);
export const currentPairsStore = createStore(null);
export const currentMainAssetStore = createStore(null);
export const loadingDetailStore = createStore(false);
export const jettonDetailStore = createStore(null);
export const setPair = createEvent();
export const setPairs = createEvent();
export const setCurrentMainAsset = createEvent();
export const setLoadingDetail = createEvent();
export const setJettonDetail = createEvent();
currentPairStore.on(setPair, (_, newData) => newData);
currentPairsStore.on(setPairs, (_, newData) => newData);
currentMainAssetStore.on(setCurrentMainAsset, (_, newData) => newData);
loadingDetailStore.on(setLoadingDetail, (_, newData) => newData);
jettonDetailStore.on(setJettonDetail, (_, newData) => newData);
export const getTokenDetail = createEvent();
const parseAddress = (address) => Address.parse(address).toString();
const filterTokens = (allTokens, pairs) => {
    const tokenAddresses = new Set(pairs.flatMap(pair => [
        parseAddress(pair.token1?.address),
        parseAddress(pair.token2?.address)
    ]));
    return allTokens.filter(token => tokenAddresses.has(parseAddress(token.address)));
};
const findAssetByAddress = (assets, address) => assets.find(asset => parseAddress(asset.tokenAddress[0]) === parseAddress(address));
const createSortedPairs = (pairsPools, currentAsset) => pairsPools
    .filter(pool => pool.balanceUsd >= 50000)
    .sort((a, b) => b.balanceUsd - a.balanceUsd)
    .map(pool => ({
    token1: { ...currentAsset, availableDex: pool.dex },
    token2: { ...pool.token, availableDex: pool.dex },
    dex: pool.dex
}))
    .slice(0, 10);
const enhancePairsWithPrices = (sortedPairs, sortedTokensPairs) => sortedPairs.map(pair => {
    const findTokenPrice = (address) => sortedTokensPairs.find(token => parseAddress(token.address) === parseAddress(address))?.currentPrice ?? '0';
    const findTokenPriceChange = (address) => sortedTokensPairs.find(token => parseAddress(token.address) === parseAddress(address))?.changePrice ?? '0';
    return {
        ...pair,
        token1: {
            ...pair.token1,
            currentPrice: findTokenPrice(pair.token1.address),
            changePrice: findTokenPriceChange(pair.token1.address)
        },
        token2: {
            ...pair.token2,
            currentPrice: findTokenPrice(pair.token2.address),
            changePrice: findTokenPriceChange(pair.token2.address)
        }
    };
}).filter((pair, index, self) => self.findIndex(p => (p.token1.symbol === pair.token1.symbol && p.token2.symbol === pair.token2.symbol) ||
    (p.token1.symbol === pair.token2.symbol && p.token2.symbol === pair.token1.symbol)) === index);
const setTokens = (litePairsWithPrices, userInfo, tokenInfo) => {
    const token1Price = litePairsWithPrices.find(el => parseAddress(el.token1.address) === parseAddress(tokenInfo.token1.address))?.token1;
    const token1Amount = findAssetByAddress(userInfo, parseAddress(tokenInfo?.token1?.address));
    const firstToken = {
        token: tokenInfo.token1.token,
        tokenLogo: tokenInfo.token1.tokenLogo,
        amount: String(token1Amount?.amount) ?? '0',
        amountUSD: String((token1Price?.currentPrice * +(token1Amount?.amount ?? 0)).toFixed(2)),
        priceUSD: String(token1Price?.currentPrice),
        priceDiff: token1Price?.changePrice?.diff_24h ?? '0',
        tokenAddress: [parseAddress(tokenInfo.token1.address)],
        decimals: Number(token1Price?.decimals) ?? 9
    };
    const token2Price = litePairsWithPrices.find(el => parseAddress(el.token2.address) === parseAddress(el.token2.address))?.token2;
    const token2Amount = findAssetByAddress(userInfo, parseAddress(tokenInfo.token2.address));
    const secondToken = {
        token: tokenInfo.token2.token,
        tokenLogo: tokenInfo.token2.tokenLogo,
        amount: String(token2Amount?.amount) ?? '0',
        amountUSD: String((token1Price?.currentPrice * +(token1Amount?.amount ?? 0)).toFixed(2)),
        priceUSD: String(token2Price?.currentPrice),
        priceDiff: token2Price?.changePrice?.diff_24h ?? '0',
        tokenAddress: [parseAddress(tokenInfo.token2.address)],
        decimals: Number(token2Price?.decimals) ?? 9
    };
    updateSwapToken1(firstToken);
    updateSwapToken2(secondToken);
    updateDex(tokenInfo?.dex === 'STON' ? 'StonFi' : 'DeDust');
};
export const getDetailInfo = (jetton) => {
    const jettonHoldersCount = jetton?.address === 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c' ? 2117941 : jetton?.holdersCount;
    const jettonDescription = jetton?.address === 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c' ? ('A decentralized and open internet, created by the community using a technology designed by Telegram.') : jetton?.description;
    const jettonDetail = {
        logo: jetton?.imageUrl ?? jetton?.cdnUrl,
        title: jetton?.name,
        symbol: jetton?.symbol,
        description: jettonDescription ?? '',
        price: jetton?.currentPrice,
        totalSupply: jetton?.totalSupply,
        marketCap: jetton?.marketCap ?? 0,
        totalLiquidity: (jetton?.liquidity?.dedust ?? 0) + (jetton?.liquidity?.ston ?? 0),
        volume24h: Number(jetton?.volume?.dedust?.volume_24h ?? 0) + Number(jetton?.volume?.ston?.volume_24h ?? 0),
        address: jetton?.address,
        holdersCount: jettonHoldersCount ?? 0,
        percent: jetton?.changePrice?.diff_24h ?? '0'
    };
    setJettonDetail(jettonDetail);
};
const setJettonDetailInfo = (jetton, userInfo, jettonAddress, isLiteEnabled) => {
    getDetailInfo(jetton);
    const firstAsset = findAssetByAddress(userInfo, jettonAddress);
    const firstToken = {
        token: jetton?.symbol ?? 'unknow',
        tokenLogo: jetton?.imageUrl ?? jetton?.cdnUrl ?? 'unknow',
        amount: firstAsset?.amount ?? '0',
        amountUSD: firstAsset?.amountUSD ?? '0',
        priceUSD: (jetton?.currentPrice ?? 0).toString(),
        priceDiff: jetton?.changePrice?.diff_24h ?? '0',
        tokenAddress: [jettonAddress],
        decimals: Number(jetton?.decimals) ?? 9
    };
    if (!isLiteEnabled) {
        updateSwapToken1(firstToken);
        setCurrentMainAsset(firstToken);
    }
};
getTokenDetail.watch(async ({ jettonAddress, allTokens, userInfo, isLiteEnabled }) => {
    try {
        setLoadingDetail(true);
        let isLoaded = false;
        const loadLiteModeData = async () => {
            const pairs = litePairs;
            const pairsPools = await tokensApi.getTokenPairsData(jettonAddress);
            const currentAsset = allTokens.find(el => parseAddress(el.address) === parseAddress(jettonAddress));
            const sortedPairsByBalancesPools = createSortedPairs(pairsPools ?? [], currentAsset);
            // @ts-ignore
            const sortedTokensPairs = filterTokens(allTokens, [...sortedPairsByBalancesPools, ...pairs]);
            const litePairsWithPrices = enhancePairsWithPrices([...sortedPairsByBalancesPools, ...pairs], sortedTokensPairs);
            const tokenInfo = {
                token1: {
                    token: litePairsWithPrices[0]?.token1?.symbol,
                    address: litePairsWithPrices[0]?.token1?.address,
                    tokenLogo: litePairsWithPrices[0]?.token1?.cdnUrl ?? currentAsset?.imageUrl
                },
                token2: {
                    token: litePairsWithPrices[0].token2.symbol,
                    address: litePairsWithPrices[0].token2.address,
                    tokenLogo: litePairsWithPrices[0].token2.cdnUrl ?? litePairsWithPrices[0].token2?.imageUrl
                },
                dex: litePairsWithPrices[0].dex
            };
            setPairs(litePairsWithPrices);
            setTokens(litePairsWithPrices, userInfo, tokenInfo);
            return litePairsWithPrices.every((el) => el.token1);
        };
        const loadRegularModeData = async () => {
            const pairs = await tokensApi.getTokenPairsData(jettonAddress);
            if (pairs && pairs.length !== 0) {
                const sortedPairsByBalance = pairs.sort((a, b) => b.balanceUsd - a.balanceUsd);
                const pairWithMaxBalanceUsd = sortedPairsByBalance[0];
                setPairs(sortedPairsByBalance);
                setPair(sortedPairsByBalance[0]);
                const currentAsset = findAssetByAddress(userInfo, pairWithMaxBalanceUsd.token.address);
                const secondToken = {
                    token: pairWithMaxBalanceUsd.token.symbol,
                    tokenLogo: pairWithMaxBalanceUsd.token.cdnUrl ?? pairWithMaxBalanceUsd.token.imageUrl,
                    amount: currentAsset?.amount ?? '0',
                    amountUSD: currentAsset?.amountUSD ?? '0',
                    priceUSD: (pairWithMaxBalanceUsd.token.currentPrice ?? 0).toString(),
                    priceDiff: pairWithMaxBalanceUsd.token.changePrice?.diff_24h,
                    tokenAddress: [pairWithMaxBalanceUsd.token.address],
                    decimals: currentAsset?.decimals ?? 9
                };
                updateSwapToken2(secondToken);
                updateDex(pairWithMaxBalanceUsd.dex === 'STON' ? 'StonFi' : 'DeDust');
                return sortedPairsByBalance.every((el) => el.pool);
            }
            return false;
        };
        if (isLiteEnabled) {
            isLoaded = await loadLiteModeData();
        }
        else {
            isLoaded = await loadRegularModeData();
        }
        const jetton = allTokens.find(el => parseAddress(el.address) === parseAddress(jettonAddress));
        setJettonDetailInfo(jetton, userInfo, jettonAddress, isLiteEnabled);
        if (isLoaded) {
            setLoadingDetail(false);
        }
        else {
            console.error('Data not fully loaded');
            setLoadingDetail(false);
        }
    }
    catch (err) {
        console.error(err);
        setLoadingDetail(false);
    }
});
export const tokenDetail = combine({
    points: [],
    loadingDetail: loadingDetailStore,
    jettonDetail: jettonDetailStore,
    currentPair: currentPairStore,
    currentMainAsset: currentMainAssetStore
});
