import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { toggleLite } from '../../../../features/lite/model';
import s from './LiteToggler.module.scss';
export const LiteToggler = ({ isLiteEnabled }) => {
    return (_jsxs("div", { className: s.inner, onClick: () => toggleLite(), children: [_jsx("div", { className: s.innerTitle, children: "Pro mode" }), _jsx(motion.div, { className: s.toggler, style: {
                    justifyContent: isLiteEnabled ? 'flex-start' : 'flex-end',
                    backgroundColor: isLiteEnabled ? 'rgba(0, 0, 0, 0.05)' : ''
                }, animate: { justifyContent: isLiteEnabled ? 'flex-start' : 'flex-end' }, transition: { duration: 0.1 }, children: _jsx(motion.div, { className: `${s.togglerModel} ${isLiteEnabled ? s.togglerModelOff : ''}`, initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.1 } }) })] }));
};
