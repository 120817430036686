import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo } from 'react';
import CoinWhite from '@/shared/lib/images/coin-white.png';
import CoinBlack from '@/shared/lib/images/coin-dark.png';
import { SvgSelector } from '@/shared/lib/assets/svg-selector';
import s from './token-pair-lite.module.scss';
const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
};
export const TokenPairLite = ({ theme, updatePair, isActive = false, pair }) => {
    useEffect(() => {
        preloadImage(CoinWhite);
        preloadImage(CoinBlack);
    }, []);
    const token1Url = useMemo(() => pair?.token1?.cdnUrl ?? pair?.token1?.imageUrl, [pair?.token1]);
    const token2Url = useMemo(() => pair?.token2?.cdnUrl ?? pair?.token2?.imageUrl, [pair?.token2]);
    const handleError = (e) => {
        if (e.target.src !== (theme === 'dark' ? CoinBlack : CoinWhite)) {
            e.target.src = theme === 'dark' ? CoinBlack : CoinWhite;
            e.target.alt = theme === 'dark' ? 'Coin Black' : 'Coin White';
        }
    };
    return (_jsxs("div", { className: `${s.pair} ${isActive ? s.pairActive : ''}`, onClick: () => updatePair(pair), children: [_jsxs("div", { className: s.pairContent, children: [_jsxs("div", { className: s.pairLeft, children: [_jsx("img", { src: token1Url, onError: handleError, alt: 'token1' }), _jsx("img", { src: token2Url, onError: handleError, alt: 'token2' })] }), _jsx("div", { className: s.pairBody, children: `${pair?.token1?.symbol}/${pair?.token2?.symbol}` })] }), _jsx(SvgSelector, { id: "chevron-right" })] }));
};
export const MemoizedPairsItem = memo(TokenPairLite);
