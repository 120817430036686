export const litePairs = [
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            name: 'DFC',
            symbol: 'DFC',
            imageUrl: 'https://i.tonscan.org/resize/zLizDLindVxRBw4DMg6-fBHQqi3EgyRe2Rs-oUGmOGU/f:jpg/rs:fit:72:72:0/aXBmczovL1FtWFFvakpVUHZrR0NDZVI5UXU4V3dtY1pGMWdMRFkyOGVwTExoUFl2RHk4VGs.jpg',
            cdnUrl: 'https://i.tonscan.org/resize/zLizDLindVxRBw4DMg6-fBHQqi3EgyRe2Rs-oUGmOGU/f:jpg/rs:fit:72:72:0/aXBmczovL1FtWFFvakpVUHZrR0NDZVI5UXU4V3dtY1pGMWdMRFkyOGVwTExoUFl2RHk4VGs.jpg',
            address: 'EQD26zcd6Cqpz7WyLKVH8x_cD6D7tBrom6hKcycv8L8hV0GP',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'DEDUST',
    },
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON'],
        },
        token2: {
            name: 'Tether USD',
            symbol: 'USDT',
            imageUrl: 'https://asset.ston.fi/img/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs.png',
            address: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            decimals: 6,
            availableDex: ['STON'],
        },
        dex: 'STON',
    },
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON'],
        },
        token2: {
            id: 2775,
            name: 'Scaleton',
            symbol: 'SCALE',
            imageUrl: 'https://asset.ston.fi/img/EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE.png',
            address: 'EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'DEDUST',
    },
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            name: 'Ton Raffles Token',
            symbol: 'RAFF',
            imageUrl: 'https://asset.ston.fi/img/EQCJbp0kBpPwPoBG-U5C-cWfP_jnksvotGfArPF50Q9Qiv9h',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQCJbp0kBpPwPoBG-U5C-cWfP_jnksvotGfArPF50Q9Qiv9h.png',
            address: 'EQCJbp0kBpPwPoBG-U5C-cWfP_jnksvotGfArPF50Q9Qiv9h',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'STON',
    },
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            id: 3530,
            name: 'KINGYTON',
            symbol: 'KINGY',
            imageUrl: 'https://asset.ston.fi/img/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ.png',
            address: 'EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'DEDUST',
    },
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            name: 'ANON',
            symbol: 'ANON',
            imageUrl: 'https://asset.ston.fi/img/EQDv-yr41_CZ2urg2gfegVfa44PDPjIK9F-MilEDKDUIhlwZ',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQDv-yr41_CZ2urg2gfegVfa44PDPjIK9F-MilEDKDUIhlwZ.png',
            address: 'EQDv-yr41_CZ2urg2gfegVfa44PDPjIK9F-MilEDKDUIhlwZ',
            decimals: 9,
            availableDex: ['STON'],
        },
        dex: 'STON',
    },
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            name: 'JetTon',
            symbol: 'JETTON',
            imageUrl: 'https://asset.ston.fi/img/EQAQXlWJvGbbFfE8F3oS8s87lIgdovS455IsWFaRdmJetTon',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQAQXlWJvGbbFfE8F3oS8s87lIgdovS455IsWFaRdmJetTon.png',
            address: 'EQAQXlWJvGbbFfE8F3oS8s87lIgdovS455IsWFaRdmJetTon',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'DEDUST',
    },
    {
        token1: {
            name: 'STON',
            symbol: 'STON',
            cdnUrl: 'https://asset.ston.fi/img/EQA2kCVNwVsil2EM2mB0SkXytxCqQjS4mttjDpnXmwG9T6bO',
            imageUrl: 'https://asset.ston.fi/img/EQA2kCVNwVsil2EM2mB0SkXytxCqQjS4mttjDpnXmwG9T6bO',
            address: 'EQA2kCVNwVsil2EM2mB0SkXytxCqQjS4mttjDpnXmwG9T6bO',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            name: 'TON',
            symbol: 'TON',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            cdnUrl: null,
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'STON',
    },
    {
        token1: {
            token: 'KINGY',
            symbol: 'KINGY',
            cdnUrl: 'https://asset.ston.fi/img/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            imageUrl: 'https://asset.ston.fi/img/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            address: 'EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            decimals: 9,
        },
        token2: {
            name: 'TON',
            symbol: 'TON',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            cdnUrl: null,
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'DEDUST',
    },
    {
        token1: {
            token: 'KINGY',
            symbol: 'KINGY',
            cdnUrl: 'https://asset.ston.fi/img/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            imageUrl: 'https://asset.ston.fi/img/EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            address: 'EQC-tdRjjoYMz3MXKW4pj95bNZgvRyWwZ23Jix3ph7guvHxJ',
            decimals: 9,
        },
        token2: {
            name: 'Tether USD',
            symbol: 'USD₮',
            imageUrl: 'https://asset.ston.fi/img/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs.png',
            address: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            decimals: 6,
            availableDex: ['STON'],
        },
        dex: 'DEDUST',
    },
    {
        token1: {
            name: 'Tether USD',
            symbol: 'USD₮',
            imageUrl: 'https://asset.ston.fi/img/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs.png',
            address: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            decimals: 6,
            availableDex: ['STON'],
        },
        token2: {
            name: 'TON',
            symbol: 'TON',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            cdnUrl: null,
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'STON',
    },
    {
        token1: {
            name: 'Tether USD',
            symbol: 'USD₮',
            imageUrl: 'https://asset.ston.fi/img/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs.png',
            address: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            decimals: 6,
            availableDex: ['STON'],
        },
        token2: {
            name: 'Ton Raffles Token',
            symbol: 'RAFF',
            imageUrl: 'https://asset.ston.fi/img/EQCJbp0kBpPwPoBG-U5C-cWfP_jnksvotGfArPF50Q9Qiv9h',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQCJbp0kBpPwPoBG-U5C-cWfP_jnksvotGfArPF50Q9Qiv9h.png',
            address: 'EQCJbp0kBpPwPoBG-U5C-cWfP_jnksvotGfArPF50Q9Qiv9h',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'STON',
    },
    {
        token1: {
            token: 'SCALE',
            symbol: 'SCALE',
            imageUrl: 'https://asset.ston.fi/img/EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE',
            cdnUrl: 'https://asset.ston.fi/img/EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE',
            address: 'EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            name: 'Tether USD',
            symbol: 'USD₮',
            imageUrl: 'https://asset.ston.fi/img/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            cdnUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/tokens-images/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs.png',
            address: 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs',
            decimals: 6,
            availableDex: ['STON'],
        },
        dex: 'DEDUST',
    },
    {
        token1: {
            name: 'TON',
            symbol: 'TON',
            cdnUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            imageUrl: 'https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            address: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        token2: {
            name: 'GRAM',
            symbol: 'GRAM',
            cdnUrl: 'https://asset.ston.fi/img/EQC47093oX5Xhb0xuk2lCr2RhS8rj-vul61u4W2UH5ORmG_O',
            imageUrl: 'https://asset.ston.fi/img/EQC47093oX5Xhb0xuk2lCr2RhS8rj-vul61u4W2UH5ORmG_O',
            address: 'EQC47093oX5Xhb0xuk2lCr2RhS8rj-vul61u4W2UH5ORmG_O',
            decimals: 9,
            availableDex: ['STON', 'DEDUST'],
        },
        dex: 'STON',
    },
];
