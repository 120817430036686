import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import { useUnit } from 'effector-react';
import { NumberRenderer } from '../NumberRenderer';
import { BtnVariant, Button } from '../Button';
import { TokenImg } from '../TokenImg';
import { tokensInfo } from '@/features/tokens/model';
import { smlTokenAddr, formatNumberAmount, formatLargeNumber, convertTokensFormat } from '../../../utils';
import { themeStore } from '@/features/theme/model';
import { SvgSelector } from '@/shared/lib/assets/svg-selector';
import s from './MainTokensTable.module.scss';
import { AggregatedTokensApiService } from '@/entities/token';
import { useQuery } from '@tanstack/react-query';
export const MainTokensTable = () => {
    const allData = useUnit(tokensInfo);
    const tableData = allData.finalTableTokens;
    const [skip, setSkip] = useState(1);
    const [tokens, setTokens] = useState([]);
    const aggregatedTokensApiService = new AggregatedTokensApiService();
    const [visibleTokenCount, setVisibleTokenCount] = useState(20);
    const { data: maxScore } = useQuery({
        queryKey: ['topScore'],
        queryFn: () => aggregatedTokensApiService.send('tokens/top/score', {})
    });
    const { data, error, isLoading } = useQuery({
        queryKey: ['finalTableTokens', skip, visibleTokenCount],
        queryFn: async () => {
            const result = await aggregatedTokensApiService.send('tokens/getTokens', {
                queryMeta: {
                    paginate: true,
                    skip: skip,
                    take: visibleTokenCount,
                    order: {
                        totalLiquidity: 'ASC'
                    }
                }
            });
            if (result?.data) {
                // Append new data to tokens array
                setTokens((prevTokens) => [...(prevTokens || []), ...convertTokensFormat(result.data, maxScore?.score)]);
            }
            return {
                meta: result.meta,
                data: convertTokensFormat(result.data, maxScore?.score)
            };
        },
        enabled: true, // Control when to enable/disable fetching
    });
    const currentTheme = useUnit(themeStore);
    const [sorting, setSorting] = useState([]);
    const navigate = useNavigate();
    const columns = useMemo(() => [
        {
            header: () => 'Token',
            accessorKey: 'token',
            cell: (info) => {
                const data = info.getValue();
                return (_jsxs("div", { className: s.token, children: [_jsx(TokenImg, { imageUrl: data?.imageUrl, className: s.token_image }), _jsxs("div", { className: s.token_info, children: [_jsx("div", { className: s.title, children: data?.name }), _jsx("div", { className: s.address, children: smlTokenAddr(data?.address) })] })] }));
            },
        },
        {
            header: () => 'Price',
            accessorKey: 'price',
            cell: (info) => {
                const data = info.getValue();
                return (_jsxs("div", { className: s.price, children: ["$", _jsx(NumberRenderer, { value: data })] }));
            },
        },
        {
            header: () => 'Period 24h',
            accessorKey: 'percent',
            cell: (info) => {
                const data = String(info.getValue());
                return (_jsxs("div", { className: s.percent, style: {
                        color: data?.startsWith('−') || data?.startsWith('-')
                            ? '#FF1C45'
                            : '#10B981',
                    }, children: [data?.startsWith('−') || data?.startsWith('-') ? (_jsx(SvgSelector, { id: "percent-down" })) : (_jsx(SvgSelector, { id: "percent-up" })), ' ', data.replace('-', '').replace('−', '')] }));
            },
        },
        {
            header: () => 'Market cap',
            accessorKey: 'marketCap',
            cell: (info) => {
                const data = info.getValue();
                return _jsxs("div", { className: s.marketCap, children: ["$", formatLargeNumber(data)] });
            },
        },
        {
            header: () => 'Total Liquidity',
            accessorKey: 'totalLiquidity',
            cell: (info) => {
                const data = info.getValue();
                return _jsxs("div", { className: s.totalLiquidity, children: ["$", formatLargeNumber(data)] });
            },
        },
        {
            header: () => 'Volume 24h',
            accessorKey: 'volume',
            cell: (info) => {
                const data = info.getValue();
                const volume = formatNumberAmount(info.getValue());
                return (_jsx("div", { className: s.volume, children: _jsxs("div", { className: s.amount, children: ["$", formatLargeNumber(Number(volume))] }) }));
            },
        },
        // {
        //     header: () => 'Score',
        //     accessorKey: 'score',
        //     cell: (info: any) => {
        //         const data = info.getValue()
        //         const val = (data / 100) * 100
        //         const deg = (180 / 100) * val
        //         const getScoreClass = (score: number) => {
        //             if (score >= 80) {
        //                 return '#10B981'
        //             }
        //             if (score >= 30) {
        //                 return '#FB923C'
        //             }
        //             return '#FF1C45'
        //         }
        //         const getScoreClassBg = (score: number) => {
        //             if (score >= 80) {
        //                 return '#D1FAE5'
        //             }
        //             if (score >= 30) {
        //                 return '#FFEDD5'
        //             }
        //             return '#FECACA'
        //         }
        //         return (
        //             <div className={s.indicator}>
        //                 <span
        //                     className={s.bar}
        //                     style={{
        //                         transform: `rotate(${deg}deg)`,
        //                         borderColor: getScoreClass(data)
        //                     }}
        //                 />
        //                 <span
        //                     className={s.barBg}
        //                     style={{ border: `9px solid ${getScoreClassBg(data)}` }}
        //                 ></span>
        //                 <div className={classNames(s.result, 'fz16')}>
        //                     {isNaN(data) ? '0' : data.toFixed(2).replace(/\.00$/, '')}
        //                 </div>
        //             </div>
        //         )
        //     }
        // }
    ], [skip]);
    const table = useReactTable({
        data: tokens,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });
    useEffect(() => { }, [skip]);
    const parentRef = React.useRef(null);
    const loadMoreTokens = () => {
        // setVisibleTokenCount((prevCount) => prevCount + 20);
        setSkip((prevCount) => prevCount + 1);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: parentRef, className: s.showScrollbar, children: _jsxs("table", { className: s.table, children: [_jsx("thead", { className: s.thead, children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { className: s.tr, children: headerGroup.headers.map((header) => (_jsx("th", { className: s.th, colSpan: header.colSpan, style: { width: header.getSize() }, children: _jsxs("div", { ...{
                                            className: header.column.getCanSort() ? '' : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                        }, children: [flexRender(header.column.columnDef.header, header.getContext()), {
                                                asc: ' ▲',
                                                desc: ' ▼',
                                            }[header.column.getIsSorted()] ?? null] }) }, header.id))) }, headerGroup.id))) }), _jsx("tbody", { className: s.tbody, children: table
                                .getRowModel()
                                .rows
                                .map((row) => (_jsx("tr", { className: s.tr, children: row.getVisibleCells().map((cell) => (_jsx("td", { className: s.td, onClick: () => {
                                        const rowData = cell.row.original;
                                        navigate(`token/${rowData.token.address}`);
                                    }, children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id))) }, row.id))) })] }) }), skip < Math.ceil(data?.meta?.count / visibleTokenCount) && (_jsx(Button, { variant: BtnVariant.light, onClick: loadMoreTokens, className: s.loadMoreBtn, children: "Load more" }))] }));
};
