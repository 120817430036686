import { createEvent, createStore, combine } from 'effector';
import { Address, fromNano } from 'ton-core';
import { UserApiService } from '../../entities/user/api/userApi';
import { tokensData } from '../../shared/mock/jettons';
export const updateTokensLoading = createEvent();
export const updateUserAssets = createEvent();
export const updateTotalBalance = createEvent();
export const tokensLoadingStore = createStore(true);
export const userAssetsStore = createStore([]);
export const totalBalanceStore = createStore('');
tokensLoadingStore.on(updateTokensLoading, (_, isLoading) => isLoading);
userAssetsStore.on(updateUserAssets, (_, newAssets) => newAssets);
totalBalanceStore.on(updateTotalBalance, (_, newBalance) => newBalance);
export const getUserData = createEvent();
const tokenApi = new UserApiService();
getUserData.watch(async (userAddress) => {
    try {
        // setTokensLoading(true)
        // console.log('START UPDATE USER ASSETS')
        updateTokensLoading(true);
        const res = await tokenApi.getAllJettonsBalances(userAddress);
        const tokenAddressesArr = tokensData?.map(item => item?.tokenAddress[0]);
        const tonInfo = await tokenApi.getHumanFriendlyInfo(userAddress);
        const tokenAddresses = res?.balances?.map(el => el?.jetton?.address);
        const missingAddresses = tokenAddressesArr.filter(addr => !tokenAddresses?.includes(addr));
        const allTokenAddresses = [...tokenAddresses ?? '', ...missingAddresses];
        const priceRes = await tokenApi.getTokenPrice(allTokenAddresses, 'usd');
        const tonPrice = await tokenApi.getTokenPrice('ton', 'usd');
        const price = {
            price: tonPrice?.rates?.TON?.prices?.USD,
            diff: tonPrice?.rates?.TON?.diff_24h?.USD
        };
        const updatedTokens = tokensData?.map((el) => {
            if (el.token === 'TON') {
                return {
                    ...el,
                    amount: fromNano(tonInfo?.balance ?? 0),
                    priceUSD: price.price,
                    priceDiff: price.diff,
                    amountUSD: (price.price * Number(fromNano(tonInfo?.balance ?? 0).toString())).toFixed(2)
                };
            }
            // eslint-disable-next-line no-prototype-builtins
            const matchingAddresses = tokenAddressesArr?.filter(el3 => priceRes?.rates?.hasOwnProperty(Address.parse(el3).toString()));
            // eslint-disable-next-line no-restricted-syntax
            for (const matchingAddress of matchingAddresses) {
                if (Address.parse(matchingAddress).toString() === Address.parse(el.tokenAddress[0]).toString()) {
                    const matchingBalance = priceRes?.rates[Address.parse(matchingAddress).toString()]?.prices?.USD;
                    const diffRate = priceRes?.rates[Address.parse(matchingAddress).toString()]?.diff_24h?.USD;
                    const tokenPrice = res?.balances.find(el4 => Address.parse(el4?.jetton?.address ?? '').toString() === Address.parse(matchingAddress).toString());
                    return {
                        ...el,
                        amount: String((Number(tokenPrice?.balance ?? 0) / (10 ** Number(tokenPrice?.jetton?.decimals ?? 9)))),
                        // fromNano(tokenPrice?.balance ?? 0),
                        priceUSD: matchingBalance,
                        priceDiff: diffRate,
                        amountUSD: (parseFloat((Number(tokenPrice?.balance ?? 0) / (10 ** Number(tokenPrice?.jetton?.decimals ?? 9))).toString())
                            * parseFloat(matchingBalance)).toFixed(2),
                        decimals: tokenPrice?.jetton?.decimals ?? 9
                    };
                }
            }
            return el;
        });
        const additionalTokens = res?.balances
            ?.filter(el => el?.jetton?.address
            && !tokenAddressesArr?.includes(el?.jetton.address ?? '')
            && !updatedTokens.some(updatedToken => Address.parse(updatedToken.tokenAddress[0]).toString() === Address.parse(el?.jetton?.address ?? '').toString()))
            .map((el) => {
            const additionalTokenPrice = priceRes?.rates[Address.parse(el?.jetton?.address ?? '').toString()]?.prices?.USD ?? '0';
            const diffRate = priceRes?.rates[Address.parse(el?.jetton?.address ?? '').toString()]?.diff_24h?.USD;
            return {
                token: el?.jetton?.symbol ?? '',
                tokenLogo: el?.jetton?.image ?? '',
                amount: String((Number(el?.balance ?? 0) / (10 ** Number(el?.jetton?.decimals ?? 9)))),
                priceDiff: diffRate,
                priceUSD: additionalTokenPrice,
                amountUSD: (parseFloat((Number(el?.balance ?? 0) / (10 ** Number(el?.jetton?.decimals ?? 9))).toString())
                    * parseFloat(additionalTokenPrice)).toFixed(2),
                tokenAddress: [el?.jetton?.address ?? ''],
                decimals: el?.jetton?.decimals ?? 9
            };
        }) || [];
        const finalUpdatedTokens = [...updatedTokens, ...additionalTokens];
        const finalTotalBalance = finalUpdatedTokens.reduce((acc, el) => acc + parseFloat(el.amountUSD), 0).toFixed(2);
        // setTokens(finalUpdatedTokens)
        // console.log('finalUpdatedTokens', finalUpdatedTokens)
        updateUserAssets(finalUpdatedTokens);
        updateTotalBalance(finalTotalBalance);
    }
    catch (error) {
        console.error('Error fetching jettons:', error);
    }
    finally {
        // setTokensLoading(false)
        updateTokensLoading(false);
    }
});
export const userBaseInfo = combine({
    assets: userAssetsStore,
    loading: tokensLoadingStore,
    totalBalance: totalBalanceStore
});
