/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import axios from 'axios';
import QueryString from 'qs';
export class AggregatedTokensApiService {
    constructor() {
        this._url = 'https://aggregator-back.delab.team/';
    }
    // private _url: string = 'http://localhost:4000/'
    async send(url, query) {
        console.log(query, "7488989");
        const { data } = await axios.get(`${this._url}${url}?${QueryString.stringify(query)}`);
        if (data.error) {
            console.error(data.result);
            return undefined;
        }
        return data;
    }
    async getTokensData() {
        const data = await this.send('tokens/getTokens', []);
        return data;
    }
    async getTokenPairsData(tokenAddress) {
        const data = await this.send('tokens/getPools', { address: tokenAddress });
        return data.pairs;
    }
    async updateTokenImages() {
        const data = await this.send('updateTokenImages', {});
        return data;
    }
}
