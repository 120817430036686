import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useTonConnectUI, useTonAddress } from 'delab-tonconnect-ui-react';
import { Address } from 'ton';
import { useMediaQuery } from 'react-responsive';
import { SvgSelector } from '../../../lib/assets/svg-selector';
import { UserApiService } from '../../../../entities/user/api/userApi';
import { toggleProfileModal } from '../../../../features/profile-modal/model';
import { getUserData, } from '../../../../features/user/model';
import { smlAddr } from '../../../utils/smlAddr';
import s from './TonConnectButton.module.scss';
export const TonConnectButton = () => {
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const address = useTonAddress();
    const tokenApi = new UserApiService();
    const isTablet = useMediaQuery({ query: '(max-width: 600px)' });
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://raw.githubusercontent.com/delab-team/manifests-images/main/DeLabLogo.png',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    const clickHandler = () => {
        if (!tonConnectUI.connected) {
            tonConnectUI.openModal();
        }
        else {
            // tonConnectUI.disconnect()
            toggleProfileModal();
        }
    };
    const rawAddress = useTonAddress();
    useEffect(() => {
        if (rawAddress && tonConnectUI.connected) {
            getUserData(rawAddress);
        }
    }, [rawAddress, tonConnectUI.connected]);
    return (_jsxs("div", { className: `${s.item} ${!tonConnectUI.connected ? s.itemConnected : ''}`, onClick: clickHandler, children: [tonConnectUI.connected && (_jsx("div", { className: s.icon, style: { marginLeft: !isTablet ? '-6px' : '' }, children: _jsx(SvgSelector, { id: "user" }) })), tonConnectUI.connected
                ? !isTablet && smlAddr(Address.parse(address).toString({ bounceable: false }))
                : 'Connect Wallet'] }));
};
