import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable import/no-default-export */
/* eslint-disable no-empty */
/* eslint-disable new-cap */
/* eslint-disable no-multi-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-tabs */
import { useEffect, useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import Datafeed from './datafeeds';
import { tokenDetail } from '@/features/token-detail/model';
import { themeStore } from '@/features/theme/model';
import s from './TokenChart.module.scss';
let tvScriptLoadingPromise;
//--tv-color-toolbar-button-background-hover: white;
//--tv-color-toolbar-button-background-expanded: #F5F5F7;
//--tv-color-toolbar-button-background-active: #F5F5F7;
//--tv-color-toolbar-button-background-active-hover: #F5F5F7;
const customCSSDark = `:root:not(.theme-dark) {
	--tv-color-platform-background: rgba(128, 129, 133, 0.16);
	--tv-color-pane-background: white;

	--tv-color-toolbar-button-text: #04060B;
	--tv-color-toolbar-button-text-hover: #04060B;
	--tv-color-toolbar-button-text-active: #00A0F0;
	--tv-color-toolbar-button-text-active-hover: #00A0F0;
	--tv-color-item-active-text: red;
	--tv-color-toolbar-toggle-button-background-active: #E8E9ED;
	--tv-color-toolbar-toggle-button-background-active-hover: #E8E9ED;
	--tv-color-toolbar-divider-background: rgba(128, 129, 133, 0.16);
}`;
const customCSSLight = `.theme-dark:root {
	--tv-color-platform-background: rgba(128, 129, 133, 0.16);
	--tv-color-pane-background: rgb(39, 43, 47);

	--tv-color-toolbar-button-text: #F1F1F5;
	--tv-color-toolbar-button-text-hover: #F1F1F5;
	--tv-color-toolbar-button-text-active: #0096E0;
	--tv-color-toolbar-button-text-active-hover: #0096E0;
	--tv-color-item-active-text: #F1F1F5;
	--tv-color-toolbar-toggle-button-background-active: #1E1E1F;
	--tv-color-toolbar-toggle-button-background-active-hover: #0F0F0F;
	--tv-color-toolbar-divider-background: rgba(176, 178, 184, 0.16);
}
`;
export const Chart = (props) => {
    const { id = 'tradingview_cb40e', symbols = 'GRAM', address = '' } = props;
    const { loadingDetail, currentPair } = useUnit(tokenDetail);
    const theme = useUnit(themeStore);
    const onLoadScriptRef = useRef();
    const widgetRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const cssBlobLight = new Blob([customCSSLight], { type: 'text/css' });
    const cssBlobUrlLight = URL.createObjectURL(cssBlobLight);
    const cssBlobDark = new Blob([customCSSDark], { type: 'text/css' });
    const cssBlobUrlDark = URL.createObjectURL(cssBlobDark);
    const getPair = (metadata) => {
        if (!metadata)
            return null;
        const baseTokenAddr = currentPair?.token.address;
        return {
            firstToken: {
                address: baseTokenAddr === metadata.base.address ? metadata.base.address : metadata.quote.address,
                name: baseTokenAddr === metadata.base.address ? metadata.base.name : metadata.quote.name,
                symbol: baseTokenAddr === metadata.base.address ? metadata.base.symbol : metadata.quote.symbol,
                type: baseTokenAddr === metadata.base.address ? 'quote' : 'base',
            },
            secondToken: {
                address: baseTokenAddr === metadata.base.address ? metadata.quote.address : metadata.base.address,
                name: baseTokenAddr === metadata.base.address ? metadata.quote.name : metadata.base.name,
                symbol: baseTokenAddr === metadata.base.address ? metadata.quote.symbol : metadata.base.symbol,
                type: baseTokenAddr === metadata.base.address ? 'base' : 'quote',
            },
        };
    };
    // @ts-ignore
    useEffect(() => {
        onLoadScriptRef.current = createWidget;
        if (!tvScriptLoadingPromise) {
            tvScriptLoadingPromise = new Promise((resolve) => {
                const script = document.createElement('script');
                script.id = 'tradingview-widget-loading-script';
                script.src = 'https://ton.app/tradingview/charting_library/charting_library.standalone.js';
                script.type = 'text/javascript';
                script.onload = resolve;
                document.head.appendChild(script);
            });
        }
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
        // @ts-ignore
        return () => (onLoadScriptRef.current = null);
        function createWidget() {
            if (!props.data?.meta) {
                return;
            }
            const pair = getPair(props.data?.meta);
            const quoteName = pair?.firstToken.symbol || 'NOT_FOUND';
            const baseName = pair?.secondToken.symbol || 'NOT_FOUND';
            const name = `${baseName}:${quoteName}`;
            if (document.getElementById(id) && 'TradingView' in window) {
                // @ts-ignore
                const widget = (window.tvWidget = new window.TradingView.widget({
                    debug: true,
                    autosize: true,
                    symbol: name,
                    interval: '15',
                    timeframe: '15',
                    container: id,
                    //@ts-ignore
                    datafeed: Datafeed({
                        address: currentPair?.pool || '',
                        type: pair?.firstToken.type || 'base',
                        currency: 'token'
                    }, {
                        mainConfig: {
                            supports_search: true,
                            supports_timescale_marks: false,
                            supports_group_request: false,
                            supports_marks: false,
                            supports_time: true,
                            exchanges: [{ "value": "DEDUST", "name": "DeDust", "desc": "DeDust" }],
                            symbols_types: [{ "value": "crypto", "name": "Cryptocurrency" }],
                            supported_resolutions: ["1", "5", "15", "60", "240", '3600', '7200', "43200", "1440"],
                        },
                        symbolsConfig: {
                            ticker: name,
                            name,
                            description: name,
                            type: 'Jetton',
                            session: '24x7',
                            timezone: 'Etc/UTC'
                        }
                    }),
                    library_path: 'https://ton.app/tradingview/charting_library/',
                    locale: 'en',
                    disabled_features: ['use_localstorage_for_settings', 'header_symbol_search'],
                    charts_storage_url: 'https://saveload.tradingview.com',
                    charts_storage_api_version: '1.1',
                    client_id: 'tradingview.com',
                    user_id: 'public_user_id',
                    theme: theme == 'light' ? 'light' : 'dark',
                    study_count_limit: 1000,
                    custom_css_url: theme == 'light' ? cssBlobUrlDark : cssBlobUrlLight,
                    loading_screen: {
                        backgroundColor: theme == 'light' ? '#FFFFFF' : 'rgb(39, 43, 47)',
                        foregroundColor: theme == 'light' ? '#FFFFFF' : 'rgb(39, 43, 47);'
                    },
                    overrides: {
                        'paneProperties.background': theme == 'light' ? '#FFFFFF' : 'rgb(39, 43, 47)',
                        'paneProperties.backgroundType': 'solid',
                        'mainSeriesProperties.minTick': '1000000000,1,false'
                    },
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }));
                widget.onChartReady(() => {
                    setIsLoaded(true);
                    widgetRef.current = widget;
                });
            }
        }
    }, [props.data, theme]);
    useEffect(() => {
        if (!isLoaded) {
            return;
        }
        const widget = widgetRef.current;
        if (!widget || typeof widget?.activeChart !== 'function') {
        }
    }, [isLoaded]);
    return (_jsx("div", { id: id, className: s.chartTradingView }));
};
export default Chart;
