import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { SvgSelector } from '../../../lib/assets/svg-selector';
import s from './Modal.module.scss';
export const Modal = ({ children, title, onClose, isOpen, className, buttonClass, isCloseButton = true }) => {
    const modalRef = useRef(null);
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    const handleCloseModal = () => {
        onClose();
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleCloseModal();
            }
        };
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);
    return (_jsx(AnimatePresence, { children: isOpen && (_jsx(motion.div, { className: s.modalBackdrop, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }, children: _jsxs(motion.div, { className: `${s.modalContent} ${className || ''}`, ref: modalRef, initial: { y: isMobile ? '100%' : 0, opacity: isMobile ? 1 : 0, scale: !isMobile ? 0.8 : 1 }, animate: { y: 0, opacity: 1, scale: 1 }, transition: {
                    duration: 0.4,
                    ease: 'easeOut'
                }, children: [_jsxs("div", { className: s.modalTop, children: [_jsx("h2", { children: title }), isCloseButton && (_jsx("button", { className: `${s.closeButton} ${buttonClass || ''}`, onClick: onClose, children: _jsx(SvgSelector, { id: "close" }) }))] }), children] }) })) }));
};
