import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { v1 } from 'uuid';
import { DedustApi } from '@/entities/swap/dedust/dedust-api';
import { TokenApiService } from '@/entities/token/api/tokenApi';
import { TopTokenCard } from '@/shared/ui/components';
import 'swiper/css/autoplay';
import s from './TopTokensList.module.scss';
import { AggregatedTokensApiService } from '@/entities/token';
import { useQuery } from '@tanstack/react-query';
const mockTopTokens = ['TON', 'DFC', 'ARBUZ', 'WEB3', 'KINGY', 'PROTON', 'jUSDT'];
export const TopTokensList = () => {
    const dedustApi = new DedustApi();
    const tokenApi = new TokenApiService();
    const aggregatedTokensApiService = new AggregatedTokensApiService();
    const { data, error, isLoading } = useQuery({
        queryKey: ['top7Tokens'],
        queryFn: () => aggregatedTokensApiService.send('tokens/getTokens', {
            queryMeta: {
                paginate: true,
                skip: 1,
                take: 7,
                order: {
                    ['totalLiquidity']: 'DESC',
                    ['score']: 'DESC',
                }
            }
        })
    });
    return (_jsx("div", { className: s.tokens_list, children: _jsx(Swiper, { spaceBetween: 30, modules: [Autoplay], autoplay: { delay: 3000, reverseDirection: true }, slidesPerView: 2, loop: true, breakpoints: {
                200: { slidesPerView: 1.6 },
                380: { slidesPerView: 2 },
                470: { slidesPerView: 2.5 },
                560: { slidesPerView: 3 },
                769: { slidesPerView: 3 },
                994: { slidesPerView: 4 },
                1201: { slidesPerView: 5 },
                1515: { slidesPerView: 6 },
                1801: { slidesPerView: 7 }
            }, allowTouchMove: true, children: data?.data
                ? data.data.map((token, i) => (_jsx(SwiperSlide, { children: _jsx(TopTokenCard, { imgUrl: token?.cdnUrl ?? token.imageUrl, price: (token.currentPrice ?? 0).toString(), percent: token.address === 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
                            ? (token?.changePrice?.diff_24h !== undefined ? Number(token?.changePrice?.diff_24h)?.toFixed(2) : 0.00)?.toString() + '%'
                            : (token?.changePrice && token?.changePrice?.diff_24h ? String(token?.changePrice?.diff_24h)?.replace('+', '') : '0%'), symbol: token.symbol, name: token.name, tokenAddress: token.address, backId: i + 1 }) }, v1())))
                : _jsx(_Fragment, {}) }) }));
};
