import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { Address } from 'ton-core';
import { NumberRenderer, TokenImg } from '@/shared';
import { selectAssetsModalStore, toggleSelectAssetsModal } from '@/features/select-assets-modal/model';
import { themeStore } from '@/features/theme/model';
import { isFromAsset, updateSwapToken1, updateSwapToken2 } from '@/features/swap/model';
import { toggleProfileModal } from '@/features/profile-modal/model';
import s from './TokenItem.module.scss';
export const TokenItem = ({ token, tokenLogo, amount, diff, amountUSD, priceUSD, tokenAddress, decimals, containLinks = false }) => {
    const isAssetsModalOpen = useUnit(selectAssetsModalStore);
    const currentTheme = useUnit(themeStore);
    const currentIsFromAssets = useUnit(isFromAsset);
    const navigate = useNavigate();
    const assetsHandler = useCallback(() => {
        if (isAssetsModalOpen) {
            if (currentIsFromAssets) {
                updateSwapToken1({
                    token,
                    tokenLogo,
                    amount,
                    amountUSD,
                    priceUSD,
                    priceDiff: diff,
                    tokenAddress: [tokenAddress],
                    decimals,
                });
            }
            if (!currentIsFromAssets) {
                updateSwapToken2({
                    token,
                    tokenLogo,
                    amount,
                    amountUSD,
                    priceUSD,
                    priceDiff: diff,
                    tokenAddress: [tokenAddress],
                    decimals,
                });
            }
            toggleSelectAssetsModal();
        }
        if (containLinks) {
            navigate(`token/${Address.parse(tokenAddress).toString()}`);
            toggleProfileModal();
        }
    }, [isFromAsset, isAssetsModalOpen]);
    return (_jsxs("div", { className: s.item, onClick: assetsHandler, children: [_jsxs("div", { className: s.itemInfo, children: [_jsx("div", { className: s.itemImg, children: _jsx(TokenImg, { imageUrl: tokenLogo, alt: token }) }), _jsxs("div", { className: s.itemBody, children: [_jsx("p", { className: s.itemToken, children: token }), _jsxs("p", { className: s.itemAmount, style: { display: Number(priceUSD ?? 0) === 0 ? 'none' : '' }, children: ["$", _jsx(NumberRenderer, { value: Number(priceUSD ?? 0) }), typeof diff === 'string' && !diff.startsWith('0') && (_jsx("span", { style: {
                                            color: diff.startsWith('+') ? '#10B981' : '#FF1C45',
                                            paddingLeft: '5px',
                                        }, children: diff }))] })] })] }), _jsxs("div", { className: s.itemDetail, children: [_jsx("p", { className: `${s.itemAmountUsd} ${s.itemAmountRight}`, children: _jsx(NumberRenderer, { value: Number(amount ?? 0) }) }), _jsxs("p", { className: `${s.itemAmount} ${s.itemAmountRight}`, children: ["$", _jsx(NumberRenderer, { value: Number(amountUSD ?? 0) })] })] })] }));
};
