// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NQVMWa20xixJvj9ynskt{display:inline-flex;align-items:center;padding:4px;background-color:rgb(var(--background-tertiary));border-radius:12px;gap:4px}.NQVMWa20xixJvj9ynskt .EV8ry0Wq5w2r9FNrpcoT{width:100%;color:#8a97aa;padding:9px 23px;cursor:pointer;border-radius:12px;display:flex;align-items:center;justify-content:center}.NQVMWa20xixJvj9ynskt .oEeEe7MLhqiHaXvOYE5j{background-color:rgb(var(--background-primary));color:rgb(var(--placeholder-primary));font-weight:510}.Khc7wRXgf66Z1QuoMiQE{width:100%}@media(max-width: 420px){.EV8ry0Wq5w2r9FNrpcoT{font-size:15px;padding:9px 9px !important}}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/TabSelector/TabSelector.module.scss"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CACA,kBAAA,CACA,WAAA,CACA,gDAAA,CACA,kBAAA,CACA,OAAA,CACA,4CACI,UAAA,CACA,aAAA,CACA,gBAAA,CACA,cAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEJ,4CACI,+CAAA,CACA,qCAAA,CACA,eAAA,CAIR,sBACI,UAAA,CAGJ,yBACI,sBACI,cAAA,CACA,0BAAA,CAAA","sourcesContent":[".tab_selector {\n    display: inline-flex;\n    align-items: center;\n    padding: 4px;\n    background-color: rgb(var(--background-tertiary));\n    border-radius: 12px;\n    gap: 4px;\n    .item {\n        width: 100%;\n        color: #8a97aa;\n        padding: 9px 23px;\n        cursor: pointer;\n        border-radius: 12px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n    .active {\n        background-color: rgb(var(--background-primary));\n        color: rgb(var(--placeholder-primary));\n        font-weight: 510;\n    }\n}\n\n.tab_selectorFull {\n    width: 100%;\n}\n\n@media (max-width: 420px) {\n    .item {\n        font-size: 15px;\n        padding: 9px 9px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab_selector": `NQVMWa20xixJvj9ynskt`,
	"item": `EV8ry0Wq5w2r9FNrpcoT`,
	"active": `oEeEe7MLhqiHaXvOYE5j`,
	"tab_selectorFull": `Khc7wRXgf66Z1QuoMiQE`
};
export default ___CSS_LOADER_EXPORT___;
