// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KVKDdhLjGniDwIaHD15g{padding:17px 24px;border:none;border-radius:16px;background-color:rgba(var(--background-primary));color:rgb(var(--placeholder-primary));display:flex;align-items:center;gap:8px;font-size:16px;line-height:122%;cursor:pointer;transition:all .1s ease 0s}.KVKDdhLjGniDwIaHD15g:hover{opacity:.9}@media(max-width: 550px){.KVKDdhLjGniDwIaHD15g{font-size:14px}}`, "",{"version":3,"sources":["webpack://./src/widgets/profile-modal/ui/LogoutButton/LogoutButton.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,gDAAA,CACA,qCAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CACA,gBAAA,CACA,cAAA,CACA,0BAAA,CACA,4BACI,UAAA,CAEJ,yBAhBJ,sBAiBQ,cAAA,CAAA","sourcesContent":[".logoutBtn {\n    padding: 17px 24px;\n    border: none;\n    border-radius: 16px;\n    background-color: rgba(var(--background-primary));\n    color: rgb(var(--placeholder-primary));\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    font-size: 16px;\n    line-height: 122%;\n    cursor: pointer;\n    transition: all 0.1s ease 0s;\n    &:hover {\n        opacity: 0.9;\n    }\n    @media (max-width: 550px) {\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoutBtn": `KVKDdhLjGniDwIaHD15g`
};
export default ___CSS_LOADER_EXPORT___;
