/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import axios from 'axios';
export class DedustApi {
    constructor() {
        this._url = 'https://api.dedust.io/v2';
    }
    async send(url, data) {
        const apiUrlToUse = this._url;
        const res = await axios.get(`${apiUrlToUse}${url}`);
        if (res.data.error) {
            console.error(res.data.result);
            return undefined;
        }
        return res.data;
    }
    async getAssets() {
        const data = await this.send('/assets', {});
        // console.log(data)
        return data;
    }
    async getAssetInfo(symbol) {
        const data = await this.send(`/assets/${symbol}`, {});
        // console.log(data)
        return data;
    }
}
