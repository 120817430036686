// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zPoZaieDL16Eu9M6QbXz{padding:10px}.Zf5ohMilAWj8A0QzQet5{display:flex;align-items:flex-end;justify-content:space-between;flex-wrap:wrap-reverse;gap:20px;max-width:100%;height:100%}.MkhskhrQvDSSwsLFEZiQ{padding:8px !important;overflow:hidden}.o_gl_lY4HvZBbC0TaFdK{width:100%;height:100%;max-width:100%;max-height:100%;min-height:100%;min-width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;gap:20px}.o_gl_lY4HvZBbC0TaFdK iframe{min-height:500px}`, "",{"version":3,"sources":["webpack://./src/widgets/token/ui/TokenChart/TokenChart.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAGJ,sBACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,sBAAA,CACA,QAAA,CACA,cAAA,CACA,WAAA,CAGJ,sBACI,sBAAA,CACA,eAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,6BACI,gBAAA","sourcesContent":[".inner {\n    padding: 10px;\n}\n\n.top {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-between;\n    flex-wrap: wrap-reverse;\n    gap: 20px;\n    max-width: 100%;\n    height: 100%;\n}\n\n.wp {\n    padding: 8px !important;\n    overflow: hidden;\n}\n\n.chartTradingView {\n    width: 100%;\n    height: 100%;\n    max-width: 100%;\n    max-height: 100%;\n    min-height: 100%;\n    min-width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 20px;\n    iframe {\n        min-height: 500px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `zPoZaieDL16Eu9M6QbXz`,
	"top": `Zf5ohMilAWj8A0QzQet5`,
	"wp": `MkhskhrQvDSSwsLFEZiQ`,
	"chartTradingView": `o_gl_lY4HvZBbC0TaFdK`
};
export default ___CSS_LOADER_EXPORT___;
