// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GSoE4lJqADR_VKBo7naw{position:fixed;left:10px;height:98vh;top:16px;width:88px;border-radius:24px;padding:10px;background-color:rgb(var(--background-primary));display:flex;flex-direction:column;align-items:center;gap:8px}.GSoE4lJqADR_VKBo7naw div:first-child{margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/sidebar/ui/Sidebar.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,SAAA,CACA,WAAA,CACA,QAAA,CACA,UAAA,CACA,kBAAA,CACA,YAAA,CACA,+CAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,OAAA,CAEI,sCACI,iBAAA","sourcesContent":[".sidebar {\n    position: fixed;\n    left: 10px;\n    height: 98vh;\n    top: 16px;\n    width: 88px;\n    border-radius: 24px;\n    padding: 10px;\n    background-color: rgb(var(--background-primary));\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 8px;\n    div {\n        &:first-child {\n            margin-bottom: 8px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `GSoE4lJqADR_VKBo7naw`
};
export default ___CSS_LOADER_EXPORT___;
