import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { usePalette } from 'react-palette';
import { SvgSelector, NumberRenderer, TopTokensSelector, TokenImg } from '@/shared';
import { tokenDetail } from '@/features/token-detail/model';
import { themeStore } from '@/features/theme/model';
import s from './TokenStats.module.scss';
function formatNumber(num) {
    return parseFloat(String(num)).toLocaleString(undefined, { maximumFractionDigits: 0 });
}
export const TokenStats = () => {
    const { jettonDetail } = useUnit(tokenDetail);
    const currentTheme = useUnit(themeStore);
    const isDown = String(jettonDetail?.percent)?.startsWith('−') || String(jettonDetail?.percent)?.startsWith('-');
    const [mainColor, setMainColor] = useState('#547998');
    const { data, loading, error } = usePalette(jettonDetail?.logo ?? '');
    // const AggredatedTokensApi = new AggregatedTokensApiService()
    // const updateTokenImg = async () => {
    //     await AggredatedTokensApi.updateTokenImages()
    // }
    useEffect(() => {
        // if (!jettonDetail?.logo) {
        //     updateTokenImg()
        // }
        const dominantSwatch = jettonDetail?.symbol === 'ARBUZ' ? data.darkVibrant : data.muted;
        console.log('dominantSwatch', dominantSwatch);
        if (!loading && !error && dominantSwatch) {
            setMainColor(dominantSwatch);
        }
    }, [loading, error, jettonDetail]);
    return (_jsxs("div", { className: s.token_stats, children: [mainColor && (_jsx("div", { className: s.background, children: _jsx(TopTokensSelector, { id: "1", theme: currentTheme, color: mainColor }, mainColor) })), _jsxs("div", { className: s.info, children: [_jsx(TokenImg, { imageUrl: jettonDetail?.logo, className: s.icon }), _jsxs("div", { className: s.about, children: [_jsx("div", { className: s.title, children: jettonDetail?.symbol }), _jsx("div", { className: s.subtitle, children: jettonDetail?.title })] })] }), _jsxs("div", { className: s.token_items, children: [_jsxs("div", { className: s.stats_item, style: { display: !jettonDetail?.price ? 'none' : '' }, children: [_jsx("div", { className: s.title, children: "Price" }), _jsxs("div", { className: s.value, children: [_jsxs("div", { className: s.amount, children: ["$", _jsx(NumberRenderer, { value: jettonDetail?.price ?? 0 })] }), _jsxs("div", { className: s.percent, children: [isDown ? (_jsx(SvgSelector, { id: "percent-down" })) : (_jsx(SvgSelector, { id: "percent-up" })), _jsx("span", { className: `${isDown ? s.down : s.up}`, children: jettonDetail?.address ===
                                                    'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
                                                    ? Number(jettonDetail?.percent ?? 0).toFixed(2).replace('-', '') + '%'
                                                    : jettonDetail?.percent?.replace('−', '').replace('+', '') })] })] })] }), _jsxs("div", { className: s.stats_item, children: [_jsx("div", { className: s.title, children: "Market Cap" }), _jsxs("div", { className: s.value, children: ["$", formatNumber(String(jettonDetail?.marketCap ?? 0))] })] }), _jsxs("div", { className: s.stats_item, children: [_jsx("div", { className: s.title, children: "Total Liquidity" }), _jsxs("div", { className: s.value, children: ["$", formatNumber(String(jettonDetail?.totalLiquidity ?? 0))] })] }), _jsxs("div", { className: s.stats_item, children: [_jsx("div", { className: s.title, children: "Volume 24h" }), _jsxs("div", { className: s.value, children: ["$", formatNumber(String(jettonDetail?.volume24h?.toFixed(2) ?? 0))] })] })] })] }));
};
