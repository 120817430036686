import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTonAddress } from 'delab-tonconnect-ui-react';
import { Address } from 'ton';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { useUnit } from 'effector-react';
import { SvgSelector, smlAddr } from '@/shared';
import { themeStore } from '@/features/theme/model';
import s from './ProfileAddress.module.scss';
export const ProfileAddress = () => {
    const rawAddress = useTonAddress();
    const theme = useUnit(themeStore);
    const handleCopy = () => {
        if (rawAddress) {
            toast('Address copied', {
                type: 'success',
                theme: theme === 'dark' ? 'dark' : 'light',
            });
            copy(Address.parse(rawAddress).toString({ bounceable: false }));
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: s.profileAddress, onClick: handleCopy, children: [rawAddress && smlAddr(Address.parse(rawAddress).toString({ bounceable: false })), _jsx(SvgSelector, { id: "copy" })] }) }));
};
