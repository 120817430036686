// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I6FgA5bwnswAaJ5jwYyD{width:100%;overflow-x:auto}.I6FgA5bwnswAaJ5jwYyD::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/widgets/token/ui/TokensTableList/TokensTableList.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA,CAGF,yCACE,YAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  overflow-x: auto;\n}\n\n.wrapper::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `I6FgA5bwnswAaJ5jwYyD`
};
export default ___CSS_LOADER_EXPORT___;
