import { jsx as _jsx } from "react/jsx-runtime";
import { formatNumberAmount } from '../../../utils/formatNumber';
function scientificToDecimal(numString) {
    const scientific = parseFloat(String(numString));
    const str = scientific.toString();
    if (str.indexOf('e') === -1) {
        return numString;
    }
    const [coefficient, exponent] = str.split('e');
    const decimalPlaces = Math.max(0, -parseInt(exponent, 10));
    return (parseFloat(coefficient) * 10 ** parseInt(exponent, 10)).toFixed(decimalPlaces);
}
export const NumberRenderer = ({ value }) => {
    function formatSmallNumber() {
        if (value === 0)
            return value;
        const number = scientificToDecimal(value);
        if (Number(number) > 0.00001) {
            return formatNumberAmount(String(number));
        }
        const parts = number?.toString().split('.');
        if (!parts || parts.length < 2) {
            return number;
        }
        const [integerPart, decimalPart] = parts;
        let numberOfZeros = 0;
        for (let i = 0; i < decimalPart.length; i++) {
            if (decimalPart[i] !== '0') {
                break;
            }
            numberOfZeros++;
        }
        const significantDigits = decimalPart.slice(numberOfZeros, numberOfZeros + 3);
        if (significantDigits) {
            return `${integerPart},0<sub>${numberOfZeros}</sub>${significantDigits}`;
        }
        else {
            return `${integerPart},0<sub>${numberOfZeros}</sub>0`;
        }
    }
    return (_jsx("span", { dangerouslySetInnerHTML: { __html: formatSmallNumber() } }));
};
