import { jsx as _jsx } from "react/jsx-runtime";
import Lottie from 'react-lottie';
import animationData from '../../../lib/lottie/loader.json';
import s from './Loader.module.scss';
export const Loader = () => {
    const theme = localStorage.getItem('theme') || 'light';
    const options = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    return (_jsx("div", { className: s.loader, style: { background: theme === 'light' ? 'rgb(239, 241, 245)' : 'rgb(33, 35, 37)' }, children: _jsx(Lottie, { options: options, height: 200, width: 200, isClickToPauseDisabled: true }) }));
};
