import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useUnit } from 'effector-react';
import { useMediaQuery } from 'react-responsive';
import { profileModalStore } from '@/features/profile-modal/model';
import { selectAssetsModalStore } from '@/features/select-assets-modal/model';
import { tokenDetail } from '@/features/token-detail/model';
import { AssetsModalInner } from '@/widgets/selectAssetsModal';
import { ProfileModalInner } from '@/widgets/profile-modal';
import { Sidebar } from '@/widgets/sidebar';
import { Header } from '@/widgets/header';
import { Menu } from '@/widgets/menu';
import { Footer } from '@/widgets/footer';
import s from './MainLayout.module.scss';
export const MainLayout = ({ children }) => {
    const isProfileModalOpen = useUnit(profileModalStore);
    const isAssetsModalOpen = useUnit(selectAssetsModalStore);
    const { loadingDetail } = useUnit(tokenDetail);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const mainClass = loadingDetail ? '' : s.main;
    const innerClass = loadingDetail ? '' : s.inner;
    const contentClass = loadingDetail ? '' : s.content;
    const contentInnerClass = loadingDetail ? '' : s.contentInner;
    return (_jsxs(_Fragment, { children: [_jsx(AssetsModalInner, { isModalOpen: isAssetsModalOpen }), _jsx(ProfileModalInner, { isModalOpen: isProfileModalOpen }), _jsxs("div", { className: mainClass, children: [!loadingDetail && (_jsx("div", { className: s.sidebar, children: _jsx(Sidebar, {}) })), _jsxs("div", { className: innerClass, children: [_jsxs("div", { className: contentClass, children: [!loadingDetail && _jsx(Header, {}), _jsx("div", { className: contentInnerClass, children: children })] }), !loadingDetail && _jsx(Footer, {})] }), isMobile && !loadingDetail && _jsx(Menu, {})] })] }));
};
