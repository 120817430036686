function findFirstTwoNonZeroDigits(num) {
    const numString = Math.abs(num).toString();
    const cleanString = numString.replace(/\./g, '');
    for (let i = 0; i < cleanString.length; i++) {
        const char = cleanString[i];
        if (char !== '0' && char !== '.') {
            return cleanString.slice(i, i + 2);
        }
    }
    return '1';
}
export function formatNumber(amount) {
    const amountAsNumber = parseFloat(amount);
    if (amountAsNumber === 0 || isNaN(amountAsNumber)) {
        return '0';
    }
    if (amountAsNumber >= 0.01) {
        const formattedValue = amount.toString();
        const index = formattedValue.indexOf('.');
        if (index !== -1 && formattedValue.length - index > 2) {
            return formattedValue.slice(0, index + 3);
        }
        return formattedValue;
    }
    if (amountAsNumber < 0.01 && amountAsNumber > 0) {
        const symbols = findFirstTwoNonZeroDigits(amountAsNumber);
        return (`0.00...${symbols}`);
    }
    const formattedValue = amount.toLocaleString();
    return formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '').slice(0, 8);
}
export function formatNumberAmount(amount) {
    const amountAsNumber = parseFloat(amount);
    if (amountAsNumber > 0.01) {
        const formattedValue = amount.toString();
        const index = formattedValue.indexOf('.');
        if (index !== -1 && formattedValue.length - index > 2) {
            return formattedValue.slice(0, index + 3);
        }
        return formattedValue;
    }
    const formattedValue = amount.toLocaleString();
    return formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '').slice(0, 8);
}
