// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fbzsv4JGmr27P6FOoLOO{width:100%}.Fbzsv4JGmr27P6FOoLOO h1{font-weight:540 !important;height:44px;padding-left:16px;display:flex;align-items:center;margin-bottom:8px !important}.Fbzsv4JGmr27P6FOoLOO .f0HsiVzo4UqTspXSWc7w{display:flex;flex-direction:column;gap:16px}.Fbzsv4JGmr27P6FOoLOO .f0HsiVzo4UqTspXSWc7w .KBHbvm8Q2P8trDOfPkGq{display:flex;gap:16px;margin-bottom:16px}@media(max-width: 1200px){.KBHbvm8Q2P8trDOfPkGq{flex-direction:column}}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,yBACI,0BAAA,CACA,WAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,4BAAA,CAEJ,4CACI,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kEACI,YAAA,CACA,QAAA,CACA,kBAAA,CAKZ,0BACI,sBACI,qBAAA,CAAA","sourcesContent":[".home {\n    width: 100%;\n    h1 {\n        font-weight: 540 !important;\n        height: 44px;\n        padding-left: 16px;\n        display: flex;\n        align-items: center;\n        margin-bottom: 8px !important;\n    }\n    .content {\n        display: flex;\n        flex-direction: column;\n        gap: 16px;\n        .custom_blocks {\n            display: flex;\n            gap: 16px;\n            margin-bottom: 16px;\n        }\n    }\n}\n\n@media (max-width: 1200px) {\n    .custom_blocks {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `Fbzsv4JGmr27P6FOoLOO`,
	"content": `f0HsiVzo4UqTspXSWc7w`,
	"custom_blocks": `KBHbvm8Q2P8trDOfPkGq`
};
export default ___CSS_LOADER_EXPORT___;
