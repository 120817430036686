import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUnit } from 'effector-react';
import { Modal } from '@/shared';
import { BalanceBlock } from '@/entities/user';
import { userBaseInfo } from '@/features/user/model';
import { toggleProfileModal } from '@/features/profile-modal/model';
import { TokenList } from '../TokenList';
import { ProfileAddress } from '../ProfileAddress';
import { LogoutButton } from '../LogoutButton';
import s from './ProfileModalInner.module.scss';
export const ProfileModalInner = ({ isModalOpen }) => {
    const { assets, loading, totalBalance } = useUnit(userBaseInfo);
    return (_jsxs(Modal, { isOpen: isModalOpen, onClose: () => toggleProfileModal(), title: "Profile", children: [_jsx(BalanceBlock, { loading: loading, balance: totalBalance }), _jsx(TokenList, { tokensData: assets, containLinks: true, tokensLoading: loading }), _jsxs("div", { className: s.modalActions, children: [_jsx(ProfileAddress, {}), _jsx(LogoutButton, {})] })] }));
};
