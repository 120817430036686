import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { v1 } from 'uuid';
import { useUnit } from 'effector-react';
import { ContentBlock, NumberRenderer, SvgSelector, TokenImg } from '@/shared';
import { themeStore } from '@/features/theme/model';
import { tokensInfo } from '@/features/tokens/model';
import s from './CustomTokensList.module.scss';
import { AggregatedTokensApiService } from '@/entities/token';
import { useQuery } from '@tanstack/react-query';
export const CustomTokensList = ({ title }) => {
    const navigate = useNavigate();
    const tokensBaseInfo = useUnit(tokensInfo);
    const currentTheme = useUnit(themeStore);
    const aggregatedTokensApiService = new AggregatedTokensApiService();
    const { data, error, isLoading } = useQuery({
        queryKey: [title === 'Daily Gainers' ? 'dailyGainers' : 'Trending', title],
        queryFn: () => aggregatedTokensApiService.send('tokens/getTokens', {
            queryMeta: {
                ...(title === 'Daily Gainers' ?
                    {
                        order: {
                            ['diff_24hPercentage']: 'DESC',
                        }
                    } : {
                    order: {
                        ['totalLiquidity']: 'DESC',
                    }
                }),
                paginate: true,
                skip: 1,
                take: 5,
            },
        })
    });
    return (_jsxs(ContentBlock, { children: [_jsx("div", { className: s.title, children: title }), _jsx("div", { className: s.list, children: data?.data?.map((el, index) => (_jsxs("div", { className: s.list_item, onClick: () => navigate(`token/${el.address}`), children: [_jsxs("div", { className: s.left, children: [_jsxs("div", { className: s.index, children: ["#", index + 1] }), _jsxs("div", { className: s.token, children: [_jsx(TokenImg, { imageUrl: el.imageUrl, className: s.token_image }), _jsx("div", { className: s.token_title, children: el.symbol })] })] }), _jsxs("div", { className: s.right, children: [_jsxs("div", { className: s.price, children: ["$", _jsx(NumberRenderer, { value: el?.currentPrice ?? 0 })] }), el.changePrice?.diff_24h && (_jsxs("div", { className: s.percent, children: [el.changePrice.diff_24h && (String(el.changePrice.diff_24h).startsWith('−') || String(el.changePrice.diff_24h).startsWith('-')) ? (_jsx(SvgSelector, { id: "percent-down" })) : (_jsx(SvgSelector, { id: "percent-up" })), _jsxs("div", { className: `${s.percent_value} ${el.changePrice.diff_24h && (String(el.changePrice.diff_24h).startsWith('−') || String(el.changePrice.diff_24h).startsWith('-')) ? s.down : s.up}`, children: [el.address !== 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c' && (el?.changePrice && el?.changePrice?.diff_24h ? String(el?.changePrice?.diff_24h)?.replace('+', '').replace('−', '') : '0%'), el.address === 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c' && (el?.changePrice?.diff_24h !== undefined ? Number(String(el?.changePrice?.diff_24h)?.replace('-', ''))?.toFixed(2) : 0.00)?.toString() + '%'] })] }))] })] }, v1()))) })] }));
};
