/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
export class AppsApi {
    constructor() {
        this._baseUrl = 'https://raw.githubusercontent.com/delab-team/apps-data/main/';
    }
    async getItems(selectedLanguage) {
        const url = selectedLanguage === 'ru' ? 'data-ru.json' : 'data-en.json';
        const res = await axios.get(`${this._baseUrl}${url}`);
        return res.data.data;
    }
}
