// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jlrdFXAVM8BT3xP5tjXx{display:flex;align-items:center;justify-content:center;padding:12px;border-radius:12px;cursor:pointer;width:62px;height:62px;transition:all .1s ease 0s}.jlrdFXAVM8BT3xP5tjXx:first-child svg{min-width:38px;min-height:38px}.jlrdFXAVM8BT3xP5tjXx:hover{opacity:.8}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/SidebarItem/SidebarItem.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,0BAAA,CAEI,sCACI,cAAA,CACA,eAAA,CAGR,4BACI,UAAA","sourcesContent":[".item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 12px;\n    border-radius: 12px;\n    cursor: pointer;\n    width: 62px;\n    height: 62px;\n    transition: all 0.1s ease 0s;\n    &:first-child {\n        svg {\n            min-width: 38px;\n            min-height: 38px;\n        }\n    }\n    &:hover {\n        opacity: 0.8;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `jlrdFXAVM8BT3xP5tjXx`
};
export default ___CSS_LOADER_EXPORT___;
