// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mkoffcb7wE18BbmADeiY{font-size:22px;font-weight:590;color:rgb(var(--placeholder-primary));line-height:118%;margin-bottom:17px}@media(max-width: 550px){.mkoffcb7wE18BbmADeiY{font-size:20px}}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/PageTitle/PageTitle.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,eAAA,CACA,qCAAA,CACA,gBAAA,CACA,kBAAA,CACA,yBANJ,sBAOQ,cAAA,CAAA","sourcesContent":[".page_title {\n    font-size: 22px;\n    font-weight: 590;\n    color: rgb(var(--placeholder-primary));\n    line-height: 118%;\n    margin-bottom: 17px;\n    @media (max-width: 550px) {\n        font-size: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page_title": `mkoffcb7wE18BbmADeiY`
};
export default ___CSS_LOADER_EXPORT___;
