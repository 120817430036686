import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { usePalette } from 'react-palette';
import { NumberRenderer } from '../NumberRenderer';
import { TokenImg } from '../TokenImg';
import { themeStore } from '../../../../features/theme/model';
import { TopTokensSelector } from '../../../lib/assets/top-tokens-selector';
import { SvgSelector } from '../../../lib/assets/svg-selector';
import s from './TopTokenCard.module.scss';
import { sharedConfigRoutes } from '@/shared/config';
export const TopTokenCard = ({ symbol, price, percent, backId, imgUrl, name, tokenAddress, }) => {
    const [mainColor, setMainColor] = useState('#547998');
    const navigate = useNavigate();
    const currentTheme = useUnit(themeStore);
    const isDown = percent.startsWith('−') || percent.startsWith('-');
    const cleanPercent = percent.replace('−', '').replace('+', '').replace('-', '');
    const { data, loading, error } = usePalette(imgUrl);
    const { RouteName } = sharedConfigRoutes;
    useEffect(() => {
        const dominantSwatch = symbol === 'ARBUZ' ? data.darkVibrant : data.muted;
        // console.log('dominantSwatch', dominantSwatch)
        if (!loading && !error && dominantSwatch) {
            setMainColor(dominantSwatch);
        }
    }, [loading, error]);
    const handleNavigateToTokenPage = () => {
        navigate(`token/${tokenAddress}`);
        // updateSwapToken1()
    };
    return (_jsxs("div", { className: s.card, onClick: handleNavigateToTokenPage, children: [_jsx("div", { className: s.top_title, style: { backgroundColor: mainColor }, children: symbol }), _jsxs("div", { className: s.price_info, children: [_jsxs("div", { className: s.price, children: ["$", _jsx(NumberRenderer, { value: Number(price) })] }), _jsxs("div", { className: s.price_stats, children: [isDown ? _jsx(SvgSelector, { id: "percent-down" }) : _jsx(SvgSelector, { id: "percent-up" }), _jsx("div", { className: `${s.percent} ${isDown ? s.down : s.up}`, children: cleanPercent })] })] }), _jsxs("div", { className: s.bottom, children: [_jsx(TokenImg, { imageUrl: imgUrl, className: s.tokenImage }), _jsx("h3", { className: s.title, children: name })] }), _jsx("div", { className: s.background, children: mainColor && !loading && (_jsx(TopTokensSelector, { id: backId.toString(), theme: currentTheme, color: mainColor }, mainColor)) })] }));
};
