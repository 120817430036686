// convertTokensFormat
export const convertTokensFormat = (array, maxScore) => {
    return array?.map((token) => {
        const liquiditySum = (token.liquidity?.dedust ?? 0) + (token.liquidity?.ston ?? 0);
        const volumeSum = Number(token.volume?.dedust?.volume_24h ?? 0) + Number(token.volume?.ston?.volume_24h ?? 0);
        const score = ((token?.score ?? maxScore ?? 1) / 1) * 100;
        return {
            token: {
                name: token?.symbol,
                imageUrl: token?.cdnUrl ?? token?.imageUrl,
                address: token?.address,
                symbol: token?.symbol
            },
            price: token?.currentPrice ?? 0,
            percent: token.address === 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
                ? (token?.changePrice?.diff_24h !== undefined ? Number(token?.changePrice?.diff_24h)?.toFixed(2) : 0.00)?.toString() + '%'
                : (token?.changePrice && token?.changePrice?.diff_24h ? String(token?.changePrice?.diff_24h)?.replace('+', '') : '0%'),
            marketCap: token?.marketCap ?? 0,
            totalLiquidity: liquiditySum,
            // volume: {
            //     amount: volumeSum,
            //     count: token?.currentPrice ? volumeSum / (token?.currentPrice ?? 1) : 0
            // },
            volume: volumeSum,
            score,
            actions: ''
        };
    });
};
