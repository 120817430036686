/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import axios from 'axios';
export class TokenApiService {
    constructor() {
        this._url = 'https://tonapi.io/v2/'; // https://king-prawn-app-situq.ondigitalocean.app
        // private _url: string = 'https://tonapi.delabteam.com/' // https://tonapi.io/v2/
        this._token = 'AFJOFCU7U3SD7DYAAAAFNHSJF3J4OKQQEXIMHCEQSXUQJAGVP227ZLZ3G3MQTBEU5P3JMRI';
    }
    async send(url, data) {
        const res = await axios.get(`${this._url}${url}?${new URLSearchParams(data)}`, { headers: { Authorization: `Bearer ${this._token}` } });
        if (res.data.error) {
            console.error(res.data.result);
            return undefined;
        }
        return res.data;
    }
    async getTokenPrice(tokens, currencies) {
        const data = await this.send('rates', { tokens, currencies });
        return data;
    }
    async getChartToken(token, currency, start_date, end_date) {
        const data = { token };
        if (currency !== undefined) {
            data.currency = currency;
        }
        if (start_date !== undefined) {
            data.start_date = start_date;
        }
        if (end_date !== undefined) {
            data.end_date = end_date;
        }
        const result = await this.send('rates/chart', data);
        return result;
    }
}
