// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZdkQCronLw9iTA0ptvf1{color:rgb(var(--placeholder-primary));margin-bottom:16px;background-color:rgba(var(--background-primary));border-radius:24px;padding:15px 0 0 0;overflow:hidden}.LBBmTBWivOmhUZjeqwq7{height:400px}.p8qGJH9UHkgZzRQdyQcx{height:100%;display:flex;align-items:center;justify-content:center;font-size:25px;font-weight:540}`, "",{"version":3,"sources":["webpack://./src/widgets/profile-modal/ui/TokenList/TokenList.module.scss"],"names":[],"mappings":"AAAA,sBACI,qCAAA,CACA,kBAAA,CACA,gDAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBACI,YAAA,CAIR,sBACI,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".tokens {\n    color: rgb(var(--placeholder-primary));\n    margin-bottom: 16px;\n    background-color: rgba(var(--background-primary));\n    border-radius: 24px;\n    padding: 15px 0 0 0;\n    overflow: hidden;\n    &List {\n        height: 400px;\n    }\n}\n\n.notFound {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 25px;\n    font-weight: 540;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokens": `ZdkQCronLw9iTA0ptvf1`,
	"tokensList": `LBBmTBWivOmhUZjeqwq7`,
	"notFound": `p8qGJH9UHkgZzRQdyQcx`
};
export default ___CSS_LOADER_EXPORT___;
