/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import axios from 'axios';
export class CoinGeckoApiService {
    constructor() {
        this._url = 'https://api.geckoterminal.com/api/v2/';
    }
    async send(url, data) {
        const res = await axios.get(`${this._url}${url}?${new URLSearchParams(data)}`);
        if (res.data.error) {
            console.error(res.data.result);
            return undefined;
        }
        return res.data;
    }
    async getTradesData(poolAddress) {
        const data = await this.send(`networks/ton/pools/${poolAddress}/trades`, {
            trade_volume_in_usd_greater_than: 1
            // минимум в баксах
        });
        return data.data;
    }
    // periodSize
    // Available values (day): 1
    // Available values (hour): 1, 4, 12
    // Available values (minute): 1, 5, 15
    // мы будем делать только 15 минутные свечи, 1 дневные (однодневные), 1 часовые
    // limit надо подстраивать под тип свечи. дневные свечи можно на два года назад показывать (730 дней), 15 минутные на 500 периодов по 15 минут, часовые на 72 часа
    async getCandlesData(poolAddress, period, periodSize, currency, limit, before_timestamp, token) {
        const data = await this.send(`networks/ton/pools/${poolAddress}/ohlcv/${period}`, {
            aggregate: periodSize,
            before_timestamp,
            limit,
            currency,
            token
        });
        return data;
    }
}
