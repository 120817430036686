// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kbwsrGnm2sZgrjLrslrA{background:rgb(var(--background-primary));border-radius:0px;overflow:hidden;height:500px}@media(max-width: 1000px){.kbwsrGnm2sZgrjLrslrA{max-width:496px;width:100%}}.egq_3U2nd_9frIATY_yA{font-family:"SF-PRO";font-size:18px;font-weight:540;margin-left:24px;margin-bottom:12px;color:rgb(var(--placeholder-primary));display:flex;align-items:center}.XvbH13tAv5UlbMIdksOQ{height:30vh;width:100%;display:flex;align-items:center;justify-content:center;font-size:30px;font-weight:510;color:rgb(var(--placeholder-primary))}`, "",{"version":3,"sources":["webpack://./src/widgets/token/ui/TokenPairsLite/token-pairs-lite.module.scss"],"names":[],"mappings":"AAAA,sBACE,yCAAA,CAEA,iBAAA,CACA,eAAA,CAEA,YAAA,CACA,0BAPF,sBAQI,eAAA,CACA,UAAA,CAAA,CAEF,sBACE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,qCAAA,CACA,YAAA,CACA,kBAAA,CAIJ,sBACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,eAAA,CACA,qCAAA","sourcesContent":[".inner {\n  background: rgb(var(--background-primary));\n  // padding: 20px 4px 0 4px;\n  border-radius: 0px;\n  overflow: hidden;\n  // width: 332px;\n  height: 500px;\n  @media (max-width: 1000px) {\n    max-width: 496px;\n    width: 100%;\n  }\n  &Title {\n    font-family: \"SF-PRO\";\n    font-size: 18px;\n    font-weight: 540;\n    margin-left: 24px;\n    margin-bottom: 12px;\n    color: rgb(var(--placeholder-primary));\n    display: flex;\n    align-items: center;\n  }\n}\n\n.noData {\n  height: 30vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 30px;\n  font-weight: 510;\n  color: rgb(var(--placeholder-primary));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `kbwsrGnm2sZgrjLrslrA`,
	"innerTitle": `egq_3U2nd_9frIATY_yA`,
	"noData": `XvbH13tAv5UlbMIdksOQ`
};
export default ___CSS_LOADER_EXPORT___;
