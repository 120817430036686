/* eslint-disable max-len */
import { createEvent, createStore, combine } from 'effector';
import { AggregatedTokensApiService } from '../../entities/token/api/aggregatedTokens';
function parsePercentage(percentage) {
    if (!percentage) {
        return 0;
    }
    const percentageString = percentage.toString();
    return parseFloat(percentageString?.replace('−', '-').replace('+', '').replace('%', ''));
}
const tokenAggregatedApi = new AggregatedTokensApiService();
export const allTokensStore = createStore([]); // all tokens
export const dedustTokensStore = createStore([]);
export const stonfiTokensStore = createStore([]);
export const tableTokensStore = createStore([]); // all table tokens
// =========================
export const topTokensStore = createStore([]); // top  tokens
export const dailyGainersStore = createStore([]); // daily gainers
export const trandingStore = createStore([]); // tranding
export const topScoreStore = createStore(0); // top score
export const allTokensLoadingStore = createStore(true);
export const updateAllTokens = createEvent();
export const updateDedustTokens = createEvent();
export const updateStonfiTokens = createEvent();
export const updateTableTokens = createEvent();
export const updateAllTokensLoading = createEvent();
export const updateTopTokens = createEvent();
export const updateGainers = createEvent();
export const updateTraiding = createEvent();
export const updateTopScore = createEvent();
allTokensStore.on(updateAllTokens, (_, newAssets) => newAssets);
dedustTokensStore.on(updateDedustTokens, (_, newAssets) => newAssets);
stonfiTokensStore.on(updateStonfiTokens, (_, newAssets) => newAssets);
tableTokensStore.on(updateTableTokens, (_, newAssets) => newAssets);
topTokensStore.on(updateTopTokens, (_, newAssets) => newAssets);
dailyGainersStore.on(updateGainers, (_, newAssets) => newAssets);
trandingStore.on(updateTraiding, (_, newAssets) => newAssets);
topScoreStore.on(updateTopScore, (_, newScore) => newScore);
allTokensLoadingStore.on(updateAllTokensLoading, (_, newState) => newState);
export const getAllTokens = createEvent();
getAllTokens.watch(async () => {
    try {
        const allTokens = await tokenAggregatedApi.getTokensData();
        if (!allTokens) {
            return;
        }
        // at least 100,000
        const volumeFilteredTokens = allTokens?.data.filter((token) => {
            const volume24hDedust = token?.volume?.dedust?.volume_24h || 0;
            const volume24hSton = token?.volume?.ston?.volume_24h || 0;
            return volume24hDedust + volume24hSton > 10000;
        });
        const sortedGainersTokens = volumeFilteredTokens?.sort((a, b) => {
            const diffA = parsePercentage(a?.changePrice?.diff_24h ?? '0');
            const diffB = parsePercentage(b?.changePrice?.diff_24h ?? '0');
            return diffB - diffA;
        });
        // console.log('allTokens', allTokens)
        // console.log('sortedGainersTokens', sortedGainersTokens)
        const tokensWithTotalLiquidity = allTokens?.data.map((token) => {
            const dedustLiquidity = token.liquidity?.dedust || 0;
            const stonfLiquidity = token.liquidity?.ston || 0;
            const totalLiquidity = dedustLiquidity + stonfLiquidity;
            return { ...token, totalLiquidity };
        });
        const sortedLiqTokens = tokensWithTotalLiquidity?.sort((a, b) => b.totalLiquidity - a.totalLiquidity);
        const top5GainersTokens = sortedGainersTokens?.slice(0, 5);
        const sortedTopTokens = tokensWithTotalLiquidity?.sort((a, b) => Number(b?.totalLiquidity) - Number(a?.totalLiquidity));
        // console.log('sortedTopTokens', sortedTopTokens)
        // const index = sortedTopTokens.findIndex(token => token.symbol === 'DFC')
        // console.log('some token', index, sortedTopTokens.find(token => token.symbol === 'DFC'))
        const top7Tokens = sortedTopTokens?.slice(0, 7);
        const topLiqTokens = sortedLiqTokens?.slice(0, 5);
        const stonTokens = allTokens?.data.filter(token => token.availableDex.includes('STON'));
        const dedustTokens = allTokens?.data.filter(token => token.availableDex.includes('DEDUST'));
        // tokens for table
        const maxScore = top7Tokens[0]?.score;
        // console.log('maxScore', maxScore)
        const finalTableTokens = allTokens?.data.map((token) => {
            const liquiditySum = (token.liquidity?.dedust ?? 0) + (token.liquidity?.ston ?? 0);
            const volumeSum = Number(token.volume?.dedust?.volume_24h ?? 0) + Number(token.volume?.ston?.volume_24h ?? 0);
            const score = ((token?.score ?? 0) / maxScore) * 100;
            return {
                token: {
                    name: token?.symbol,
                    imageUrl: token?.cdnUrl ?? token?.imageUrl,
                    address: token?.address,
                    symbol: token?.symbol
                },
                price: token?.currentPrice ?? 0,
                percent: token.address === 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
                    ? (token?.changePrice?.diff_24h !== undefined ? Number(token?.changePrice?.diff_24h)?.toFixed(2) : 0.00)?.toString() + '%'
                    : (token?.changePrice && token?.changePrice?.diff_24h ? String(token?.changePrice?.diff_24h)?.replace('+', '') : '0%'),
                marketCap: token?.marketCap ?? 0,
                totalLiquidity: liquiditySum,
                // volume: {
                //     amount: volumeSum,
                //     count: token?.currentPrice ? volumeSum / (token?.currentPrice ?? 1) : 0
                // },
                volume: volumeSum,
                score,
                actions: ''
            };
        }).sort((a, b) => Number(b?.totalLiquidity) - Number(a?.totalLiquidity));
        // console.log('finalTableTokens', finalTableTokens)
        // console.log('sortedTopTokens', sortedTopTokens)
        // console.log('top7Tokens', top7Tokens)
        // console.log('top7Token score', Number(top7Tokens[0].score))
        // console.log('top5GainersTokens', top5GainersTokens)
        // console.log('topLiqTokens', topLiqTokens)
        // console.log('stonTokens', stonTokens)
        // console.log('dedustTokens', dedustTokens)
        updateAllTokens(allTokens.data);
        updateGainers(top5GainersTokens);
        updateTopTokens(top7Tokens);
        updateTopScore(Number(top7Tokens[0]?.score));
        updateTraiding(topLiqTokens);
        updateDedustTokens(dedustTokens);
        updateStonfiTokens(stonTokens);
        updateTableTokens(finalTableTokens);
    }
    catch (err) {
        console.error(err);
    }
    finally {
        updateAllTokensLoading(false);
    }
});
export const tokensInfo = combine({
    aggregatedAssets: allTokensStore,
    loading: allTokensLoadingStore,
    top7Tokens: topTokensStore,
    topScore: topScoreStore,
    top5GainersTokens: dailyGainersStore,
    top5LiqTokens: trandingStore,
    stonTokens: stonfiTokensStore,
    dedustTokens: dedustTokensStore,
    finalTableTokens: tableTokensStore
});
