import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useUnit } from 'effector-react';
import { useDebounce } from 'usehooks-ts';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { toNano } from 'ton-core';
import { ContentBlock, SvgSelector, getDeLabClient, getHubClient } from '@/shared';
import { Amount, SwapDropdown, DetailInfo } from '../';
import { currentDexStore, swapToken1Store, swapToken2Store, updateSwapToken1, updateSwapToken2, } from '@/features/swap/model';
import { themeStore } from '@/features/theme/model';
import { tokensInfo } from '@/features/tokens/model';
import { toggleLiteStore } from '@/features/lite/model';
import { DedustSwapService } from '@/entities/swap/dedust/getMessage';
import { StonFiSwapService } from '@/entities/swap/stonfi/getStonMessage';
import s from './swapModule.module.scss';
export const SwapModule = () => {
    const location = useLocation();
    const [isPresetted, setIsPresetted] = useState(false);
    const [isFromAssets, setIsFromAssets] = useState(true);
    const token1 = useUnit(swapToken1Store);
    const token2 = useUnit(swapToken2Store);
    const userAddress = useTonAddress(true);
    const theme = useUnit(themeStore);
    const [webBtnDisabled, setWebBtnDisabled] = useState(false);
    const [deWalletTonFee, setDeWalletTonFee] = useState(0); // 1% fron swap in amount
    const [swapped, setSwapped] = useState(false);
    const [isValidAmount, setIsValidAmount] = useState(false);
    const [estimateLoading, setEstimateLoading] = useState(false);
    const [swapLoading, setSwapLoading] = useState(false);
    const { isLiteEnabled } = useUnit(toggleLiteStore);
    const [tokenBalance1, setTokenBalance1] = useState('0');
    const [tokenBalance2, setTokenBalance2] = useState('0');
    // const userInfo = useUnit(userBaseInfo)
    const allTokensInfo = useUnit(tokensInfo);
    const currentDex = useUnit(currentDexStore);
    const SwitchHandler = () => {
        const currentToken1 = token1;
        const currentToken2 = token2;
        updateSwapToken1(currentToken2);
        updateSwapToken2(currentToken1);
        setSwapped(!swapped);
    };
    const variants = {
        open: { rotate: 180 },
        closed: { rotate: 0 },
    };
    // swap
    const dedustService = new DedustSwapService(getHubClient());
    const dedustService2 = new DedustSwapService(getDeLabClient());
    const stonfiService = new StonFiSwapService();
    const debouncedTokenBalance1 = useDebounce(tokenBalance1, 500);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://avatars.githubusercontent.com/u/116884789?s=200&v=4',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    const makeDedustSwap = async () => {
        if (token1.token === token2.token) {
            console.error('Same tokens');
            setSwapLoading(false);
            return;
        }
        if (token1.token === 'TON' && token2.token !== 'TON') {
            try {
                setSwapLoading(true);
                console.log('TON DEDUST SWAP');
                let swapObj = await dedustService.SwapTonToJetton(token2.tokenAddress[0], tokenBalance1);
                if (!swapObj) {
                    swapObj = await dedustService2.SwapTonToJetton(token2.tokenAddress[0], tokenBalance1);
                }
                if (!swapObj) {
                    console.error('swapObj error');
                    return;
                }
                console.log('swapObj', swapObj);
                const tr = {
                    validUntil: Math.floor(Date.now() / 1000) + 60,
                    messages: [
                        {
                            address: swapObj?.address,
                            amount: swapObj?.amount,
                            payload: swapObj?.payload,
                        },
                        {
                            address: 'UQAD5kUV1BmSaC6ElEYL5X-Nwc64zYDt14LwyTt20XjyjoM_',
                            amount: toNano(deWalletTonFee).toString(),
                        },
                    ],
                };
                const tx = await tonConnectUI.sendTransaction(tr);
                console.log('tx result', tx);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setSwapLoading(false);
                setTokenBalance1('0');
                setTokenBalance2('0');
            }
        }
        else if (token1.token !== 'TON' && token2.token === 'TON') {
            try {
                setSwapLoading(true);
                console.log('JETTON TO TON DEDUST SWAP');
                let swapObj = await dedustService.SwapJettonToTon(token1.tokenAddress[0], tokenBalance1, token1.decimals ?? 9, userAddress);
                if (!swapObj) {
                    swapObj = await dedustService2.SwapJettonToTon(token1.tokenAddress[0], tokenBalance1, token1?.tokenAddress[0] ===
                        'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                        token1?.tokenAddress[0] ===
                            'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                        ? 6
                        : token1.decimals ?? 9, userAddress);
                }
                if (!swapObj) {
                    console.error('swapObj error');
                    return;
                }
                console.log('swapObj', swapObj);
                const tr = {
                    validUntil: Math.floor(Date.now() / 1000) + 60,
                    messages: [
                        {
                            address: swapObj?.address,
                            amount: swapObj?.amount,
                            payload: swapObj?.payload,
                        },
                        {
                            address: 'UQAD5kUV1BmSaC6ElEYL5X-Nwc64zYDt14LwyTt20XjyjoM_',
                            amount: toNano(deWalletTonFee).toString(),
                        },
                    ],
                };
                const tx = await tonConnectUI.sendTransaction(tr);
                console.log('tx result', tx);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setSwapLoading(false);
                setTokenBalance1('0');
                setTokenBalance2('0');
            }
        }
        else if (token1.token !== 'TON' && token2.token !== 'TON') {
            try {
                setSwapLoading(true);
                console.log('TON DEDUST SWAP');
                let swapObj = await dedustService.SwapJettonToJetton(userAddress, token1.tokenAddress[0], token2.tokenAddress[0], tokenBalance1, token1?.tokenAddress[0] ===
                    'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                    token1?.tokenAddress[0] ===
                        'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                    ? 6
                    : token1.decimals ?? 9);
                if (!swapObj) {
                    swapObj = await dedustService2.SwapJettonToJetton(userAddress, token1.tokenAddress[0], token2.tokenAddress[0], tokenBalance1, token1?.tokenAddress[0] ===
                        'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                        token1?.tokenAddress[0] ===
                            'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                        ? 6
                        : token1.decimals ?? 9);
                }
                if (!swapObj) {
                    console.error('swapObj error');
                    return;
                }
                console.log('swapObj', swapObj);
                const tr = {
                    validUntil: Math.floor(Date.now() / 1000) + 60,
                    messages: [
                        {
                            address: swapObj?.address,
                            amount: swapObj?.amount,
                            payload: swapObj?.payload,
                        },
                        {
                            address: 'UQAD5kUV1BmSaC6ElEYL5X-Nwc64zYDt14LwyTt20XjyjoM_',
                            amount: toNano(deWalletTonFee).toString(),
                        },
                    ],
                };
                const tx = await tonConnectUI.sendTransaction(tr);
                console.log('tx result', tx);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setSwapLoading(false);
                setTokenBalance1('0');
                setTokenBalance2('0');
            }
        }
    };
    const makeStonFiSwap = async () => {
        if (token1.token === token2.token) {
            console.error('Same tokens');
            setSwapLoading(false);
            return;
        }
        if (token1.token === 'TON' && token2.token !== 'TON') {
            try {
                setSwapLoading(true);
                console.log('TON STON SWAP');
                const swapObj = await stonfiService.SwapTonToJetton(token2.tokenAddress[0], tokenBalance1, userAddress);
                if (!swapObj) {
                    console.error('swapObj error');
                    return;
                }
                console.log('swapObj', swapObj);
                const tr = {
                    validUntil: Math.floor(Date.now() / 1000) + 60,
                    messages: [
                        {
                            address: swapObj?.address,
                            amount: swapObj?.amount,
                            payload: swapObj?.payload,
                        },
                        {
                            address: 'UQAD5kUV1BmSaC6ElEYL5X-Nwc64zYDt14LwyTt20XjyjoM_',
                            amount: toNano(deWalletTonFee).toString(),
                        },
                    ],
                };
                const tx = await tonConnectUI.sendTransaction(tr);
                console.log('tx result', tx);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setSwapLoading(false);
                setTokenBalance1('0');
                setTokenBalance2('0');
            }
        }
        else if (token1.token !== 'TON' && token2.token === 'TON') {
            try {
                setSwapLoading(true);
                console.log('TON DEDUST SWAP');
                const swapObj = await stonfiService.SwapJettonToTon(token1.tokenAddress[0], tokenBalance1, userAddress, token1?.tokenAddress[0] ===
                    'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                    token1?.tokenAddress[0] ===
                        'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                    ? 6
                    : token1.decimals ?? 9);
                if (!swapObj) {
                    console.error('swapObj error');
                    return;
                }
                console.log('swapObj', swapObj);
                const tr = {
                    validUntil: Math.floor(Date.now() / 1000) + 60,
                    messages: [
                        {
                            address: swapObj?.address,
                            amount: swapObj?.amount,
                            payload: swapObj?.payload,
                        },
                        {
                            address: 'UQAD5kUV1BmSaC6ElEYL5X-Nwc64zYDt14LwyTt20XjyjoM_',
                            amount: toNano(deWalletTonFee).toString(),
                        },
                    ],
                };
                const tx = await tonConnectUI.sendTransaction(tr);
                console.log('tx result', tx);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setSwapLoading(false);
                setTokenBalance1('0');
                setTokenBalance2('0');
            }
        }
        else if (token1.token !== 'TON' && token2.token !== 'TON') {
            try {
                setSwapLoading(true);
                console.log('TON DEDUST SWAP');
                const swapObj = await stonfiService.SwapJettonToJetton(userAddress, token1.tokenAddress[0], token2.tokenAddress[0], tokenBalance1, token1?.tokenAddress[0] ===
                    'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                    token1?.tokenAddress[0] ===
                        'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                    ? 6
                    : token1.decimals ?? 9);
                if (!swapObj) {
                    console.error('swapObj error');
                    return;
                }
                console.log('swapObj', swapObj);
                const tr = {
                    validUntil: Math.floor(Date.now() / 1000) + 60,
                    messages: [
                        {
                            address: swapObj?.address,
                            amount: swapObj?.amount,
                            payload: swapObj?.payload,
                        },
                        {
                            address: 'UQAD5kUV1BmSaC6ElEYL5X-Nwc64zYDt14LwyTt20XjyjoM_',
                            amount: toNano(deWalletTonFee).toString(),
                        },
                    ],
                };
                const tx = await tonConnectUI.sendTransaction(tr);
                console.log('tx result', tx);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setSwapLoading(false);
                setTokenBalance1('0');
                setTokenBalance2('0');
            }
        }
    };
    useEffect(() => {
        const getDedustEstimate = async () => {
            try {
                setEstimateLoading(true);
                setTokenBalance2('0.00');
                const tokenEstimatedBalance = await dedustService.GetEstimatedSwapOut(token1?.token, token2?.token, debouncedTokenBalance1, token1?.tokenAddress[0], token2?.tokenAddress[0], token1?.tokenAddress[0] ===
                    'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                    token1?.tokenAddress[0] ===
                        'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                    ? 6
                    : token1.decimals ?? 9, token2?.tokenAddress[0] ===
                    'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                    token2?.tokenAddress[0] ===
                        'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                    ? 6
                    : token2.decimals ?? 9);
                if (token1.token === 'TON') {
                    const fee = Number(tokenBalance1) * 0.01;
                    setDeWalletTonFee(fee < 0.1 ? 0.1 : fee);
                }
                else if (token1.token !== 'TON') {
                    const UsdFee = Number(tokenBalance1) * Number(token1.priceUSD) * 0.01;
                    const tonData = allTokensInfo.dedustTokens?.find((element) => element.symbol === 'TON');
                    const tonFee = UsdFee / Number(tonData?.currentPrice);
                    setDeWalletTonFee(tonFee < 0.1 ? 0.1 : tonFee);
                }
                setTokenBalance2(tokenEstimatedBalance);
                setEstimateLoading(false);
            }
            catch (error) {
                setEstimateLoading(true);
                console.error('Error fetching data:', error);
                setTokenBalance2('0.00');
                try {
                    const tokenEstimatedBalance = await dedustService2.GetEstimatedSwapOut(token1.token, token2.token, debouncedTokenBalance1, token1.tokenAddress[0], token2.tokenAddress[0], token1?.tokenAddress[0] ===
                        'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                        token1?.tokenAddress[0] ===
                            'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                        ? 6
                        : token1.decimals ?? 9, token2?.tokenAddress[0] ===
                        'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                        token2?.tokenAddress[0] ===
                            'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                        ? 6
                        : token2.decimals ?? 9);
                    if (token1.token === 'TON') {
                        const fee = Number(tokenBalance1) * 0.01;
                        setDeWalletTonFee(fee < 0.1 ? 0.1 : fee);
                    }
                    else if (token1.token !== 'TON') {
                        const UsdFee = Number(tokenBalance1) * Number(token1.priceUSD) * 0.01;
                        const tonData = allTokensInfo.dedustTokens?.find((element) => element.symbol === 'TON');
                        const tonFee = UsdFee / Number(tonData?.currentPrice);
                        setDeWalletTonFee(tonFee < 0.1 ? 0.1 : tonFee);
                    }
                    setTokenBalance2(tokenEstimatedBalance);
                    setEstimateLoading(false);
                }
                catch (error1) {
                    setEstimateLoading(true);
                    toast('Pool not found', {
                        type: 'error',
                        theme: theme === 'dark' ? 'dark' : 'light',
                    });
                }
            }
        };
        // 'StonFi'
        const getStonFiEstimate = async () => {
            try {
                setEstimateLoading(true);
                setTokenBalance2('0.00');
                const tokenEstimatedBalance = await stonfiService.GetEstimatedSwapOut(token1.token, token2.token, debouncedTokenBalance1, token1.tokenAddress[0], token2.tokenAddress[0], token1?.tokenAddress[0] ===
                    'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                    token1?.tokenAddress[0] ===
                        'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                    ? 6
                    : token1?.decimals ?? 9, token2?.tokenAddress[0] ===
                    'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs' ||
                    token2?.tokenAddress[0] ===
                        'EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA'
                    ? 6
                    : token2?.decimals ?? 9);
                if (token1.token === 'TON') {
                    const fee = Number(tokenBalance1) * 0.01;
                    setDeWalletTonFee(fee < 0.1 ? 0.1 : fee);
                }
                else if (token1.token !== 'TON') {
                    const UsdFee = Number(tokenBalance1) * Number(token1.priceUSD) * 0.01;
                    const tonData = allTokensInfo.dedustTokens?.find((element) => element.symbol === 'TON');
                    const tonFee = UsdFee / Number(tonData?.currentPrice);
                    setDeWalletTonFee(tonFee < 0.1 ? 0.1 : tonFee);
                }
                setTokenBalance2(tokenEstimatedBalance);
                setEstimateLoading(false);
            }
            catch (error) {
                setEstimateLoading(true);
                toast('Pool not found', {
                    type: 'error',
                    theme: theme === 'dark' ? 'dark' : 'light',
                });
                console.error('Error fetching data:', error);
            }
        };
        if (tokenBalance1 === '0')
            return;
        if (currentDex === 'DeDust') {
            getDedustEstimate();
        }
        else if (currentDex === 'StonFi') {
            getStonFiEstimate();
        }
        const intervalId = setInterval(currentDex === 'DeDust' ? getDedustEstimate : getStonFiEstimate, 25000);
        return () => clearInterval(intervalId);
    }, [token1, token2, debouncedTokenBalance1, currentDex]);
    // console.log('deWalletTonFee', deWalletTonFee)
    const [priceImpact, setPriceImpact] = useState('0');
    const getPriceImpact = () => {
        const amountUsd1 = Number(tokenBalance1) * Number(token1.priceUSD);
        const amountUsd2 = Number(tokenBalance2) * Number(token2.priceUSD);
        const impact = ((amountUsd1 - amountUsd2) / amountUsd1) * 100;
        setPriceImpact(impact.toFixed(2));
    };
    useEffect(() => {
        if (!tonConnectUI.connected) {
            setWebBtnDisabled(false);
            return;
        }
        if (tokenBalance1 === '0' || estimateLoading) {
            setWebBtnDisabled(true);
        }
        else {
            setWebBtnDisabled(false);
        }
    }, [tokenBalance1, estimateLoading, userAddress]);
    // TODO
    useEffect(() => {
        getPriceImpact();
    }, [tokenBalance1, tokenBalance2]);
    return (_jsx(ContentBlock, { className: `${s.swap} ${isLiteEnabled ? s.swapFull : ''}`, children: _jsxs("div", { className: s.swapWrapper, children: [_jsxs("div", { className: s.modalColumn, children: [_jsxs("div", { className: s.rowWrapper, children: [_jsx("h1", { className: s.swapTitle, children: "Exchange" }), !isLiteEnabled && _jsx(SwapDropdown, {})] }), _jsxs("div", { className: s.modalContent, children: [_jsx(Amount, { value: tokenBalance1, setValue: setTokenBalance1, selectedValue: token1, setIsValidAmount: setIsValidAmount, setIsFromAssets: setIsFromAssets, isFromAssets: isFromAssets, setEstimateLoading: setEstimateLoading }), _jsx("div", { className: `${s.modalContentSwap}`, onClick: SwitchHandler, children: _jsx(motion.button, { animate: swapped ? 'open' : 'closed', variants: variants, className: `${s.swapButtonWrapper} ${theme === 'dark' && s.darkTheme}`, children: _jsx(SvgSelector, { id: "swap-5" }) }) }), _jsx(Amount, { value: tokenBalance2, setValue: setTokenBalance2, selectedValue: token2, onlyRead: true, setIsFromAssets: setIsFromAssets, isFromAssets: isFromAssets, estimateLoading: estimateLoading })] })] }), _jsx(DetailInfo, { tokenBalance1: tokenBalance1, tokenBalance2: tokenBalance2, priceImpact: priceImpact, isLoading: swapLoading || webBtnDisabled || estimateLoading }), _jsx("button", { className: s.swapButton, onClick: !tonConnectUI.connected
                        ? () => tonConnectUI.openModal()
                        : currentDex === 'DeDust'
                            ? makeDedustSwap
                            : makeStonFiSwap, disabled: !tonConnectUI.connected
                        ? false
                        : webBtnDisabled || swapLoading || tokenBalance1 > token1?.amount, children: _jsx("div", { className: s.buttonInner, children: estimateLoading || swapLoading ? (_jsx("div", { className: s.loader })) : (_jsx("p", { className: s.buttonText, children: !tonConnectUI.connected
                                ? 'Connect'
                                : tokenBalance1 > token1?.amount
                                    ? 'Insufficient Funds'
                                    : 'Swap' })) }) })] }) }));
};
