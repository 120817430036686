import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import App from './app/App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const rootElement = document.getElementById('root');
const queryClient = new QueryClient();
if (!rootElement) {
    throw new Error('rootElement is null');
}
const root = createRoot(rootElement);
root.render(_jsx(QueryClientProvider, { client: queryClient, children: _jsx(App, {}) }));
