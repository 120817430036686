import { jsx as _jsx } from "react/jsx-runtime";
import { TonConnectUIProvider } from 'delab-tonconnect-ui-react';
export const withTonConnect = (component) => () => (_jsx(TonConnectUIProvider, { manifestUrl: "https://manifests.delab.team/deswap.txt", walletsListConfiguration: {
        includeWallets: [
            {
                appName: 'dewallet',
                name: 'DeWallet',
                imageUrl: 'https://delab-team.ams3.cdn.digitaloceanspaces.com/icons/WalletAvatar.png',
                aboutUrl: 'https://wallet.tg/',
                universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
            },
        ],
    }, children: component() }));
