/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
import TonWeb from 'tonweb';
import { DEX, pTON } from '@ston-fi/sdk';
import { Address, toNano, TonClient, beginCell, TonClient4, JettonMaster } from '@ton/ton';
export function formatNumber(amount) {
    const amountAsNumber = parseFloat(amount);
    if (amountAsNumber > 0.01) {
        const formattedValue = amount.toString();
        const index = formattedValue.indexOf('.');
        if (index !== -1 && formattedValue.length - index > 2) {
            return formattedValue.slice(0, index + 3);
        }
        return formattedValue;
    }
    const formattedValue = amount.toLocaleString();
    return formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '').slice(0, 8);
}
const standbyV2Client = new TonClient({
    endpoint: 'https://toncenter.com/api/v2/jsonRPC',
    apiKey: '94d8d3dde3b37be15e77baf5c3800bcafc5dcb8ec9f50c6b14bb8ed9fd79cb62'
});
export class StonFiSwapService {
    // https://tonclient4.delabteam.com
    constructor() {
        // this._client = client
        const provider = new TonWeb.HttpProvider('https://toncenter.com/api/v2/jsonRPC', { apiKey: '94d8d3dde3b37be15e77baf5c3800bcafc5dcb8ec9f50c6b14bb8ed9fd79cb62' });
        this._router = new DEX.v1.Router({
            tonApiClient: provider
        });
    }
    async SwapJettonToJetton(userAddress, tokenAddress1, tokenAddress2, jettonAmountIn, jettonDecimals) {
        const WALLET_ADDRESS = userAddress;
        const JETTON0 = tokenAddress1;
        const JETTON1 = tokenAddress2;
        try {
            const swapTxParams = await this._router.buildSwapJettonToJettonTxParams({
                userWalletAddress: WALLET_ADDRESS,
                offerJettonAddress: JETTON0,
                offerAmount: new TonWeb.utils.BN(parseFloat(jettonAmountIn) * (10 ** jettonDecimals)),
                askJettonAddress: JETTON1,
                minAskAmount: new TonWeb.utils.BN("1"),
                queryId: 12345,
            });
            return ({
                address: swapTxParams.to.toString(),
                amount: swapTxParams.gasAmount.toString(),
                payload: TonWeb.utils.bytesToBase64(await swapTxParams.payload.toBoc())
            });
        }
        catch (err) {
            console.error(err);
            return undefined;
        }
    }
    async SwapTonToJetton(tokenAddress, AmountIn, userAddress) {
        const WALLET_ADDRESS = userAddress;
        const JETTON0 = tokenAddress;
        try {
            const tonToJettonTxParams = await this._router.buildSwapTonToJettonTxParams({
                userWalletAddress: WALLET_ADDRESS,
                proxyTonAddress: pTON.v1.address,
                offerAmount: new TonWeb.utils.BN(parseFloat(AmountIn) * (10 ** 9)),
                askJettonAddress: JETTON0,
                minAskAmount: new TonWeb.utils.BN(0.1),
                queryId: 12345,
            });
            const additionalTon = Number(toNano(AmountIn));
            return ({
                address: tonToJettonTxParams.to.toString(),
                amount: (Number(tonToJettonTxParams.gasAmount.toString())).toString(),
                payload: TonWeb.utils.bytesToBase64(await tonToJettonTxParams.payload.toBoc())
            });
        }
        catch (err) {
            console.error(err);
            return undefined;
        }
    }
    async SwapJettonToTon(tokenAddress, jettonAmountIn, userAddress, jettonDecimals) {
        const WALLET_ADDRESS = userAddress;
        const JETTON0 = tokenAddress;
        try {
            const tonToJettonTxParams = await this._router.buildSwapJettonToTonTxParams({
                userWalletAddress: WALLET_ADDRESS,
                offerJettonAddress: JETTON0,
                offerAmount: new TonWeb.utils.BN(parseFloat(jettonAmountIn) * (10 ** jettonDecimals)),
                proxyTonAddress: pTON.v1.address,
                minAskAmount: new TonWeb.utils.BN(0.1),
                queryId: 12345,
            });
            return ({
                address: tonToJettonTxParams.to.toString(),
                amount: tonToJettonTxParams.gasAmount.toString(),
                payload: TonWeb.utils.bytesToBase64(await tonToJettonTxParams.payload.toBoc())
            });
        }
        catch (err) {
            console.error(err);
            return undefined;
        }
    }
    // eslint-disable-next-line class-methods-use-this
    async GetEstimatedSwapOut(tokenName1, tokenName2, tokenAmount1, tokenAddress1, tokenAddress2, decimals1, decimals2) {
        console.log("STONFI ESTIMATE");
        if (tokenAmount1 === '0' || tokenAmount1 === '0.') {
            return '0';
        }
        if (tokenAmount1 === '') {
            return '';
        }
        const JETTON0 = tokenAddress1;
        const JETTON1 = tokenAddress2;
        if (tokenName1 === 'TON' && tokenName2 !== 'TON') {
            const pool = await this._router.getPool({
                token0: pTON.v1.address,
                token1: Address.parse(JETTON1).toString({ bounceable: true }),
            });
            if (!pool) {
                return '0';
            }
            const jettonWalletAddressType = await this.resolveJettonWalletAddressFor(Address.parse(pTON.v1.address.toString()), Address.parse('EQB3ncyBUTjZUA5EnFKR5_EnOMI9V1tTEAAPaiU71gc4TiUt'));
            if (!jettonWalletAddressType) {
                return '0';
            }
            const expectedOutputsData = await pool.getExpectedOutputs({
                amount: new TonWeb.utils.BN(parseFloat(tokenAmount1) * (10 ** 9)),
                jettonWallet: jettonWalletAddressType.toString({ bounceable: false })
            });
            const { jettonToReceive } = expectedOutputsData;
            const numberValue = Number(jettonToReceive) / (10 ** decimals2);
            return formatNumber(String(numberValue));
        }
        if (tokenName1 !== 'TON' && tokenName2 === 'TON') {
            console.log("STONFI ESTIMATE JETTON TO TON");
            const pool = await this._router.getPool({
                token0: Address.parse(JETTON0).toString({ bounceable: true }),
                token1: pTON.v1.address,
            });
            if (!pool) {
                return '0';
            }
            const jettonWalletAddressType = await this.resolveJettonWalletAddressFor(Address.parse(JETTON0), Address.parse('EQB3ncyBUTjZUA5EnFKR5_EnOMI9V1tTEAAPaiU71gc4TiUt'));
            if (!jettonWalletAddressType) {
                return '0';
            }
            const expectedOutputsData = await pool.getExpectedOutputs({
                amount: new TonWeb.utils.BN(parseFloat(tokenAmount1) * (10 ** decimals1)),
                jettonWallet: jettonWalletAddressType.toString({ bounceable: false })
            });
            const { jettonToReceive } = expectedOutputsData;
            console.log('jettonToReceive', jettonToReceive);
            const numberValue = Number(jettonToReceive) / (10 ** decimals2);
            return formatNumber(String(numberValue));
        }
        if (tokenName1 !== 'TON' && tokenName2 !== 'TON') {
            console.log("STONFI ESTIMATE JETTON TO JETTON");
            const pool = await this._router.getPool({
                token0: Address.parse(JETTON0).toString({ bounceable: true }),
                token1: Address.parse(JETTON1).toString({ bounceable: true }),
            });
            console.log('pool', pool);
            if (!pool) {
                return '0';
            }
            const poolData = await pool.getData();
            const { token0WalletAddress, token1WalletAddress, reserve0, reserve1 } = poolData;
            console.log('reserves: ', Number(reserve0), Number(reserve1));
            if (!token0WalletAddress || !token1WalletAddress) {
                return '0';
            }
            const jettonWalletAddressType = await this.resolveJettonWalletAddressFor(Address.parse(JETTON0), Address.parse('EQB3ncyBUTjZUA5EnFKR5_EnOMI9V1tTEAAPaiU71gc4TiUt'));
            if (!jettonWalletAddressType) {
                return '0';
            }
            const expectedOutputsData = await pool.getExpectedOutputs({
                amount: new TonWeb.utils.BN(parseFloat(tokenAmount1) * (10 ** decimals1)),
                jettonWallet: jettonWalletAddressType.toString({ bounceable: false })
            });
            const { jettonToReceive, protocolFeePaid, refFeePaid } = expectedOutputsData;
            console.log('jettonToReceive: ', Number(jettonToReceive));
            const numberValue = Number(jettonToReceive) / (10 ** decimals2);
            return formatNumber(String(numberValue));
        }
        return '';
    }
    async resolveJettonWalletAddressFor(jettonMasterAddress, userContractAddress) {
        const client = new TonClient4({ endpoint: 'https://mainnet-v4.tonhubapi.com' });
        try {
            const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
            const address = await jettonMaster.getWalletAddress(userContractAddress);
            return address;
        }
        catch (err) {
            // standbyV2Client
            const waletAddress = await standbyV2Client.runMethod(jettonMasterAddress, 'get_wallet_address', [
                { type: 'slice', cell: beginCell().storeAddress(userContractAddress).endCell() }
            ]);
            try {
                const cell = waletAddress.stack.readCell();
                const address = cell.beginParse().loadAddress();
                return address;
            }
            catch {
                console.error(err);
                return undefined;
            }
        }
    }
}
