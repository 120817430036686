/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
import { Asset, Factory, JettonRoot, MAINNET_FACTORY_ADDR, Pool, PoolType, VaultNative, VaultJetton, JettonWallet } from '@dedust/sdk';
import { Address, toNano, fromNano, Builder } from '@ton/ton';
export function formatNumber(amount) {
    const amountAsNumber = parseFloat(amount);
    if (amountAsNumber > 0.01) {
        const formattedValue = amount.toString();
        const index = formattedValue.indexOf('.');
        if (index !== -1 && formattedValue.length - index > 2) {
            return formattedValue.slice(0, index + 3);
        }
        return formattedValue;
    }
    const formattedValue = amount.toLocaleString();
    return formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '').slice(0, 8);
}
export class DedustSwapService {
    constructor(client) {
        this.client = client;
    }
    static packSwapStep({ poolAddress, limit, next }) {
        const res = new Builder()
            .storeAddress(poolAddress)
            .storeUint(0, 1) // reserved
            .storeCoins(limit ?? 0n)
            .storeMaybeRef(next ? this.packSwapStep(next) : null)
            .endCell();
        return res;
    }
    static packSwapParams({ deadline, recipientAddress, referralAddress, fulfillPayload, rejectPayload }) {
        const res = new Builder()
            .storeUint(deadline ?? 0, 32)
            .storeAddress(recipientAddress ?? null)
            .storeAddress(referralAddress ?? null)
            .storeMaybeRef(fulfillPayload)
            .storeMaybeRef(rejectPayload)
            .endCell();
        return res;
    }
    static buildSwapMessageTonToJetton({ amount, poolAddress, queryId, limit, swapParams, next }) {
        const body = new Builder()
            .storeUint(VaultNative.SWAP, 32)
            .storeUint(queryId ?? 0, 64)
            .storeCoins(amount)
            .storeAddress(poolAddress)
            .storeUint(0, 1)
            .storeCoins(limit ?? 0)
            .storeMaybeRef(next ? this.packSwapStep(next) : null)
            .storeRef(this.packSwapParams(swapParams ?? {}))
            .endCell();
        return body;
    }
    static buildSwapMessageJettonWallet({ amount, queryId, destination, responseAddress, customPayload, forwardAmount, forwardPayload }) {
        const body = new Builder()
            .storeUint(JettonWallet.TRANSFER, 32)
            .storeUint(queryId ?? 0, 64)
            .storeCoins(amount)
            .storeAddress(destination)
            .storeAddress(responseAddress)
            .storeMaybeRef(customPayload)
            .storeCoins(forwardAmount ?? 0)
            .storeMaybeRef(forwardPayload)
            .endCell();
        return body;
    }
    async SwapTonToJetton(tokenAddress, tonAmountIn) {
        const tonClient = this.client;
        const factory = tonClient.open(Factory.createFromAddress(MAINNET_FACTORY_ADDR));
        const jetton = tonClient.open(JettonRoot.createFromAddress(Address.parse(tokenAddress)));
        const pool = tonClient.open(Pool.createFromAddress(await factory.getPoolAddress({
            poolType: PoolType.VOLATILE,
            assets: [Asset.native(), Asset.jetton(jetton.address)]
        })));
        const nativeVault = tonClient.open(VaultNative.createFromAddress(await factory.getVaultAddress(Asset.native())));
        const lastBlock = await tonClient.getLastBlock();
        const poolState = await tonClient.getAccountLite(lastBlock.last.seqno, pool.address);
        const vaultState = await tonClient.getAccountLite(lastBlock.last.seqno, nativeVault.address);
        const amountIn = toNano(tonAmountIn); // ton swap amount
        const { amountOut: expectedAmountOut } = await pool.getEstimatedSwapOut({
            assetIn: Asset.native(),
            amountIn
        });
        // Slippage handling (1%)
        const minAmountOut = (expectedAmountOut * 99n) / 100n; // expectedAmountOut - 1%
        try {
            if (poolState.account.state.type !== 'active') {
                throw new Error('Pool is not exist.');
            }
            if (vaultState.account.state.type !== 'active') {
                throw new Error('Native Vault is not exist.');
            }
            const swapBody = DedustSwapService.buildSwapMessageTonToJetton({
                amount: amountIn,
                poolAddress: pool.address,
                limit: minAmountOut
            });
            return ({
                address: nativeVault.address.toString(),
                amount: (amountIn + toNano('0.25')).toString(),
                payload: swapBody.toBoc().toString('base64')
            });
        }
        catch (err) {
            console.error(err);
            return undefined;
        }
    }
    async SwapJettonToTon(tokenAddress, jettonAmountIn, decimals, userAddress) {
        const tonClient = this.client;
        const currentSenderAddres = Address.parse(userAddress);
        const factory = tonClient.open(Factory.createFromAddress(MAINNET_FACTORY_ADDR));
        const scaleVault = tonClient.open(await factory.getJettonVault(Address.parse(tokenAddress)));
        const scaleRoot = tonClient.open(JettonRoot.createFromAddress(Address.parse(tokenAddress)));
        const scaleWallet = tonClient.open(await scaleRoot.getWallet(currentSenderAddres));
        const amountIn = BigInt(Number(jettonAmountIn) * (10 ** decimals));
        const poolJetton = tonClient.open(Pool.createFromAddress(await factory.getPoolAddress({
            poolType: PoolType.VOLATILE,
            assets: [Asset.jetton(scaleRoot.address), Asset.native()]
        })));
        const lastBlock = await tonClient.getLastBlock();
        const poolState = await tonClient.getAccountLite(lastBlock.last.seqno, poolJetton.address);
        const vaultState = await tonClient.getAccountLite(lastBlock.last.seqno, scaleWallet.address);
        const { amountOut: expectedAmountOut } = await poolJetton.getEstimatedSwapOut({
            assetIn: Asset.jetton(scaleRoot.address),
            amountIn
        });
        const minAmountOut = (expectedAmountOut * 99n) / 100n; // expectedAmountOut - 1%
        try {
            if (poolState.account.state.type !== 'active') {
                throw new Error('Pool is not exist.');
            }
            if (vaultState.account.state.type !== 'active') {
                throw new Error('Jetton Vault is not exist.');
            }
            const swapBody = DedustSwapService.buildSwapMessageJettonWallet({
                amount: amountIn,
                destination: scaleVault.address,
                responseAddress: currentSenderAddres,
                forwardAmount: toNano('0.25'),
                forwardPayload: VaultJetton.createSwapPayload({ poolAddress: poolJetton.address, limit: minAmountOut })
            });
            return ({
                address: scaleWallet.address.toString(),
                amount: toNano('0.5').toString(),
                payload: swapBody.toBoc().toString('base64')
            });
            // return ({
            //     address: nativeVault.address.toString(),
            //     amount: (amountIn + toNano('0.25')).toString(),
            //     payload: swapBody.toBoc().toString('base64')
            // })
            // await this.sendSwapMessageWithFee({
            //     publicKey: CustomWalletData.publicKey,
            //     secretKey: CustomWalletData.secretKey,
            //     Swapfee: deWallFee,
            //     amountIn: toNano('0.25'),
            //     swapBody,
            //     contractAddress: scaleWallet.address,
            //     contractDedustFee: toNano('0.25')
            // })
        }
        catch (err) {
            console.error(err);
            return undefined;
        }
    }
    async SwapJettonToJetton(userAddress, tokenAddress1, tokenAddress2, jettonAmountIn, decimals) {
        const tonClient = this.client;
        const currentSenderAddres = Address.parse(userAddress);
        const factory = tonClient.open(Factory.createFromAddress(MAINNET_FACTORY_ADDR));
        const jetton1Vault = tonClient.open(await factory.getJettonVault(Address.parse(tokenAddress1)));
        const jettonRoot = tonClient.open(JettonRoot.createFromAddress(Address.parse(tokenAddress1)));
        const jettonWallet = tonClient.open(await jettonRoot.getWallet(currentSenderAddres));
        const amountIn = BigInt(Number(jettonAmountIn) * (10 ** decimals));
        const JETTON1 = Asset.jetton(Address.parse(tokenAddress1));
        const TON = Asset.native();
        const JETTON2 = Asset.jetton(Address.parse(tokenAddress2));
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const TON_JETTON1_Pool = tonClient.open(await factory.getPool(PoolType.VOLATILE, [TON, JETTON1]));
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const TON_JETTON2_Pool = tonClient.open(await factory.getPool(PoolType.VOLATILE, [TON, JETTON2]));
        const lastBlock = await tonClient.getLastBlock();
        const poolState = await tonClient.getAccountLite(lastBlock.last.seqno, TON_JETTON1_Pool.address);
        const vaultState = await tonClient.getAccountLite(lastBlock.last.seqno, jettonWallet.address);
        const poolState2 = await tonClient.getAccountLite(lastBlock.last.seqno, TON_JETTON2_Pool.address);
        const vaultState2 = await tonClient.getAccountLite(lastBlock.last.seqno, jettonWallet.address);
        const { amountOut: expectedAmountOut1 } = await TON_JETTON1_Pool.getEstimatedSwapOut({
            assetIn: JETTON1,
            amountIn
        });
        const minAmountOut = (expectedAmountOut1 * 99n) / 100n; // expectedAmountOut - 1% slippage
        try {
            if (poolState.account.state.type !== 'active') {
                throw new Error('Pool is not exist.');
            }
            if (vaultState.account.state.type !== 'active') {
                throw new Error('Jetton Vault is not exist.');
            }
            if (poolState2.account.state.type !== 'active') {
                throw new Error('Pool is not exist.');
            }
            if (vaultState2.account.state.type !== 'active') {
                throw new Error('Jetton Vault is not exist.');
            }
            const swapBody = DedustSwapService.buildSwapMessageJettonWallet({
                amount: amountIn,
                destination: jetton1Vault.address,
                responseAddress: currentSenderAddres,
                forwardAmount: toNano('0.25'),
                forwardPayload: VaultJetton.createSwapPayload({
                    poolAddress: TON_JETTON1_Pool.address,
                    limit: minAmountOut,
                    next: { poolAddress: TON_JETTON2_Pool.address } // next step: TON -> BOLT
                })
            });
            return ({
                address: jettonWallet.address.toString(),
                amount: toNano('0.5').toString(),
                payload: swapBody.toBoc().toString('base64')
            });
            // await this.sendSwapMessageWithFee({
            //     publicKey: CustomWalletData.publicKey,
            //     secretKey: CustomWalletData.secretKey,
            //     Swapfee: deWallFee,
            //     amountIn: toNano('0.25'),
            //     swapBody,
            //     contractAddress: jettonWallet.address,
            //     contractDedustFee: toNano('0.25')
            // })
        }
        catch (err) {
            console.error(err);
            return undefined;
        }
    }
    // eslint-disable-next-line class-methods-use-this
    async GetEstimatedSwapOut(tokenName1, tokenName2, tokenAmount1, tokenAddress1, tokenAddress2, decimals1, decimals2) {
        if (tokenAmount1 === '0' || tokenAmount1 === '0.') {
            return '0';
        }
        if (tokenAmount1 === '') {
            return '';
        }
        const tonClient = this.client;
        const factory = tonClient.open(Factory.createFromAddress(MAINNET_FACTORY_ADDR));
        if (tokenName1 === 'TON' && tokenName2 !== 'TON') {
            const JETTON = Asset.jetton(Address.parse(tokenAddress2));
            const TON = Asset.native();
            const PoolTonToJetton = tonClient.open(await factory.getPool(PoolType.VOLATILE, [TON, JETTON]));
            const { amountOut: expectedAmountOutTonToJetton } = await PoolTonToJetton.getEstimatedSwapOut({
                assetIn: TON,
                amountIn: toNano(tokenAmount1)
            });
            const numberValue = Number(expectedAmountOutTonToJetton) / (10 ** decimals2);
            return formatNumber(String(numberValue));
        }
        if (tokenName1 !== 'TON' && tokenName2 === 'TON') {
            const JETTON = Asset.jetton(Address.parse(tokenAddress1));
            const TON = Asset.native();
            const PoolJettonToTon = tonClient.open(await factory.getPool(PoolType.VOLATILE, [TON, JETTON]));
            const { amountOut: expectedAmountOutJettonToTon } = await PoolJettonToTon.getEstimatedSwapOut({
                assetIn: JETTON,
                amountIn: BigInt(Number(tokenAmount1) * (10 ** decimals1))
            });
            const numberValue = Number(fromNano(expectedAmountOutJettonToTon));
            return formatNumber(String(numberValue));
        }
        if (tokenName1 !== 'TON' && tokenName2 !== 'TON') {
            const JETTON1 = Asset.jetton(Address.parse(tokenAddress1));
            const TON = Asset.native();
            const JETTON2 = Asset.jetton(Address.parse(tokenAddress2));
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const TON_JETTON1_Pool = tonClient.open(await factory.getPool(PoolType.VOLATILE, [TON, JETTON1]));
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const TON_JETTON2_Pool = tonClient.open(await factory.getPool(PoolType.VOLATILE, [TON, JETTON2]));
            const { amountOut: expectedAmountOut1 } = await TON_JETTON1_Pool.getEstimatedSwapOut({
                assetIn: JETTON1,
                amountIn: BigInt(Number(tokenAmount1) * (10 ** decimals1))
            });
            const { amountOut: expectedAmountOut2 } = await TON_JETTON2_Pool.getEstimatedSwapOut({
                assetIn: Asset.native(),
                amountIn: expectedAmountOut1
            });
            console.log('BIGINT JETTON2', expectedAmountOut2);
            const numberValue = Number(expectedAmountOut2) / (10 ** decimals2);
            return formatNumber(String(numberValue));
        }
        return '';
    }
}
