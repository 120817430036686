import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import { useUnit } from 'effector-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-duration-format';
import { BtnVariant, Button } from '../Button';
import { ContentBlock } from '../ContentBlock';
import { tokenDetail } from '@/features/token-detail/model';
import { toggleLiteStore } from '@/features/lite/model';
import { CoinGeckoApiService, } from '@/entities/token/api/coingecko';
import DeDust from '../../../lib/images/DeDust2.png';
import STONFI from '../../../lib/images/StonFi2.png';
import Tether from '../../../lib/images/tether.png';
import { smlTokenAddr } from '../../../utils/smlTokenAddr';
import { formatNumberAmount } from '../../../utils/formatNumber';
import s from './TradesTokenTable.module.scss';
// const HOST_INDEXER = 'https://urchin-app-u5s4f.ondigitalocean.app';
// const HOST_INDEXER = 'http://localhost:7362';
const HOST_INDEXER = 'https://swap-back.delab.team';
const SwapEventSource = (address, setSwapEvent) => {
    const eventSource = new EventSource(`${HOST_INDEXER}/?address=${address}`);
    eventSource.onmessage = (event) => {
        const swap = String(event.data).includes('heartbeat')
            ? null
            : JSON.parse(event.data);
        if (swap)
            setSwapEvent(swap);
    };
    eventSource.onerror = (error) => {
        console.error('[SSE_ERROR]', error);
    };
};
const requestSwapsHistory = async (address, limit, offset) => {
    try {
        const qAddr = `?address=${address}`;
        const qLimit = `&limit=${limit}`;
        const qOffset = `&offset=${offset}`;
        const data = await fetch(`${HOST_INDEXER}/jetton/swaps${qAddr}${qLimit}${qOffset}`);
        return data.json();
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
const useLoadSwapHistory = (address) => {
    const limit = 50;
    const [swaps, setSwaps] = useState([]);
    const [offset, setOffset] = useState(0);
    const loadMore = async () => {
        const history = await requestSwapsHistory(address, limit, offset);
        if (Array.isArray(history)) {
            setOffset(limit + offset);
            setSwaps((items) => [...items, ...history]);
        }
    };
    useEffect(() => {
        if (address != '') {
            loadMore();
        }
    }, [address]);
    return [swaps, loadMore];
};
export const TradesTokenTable = () => {
    const { currentPair } = useUnit(tokenDetail);
    const { currentMainAsset } = useUnit(tokenDetail);
    const [tableData, setTableData] = useState([]);
    const [tableDataSton, setTableSton] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [swapEvent, setSwapEvent] = useState([]);
    const [visibleTokenCount, setVisibleTokenCount] = useState(20);
    const { isLiteEnabled } = useUnit(toggleLiteStore);
    const [poolLoading, setPoolLoading] = useState(false);
    const { jettonDetail } = useUnit(tokenDetail);
    const [oldSwaps, loadMoreSwaps] = useLoadSwapHistory(jettonDetail?.address || '');
    const geckoApi = new CoinGeckoApiService();
    const formatDate = (date) => {
        const now = moment();
        const dateMoment = moment(date);
        const duration = moment.duration(now.diff(dateMoment));
        // @ts-ignore
        const formattedDuration = duration.format('h[h] m[m] ago', {
            largest: 2,
            trim: 'both'
        });
        return formattedDuration;
    };
    const processSwap = (swapData) => {
        const isBuy = jettonDetail?.address !== swapData.swap?.addressIn;
        const amountIn = Number(swapData?.swap?.amountIn || 1) / Math.pow(10, Number(swapData?.jettonDataIn?.decimals || 1));
        const amountOut = Number(swapData?.swap?.amountOut || 1) / Math.pow(10, Number(swapData?.jettonDataOut?.decimals || 1));
        const amountUsd = isBuy
            ? amountIn * Number(swapData?.jettonDataIn?.priceUsd || 1)
            : amountOut * Number(swapData?.jettonDataOut?.priceUsd || 1);
        const info = {
            symbol: swapData?.jettonDataIn?.symbol,
            fromTokenAmount: amountIn,
            toTokenAmount: amountOut,
            amountUsd,
            currentPrice: amountUsd / amountIn,
            type: isBuy ? 'Sell' : 'Buy',
            isBuy,
        };
        return {
            date: formatDate(new Date(Number(swapData?.swap?.timestamp * 1000))),
            amount: `${formatNumberAmount(isBuy ? String(info?.toTokenAmount) : String(info?.fromTokenAmount))} ${jettonDetail?.symbol}`,
            type: info?.type,
            dex: swapData?.swap?.dex,
            amountjUSDT: formatNumberAmount(String(info?.amountUsd)),
            price: formatNumberAmount(String(info?.currentPrice)),
            address: swapData?.swap?.senderAddress,
        };
    };
    const setRealTimeSwap = async () => {
        const processedSwap = await processSwap(swapEvent);
        setTableData((items) => items.length > 0 ? [
            processedSwap,
            ...items
        ] : [...swapEvent]);
    };
    useEffect(() => {
        setRealTimeSwap();
    }, [swapEvent]);
    useEffect(() => {
        if (jettonDetail) {
            SwapEventSource(jettonDetail?.address || '', setSwapEvent);
        }
    }, [jettonDetail]);
    async function processHistory() {
        const processedSwaps = [];
        for (const s of oldSwaps) {
            processedSwaps.push(processSwap(s));
        }
        setTableData(processedSwaps);
    }
    useEffect(() => {
        if (!currentPair || !currentMainAsset || isLiteEnabled)
            return;
        processHistory();
    }, [oldSwaps]);
    const handleSwapHistory = async (address) => {
        try {
            const response = await fetch(`${HOST_INDEXER}/jetton/swaps?address=${address}&limit=0&offset=0`);
            const swapHistory = await response.json();
            const processedSwaps = [];
            for (const s of swapHistory) {
                console.log(s);
                processedSwaps.push(processSwap(s));
            }
            setTableData(processedSwaps);
        }
        catch (error) {
            console.log(error);
            return [];
        }
    };
    useEffect(() => {
        if (!currentMainAsset)
            return;
        console.log(currentMainAsset, currentPair, isLiteEnabled);
        handleSwapHistory(currentMainAsset.tokenAddress[0]);
    }, [currentMainAsset]);
    useEffect(() => {
        if (!currentPair || !currentMainAsset || isLiteEnabled)
            return;
        const fetchData = async () => {
            try {
                setPoolLoading(true);
                const data = await geckoApi.getTradesData(currentPair.pool);
                if (data) {
                    const newData = data.map((el) => {
                        const { block_timestamp, from_token_address, price_to_in_usd, price_from_in_usd, volume_in_usd, tx_from_address, } = el.attributes;
                        const blockDate = new Date(block_timestamp);
                        const isBuy = currentMainAsset.tokenAddress[0] !== from_token_address;
                        const priceInUsd = isBuy ? price_to_in_usd : price_from_in_usd;
                        return {
                            date: formatDate(blockDate),
                            amount: `${formatNumberAmount(isBuy
                                ? el.attributes.to_token_amount
                                : el.attributes.from_token_amount)} ${jettonDetail?.symbol}`,
                            type: currentMainAsset.tokenAddress[0] ===
                                el.attributes.from_token_address
                                ? 'Sell'
                                : 'Buy',
                            dex: currentPair.dex,
                            amountjUSDT: formatNumberAmount(volume_in_usd),
                            price: formatNumberAmount(priceInUsd),
                            address: tx_from_address,
                        };
                    });
                    setTableSton(newData);
                }
            }
            catch (error) {
                console.error('Error fetching trade data:', error);
            }
            finally {
                setPoolLoading(false);
            }
        };
        fetchData();
    }, [currentPair, currentMainAsset, isLiteEnabled, currentPair?.dex]);
    if (!tableData || !tableDataSton)
        return _jsx(ContentBlock, { className: s.noData, children: "No data" });
    const columns = useMemo(() => [
        {
            header: () => 'Date',
            accessorKey: 'date',
            cell: (info) => {
                const data = info.getValue();
                return _jsx("div", { className: s.price, children: data });
            },
        },
        {
            header: () => 'DEX',
            accessorKey: 'dex',
            cell: (info) => {
                const data = info.getValue();
                return (_jsx("div", { className: s.price, children: _jsx("img", { src: data === 'STON' ? STONFI : DeDust, width: 24, height: 26, alt: "dex" }) }));
            },
        },
        {
            header: () => 'Amount',
            accessorKey: 'amount',
            cell: (info) => {
                const data = info.getValue();
                return _jsx("div", { className: s.percent, children: data });
            },
        },
        {
            header: () => 'Type',
            accessorKey: 'type',
            cell: (info) => {
                const data = info.getValue();
                return (_jsx("div", { className: s.type, style: { background: data === 'Buy' ? '#10B981' : '#FF1C45' }, children: data }));
            },
        },
        {
            header: () => 'Amount USD₮',
            accessorKey: 'amountjUSDT',
            cell: (info) => (_jsxs("div", { className: s.totalLiquidity, children: [_jsx("img", { src: Tether, alt: "tether" }), info.getValue()] })),
        },
        {
            header: () => 'Price',
            accessorKey: 'price',
            cell: (info) => {
                const data = info.getValue();
                return _jsxs("div", { className: s.price, children: ["$", data] });
            },
        },
        {
            header: () => 'Address',
            accessorKey: 'address',
            cell: (info) => (_jsx(Link, { to: `https://tonviewer.com/${info.getValue()}`, target: "_blank", className: s.actions, children: smlTokenAddr(info.getValue()) })),
        },
    ], []);
    const table = useReactTable({
        data: currentPair?.dex === 'STON' ? tableDataSton : tableData,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });
    const loadMoreTokens = () => {
        setVisibleTokenCount((prevTokens) => prevTokens + 20);
        loadMoreSwaps();
    };
    return (_jsxs(ContentBlock, { className: s.inner, children: [_jsxs("div", { className: s.table, children: [_jsxs("table", { className: s.table, children: [_jsx("thead", { className: s.thead, children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { className: s.tr, children: headerGroup.headers.map((header) => (_jsx("th", { className: s.th, colSpan: header.colSpan, style: { width: header.getSize() }, children: _jsxs("div", { ...{
                                                className: header.column.getCanSort() ? '' : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }, children: [flexRender(header.column.columnDef.header, header.getContext()), {
                                                    asc: ' ▲',
                                                    desc: ' ▼',
                                                }[header.column.getIsSorted()] ?? null] }) }, header.id))) }, headerGroup.id))) }), _jsx("tbody", { className: s.tbody, children: table
                                    .getRowModel()
                                    .rows?.slice(0, visibleTokenCount)
                                    .map((row) => (_jsx("tr", { className: s.tr, children: row.getVisibleCells().map((cell) => (_jsx("td", { className: s.td, children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id))) }, row.id))) })] }), !poolLoading && table.getRowModel().rows?.length < 1 && (_jsx(ContentBlock, { className: s.noData, children: "No trades data" }))] }), visibleTokenCount < table.getRowModel().rows?.length && (_jsx(Button, { variant: BtnVariant.light, onClick: loadMoreTokens, className: s.loadMoreBtn, children: "Load more" }))] }));
};
