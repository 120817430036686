import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { v1 } from 'uuid';
import { SkeletonRound } from '@/shared';
import { AppItem, AppItemSkeleton } from '@/entities/app';
import s from './CategoriesApps.module.scss';
export const CategoriesApps = ({ data, appsLoading, theme }) => {
    const groupedItems = {};
    data?.forEach((item) => {
        if (item.categories) {
            item.categories.forEach((category) => {
                if (!groupedItems[category]) {
                    groupedItems[category] = [];
                }
                groupedItems[category].push(item);
            });
        }
    });
    return (_jsxs("div", { className: s.allApps, children: [groupedItems.DeLab && (_jsxs("div", { className: s.allApps, children: [_jsx("div", { className: s.allAppsTop, children: appsLoading ? (_jsx(SkeletonRound, { height: 30, widthFull: true })) : (_jsxs(_Fragment, { children: ["DeLab ", _jsxs("span", { children: ["(", groupedItems.DeLab?.length ?? 0, ")"] })] })) }), _jsx("div", { className: s.allAppsItems, children: appsLoading
                            ? Array(6)
                                .fill(null)
                                .map(_ => _jsx(AppItemSkeleton, { theme: theme }, v1()))
                            : (groupedItems.DeLab || []).map(el => (_jsx(AppItem, { theme: theme, ...el }, v1()))) })] }, "DeLab")), Object.keys(groupedItems)
                ?.filter(category => category !== 'DeLab')
                .map(category => (_jsxs("div", { className: s.allApps, children: [_jsx("div", { className: s.allAppsTop, children: appsLoading ? (_jsx(SkeletonRound, { height: 30, widthFull: true })) : (_jsxs(_Fragment, { children: [category, _jsxs("span", { children: ["(", groupedItems[category]?.length ?? 0, ")"] })] })) }), _jsx("div", { className: s.allAppsItems, children: appsLoading
                            ? Array(6)
                                .fill(null)
                                .map(_ => _jsx(AppItemSkeleton, { theme: theme }, v1()))
                            : groupedItems[category].map(el => (_jsx(AppItem, { theme: theme, ...el }, v1()))) })] }, category)))] }));
};
