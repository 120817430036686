// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HX9yCie1T6SLM86EscpQ{outline:none;border:none;border-radius:12px;padding:14px;display:flex;align-items:center;justify-content:center;gap:10px;user-select:none;cursor:pointer;max-width:100%;font-size:17px;font-weight:540;line-height:142%}@media(max-width: 550px){.HX9yCie1T6SLM86EscpQ{font-display:15px}}.NiOGMHC60UJDe_Evho_u{color:rgba(var(--brand-primary));background:rgba(0,122,255,.1)}.NiOGMHC60UJDe_Evho_u:hover{opacity:.8}.NiOGMHC60UJDe_Evho_u:active{border:1px solid #f2e7e7}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,gBAAA,CACA,cAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,yBAfJ,sBAgBQ,iBAAA,CAAA,CAGR,sBACI,gCAAA,CACA,6BAAA,CACA,4BACI,UAAA,CAEJ,6BACI,wBAAA","sourcesContent":[".button {\n    outline: none;\n    border: none;\n    border-radius: 12px;\n    padding: 14px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n    user-select: none;\n    cursor: pointer;\n    max-width: 100%;\n    font-size: 17px;\n    font-weight: 540;\n    line-height: 142%;\n    @media (max-width: 550px) {\n        font-display: 15px;\n    }\n}\n.lightVariant {\n    color: rgba(var(--brand-primary));\n    background: rgba(0, 122, 255, 0.1);\n    &:hover {\n        opacity: 0.8;\n    }\n    &:active {\n        border: 1px solid #f2e7e7;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `HX9yCie1T6SLM86EscpQ`,
	"lightVariant": `NiOGMHC60UJDe_Evho_u`
};
export default ___CSS_LOADER_EXPORT___;
