import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo } from 'react';
import { v1 } from 'uuid';
import { FixedSizeList } from 'react-window';
import { TokenItem } from '../TokenItem/TokenItem';
import { TokenListItemSkeleton } from '../TokenItem/TokenItemSkeleton';
import s from './TokenList.module.scss';
export const TokenList = ({ tokensData, tokensLoading, notFound = false, containLinks = false }) => {
    const topTokens = ['TON', 'DFC', 'ARBUZ'];
    const sortedTokensData = useMemo(() => {
        const sortedDataCopy = [...(tokensData ?? [])];
        sortedDataCopy.sort((a, b) => {
            const amountA = parseFloat(a?.amountUSD ?? '0');
            const amountB = parseFloat(b?.amountUSD ?? '0');
            const aIsTopToken = topTokens.includes(a.token);
            const bIsTopToken = topTokens.includes(b.token);
            if (aIsTopToken && bIsTopToken) {
                return topTokens.indexOf(a.token) - topTokens.indexOf(b.token);
            }
            if (aIsTopToken) {
                return -1;
            }
            if (bIsTopToken) {
                return 1;
            }
            return amountB - amountA;
        });
        return sortedDataCopy;
    }, [tokensData, topTokens]);
    return (_jsx("div", { className: s.tokens, children: _jsx("div", { className: s.tokensList, children: !notFound ? (_jsx(_Fragment, { children: tokensLoading || sortedTokensData?.length === 0 ? (new Array(4).fill(null).map(_ => _jsx(TokenListItemSkeleton, {}, v1()))) : !sortedTokensData || sortedTokensData.length >= 1 ? (_jsx(FixedSizeList, { height: 400, itemCount: sortedTokensData.length, itemSize: 76, width: '100%', children: ({ index, style }) => (_jsx("div", { style: style, children: _jsx(MemoizedTokenItem, { priceUSD: sortedTokensData[index].priceUSD || '0', diff: sortedTokensData[index].priceDiff || '', token: sortedTokensData[index].token, tokenLogo: sortedTokensData[index].tokenLogo, amount: sortedTokensData[index].amount, amountUSD: sortedTokensData[index].amountUSD || '', tokenAddress: sortedTokensData[index].tokenAddress[0], decimals: sortedTokensData[index].decimals ?? 9, containLinks: containLinks }) }, sortedTokensData[index].token)) })) : (_jsx("p", { className: s.notFound })) })) : (_jsx("p", { className: s.notFound, children: "Not found" })) }) }));
};
const MemoizedTokenItem = React.memo(TokenItem);
