export function smlAddr(address) {
    if (!address) {
        return '';
    }
    if (address.endsWith('.ton') || address.endsWith('.me') || address.endsWith('.t.me')) {
        return address;
    }
    const length = address.length;
    const visibleCharacters = Math.min(length, 7);
    return address.slice(0, 4) + '...' + address.slice(length - visibleCharacters, length);
}
