/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function formatLargeNumber(number) {
    const abbreviations = ['', 'K', 'M', 'B', 'T', 'Q', 'QQ', 'S', 'SS', 'O', 'N', 'D'];
    function getAbbreviationIndex(number) {
        const index = Math.floor(Math.log10(number) / 3);
        return Math.min(index, abbreviations.length - 1);
    }
    function format(number, index) {
        const rounded = Math.round(number / 1000 ** index * 100) / 100;
        return `${rounded}${abbreviations[index]}`;
    }
    if (number < 1000) {
        return number.toFixed(2).replace(/\.00$/, '');
    }
    const abbreviationIndex = getAbbreviationIndex(number);
    return format(number, abbreviationIndex);
}
