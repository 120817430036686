// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ALQ0EMbiEor2KqrW00gR{padding:22px 24px;border-radius:32px;background-color:rgb(var(--background-primary));display:block;width:100%;height:100%}@media(max-width: 768px){.ALQ0EMbiEor2KqrW00gR{padding:18px 12px;border-radius:20px}}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/ContentBlock/ContentBlock.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,kBAAA,CACA,+CAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CACA,yBAPJ,sBAQQ,iBAAA,CACA,kBAAA,CAAA","sourcesContent":[".content_block {\n    padding: 22px 24px;\n    border-radius: 32px;\n    background-color: rgb(var(--background-primary));\n    display: block;\n    width: 100%;\n    height: 100%;\n    @media (max-width: 768px) {\n        padding: 18px 12px;\n        border-radius: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content_block": `ALQ0EMbiEor2KqrW00gR`
};
export default ___CSS_LOADER_EXPORT___;
