// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jWqNQVfh53nF3IHut4NZ{border-radius:16px;box-shadow:0px 4px 64px 0px rgba(0,0,0,.1);background:rgb(var(--background-primary));padding:16px;position:absolute;z-index:302;top:100px;max-width:440px;width:100%;height:250px;top:75px}@media(max-width: 768px){.jWqNQVfh53nF3IHut4NZ{left:0}}.JV6rzjE40EFFUCCjzVzd{padding:12px;border-radius:24px;background:rgb(var(--background-quaternary));height:220px;overflow:auto}`, "",{"version":3,"sources":["webpack://./src/widgets/header-search/ui/header-list/header-list.module.scss"],"names":[],"mappings":"AAAA,sBACI,kBAAA,CACA,0CAAA,CACA,yCAAA,CACA,YAAA,CACA,iBAAA,CACA,WAAA,CACA,SAAA,CACA,eAAA,CACA,UAAA,CACA,YAAA,CACA,QAAA,CACA,yBAZJ,sBAaQ,MAAA,CAAA,CAEJ,sBACI,YAAA,CACA,kBAAA,CACA,4CAAA,CACA,YAAA,CACA,aAAA","sourcesContent":[".list {\n    border-radius: 16px;\n    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.1);\n    background: rgb(var(--background-primary));\n    padding: 16px;\n    position: absolute;\n    z-index: 302;\n    top: 100px;\n    max-width: 440px;\n    width: 100%;\n    height: 250px;\n    top: 75px;\n    @media (max-width: 768px) {\n        left: 0;\n    }\n    &Inner {\n        padding: 12px;\n        border-radius: 24px;\n        background: rgb(var(--background-quaternary));\n        height: 220px;\n        overflow: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `jWqNQVfh53nF3IHut4NZ`,
	"listInner": `JV6rzjE40EFFUCCjzVzd`
};
export default ___CSS_LOADER_EXPORT___;
