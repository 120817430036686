import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NumberRenderer, SvgSelector, TokenImg, formatLargeNumber } from '@/shared';
import DeDust from '@/shared/lib/images/dedust.png';
import STONFI from '@/shared/lib/images/stonfi.png';
import s from './TokenPair.module.scss';
function formatNumber(num) {
    return parseFloat(String(num)).toLocaleString(undefined, { maximumFractionDigits: 2 });
}
export const TokenPair = ({ mainAsset, theme, updatePair, isActive = false, ...pair }) => {
    return (_jsxs("div", { className: `${s.pair} ${isActive ? s.pairActive : ''}`, onClick: () => updatePair(pair.pair), children: [_jsxs("div", { className: s.pairLeft, children: [_jsx("div", { className: s.pairLeftImg1, children: _jsx(TokenImg, { imageUrl: mainAsset?.tokenLogo, alt: "token1" }) }), _jsx("div", { className: s.pairLeftImg2, children: _jsx(TokenImg, { imageUrl: pair?.pair?.token?.cdnUrl ?? pair?.pair?.token?.imageUrl, alt: "token2" }) })] }), _jsxs("div", { className: s.pairBody, children: [_jsxs("div", { children: [_jsxs("div", { className: s.pairBodyTitle, children: [pair.pair.dex === 'DEDUST' ? (_jsx("img", { src: DeDust, alt: "dedust" })) : (_jsx("img", { src: STONFI, alt: "stonfi" })), `${mainAsset?.token}/${pair?.pair?.token?.symbol}`] }), _jsxs("div", { className: s.pairBodyLiquidity, children: [_jsx("span", { children: "Liquidity:" }), " $", pair?.pair?.balanceUsd > 0.00001 ? (formatLargeNumber(Number(pair?.pair?.balanceUsd))) : (_jsx(NumberRenderer, { value: pair?.pair?.balanceUsd ?? 0 }))] })] }), _jsx(SvgSelector, { id: "chevron-right" })] })] }));
};
