import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable no-confusing-arrow */
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { useUnit } from 'effector-react';
import { SvgSelector } from '@/shared/lib/assets/svg-selector';
import { profileModalStore, toggleProfileModal } from '@/features/profile-modal/model';
import { getUserData } from '@/features/user/model';
import s from './menu.module.scss';
export const Menu = () => {
    const isModalOpen = useUnit(profileModalStore);
    const location = useLocation();
    const [tonConnectUI, setOptions] = useTonConnectUI();
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://raw.githubusercontent.com/delab-team/manifests-images/main/DeLabLogo.png',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    const rawAddress = useTonAddress();
    useEffect(() => {
        if (rawAddress && tonConnectUI.connected) {
            getUserData(rawAddress);
        }
    }, [rawAddress, tonConnectUI.connected]);
    return (_jsxs("div", { className: s.menu, children: [_jsxs(Link, { className: `${s.menuLink} ${location.pathname === '/' && !isModalOpen ? s.menuLinkActive : ''}`, to: "/", children: [_jsx(SvgSelector, { id: "home" }), "Home"] }), _jsxs(Link, { className: `${s.menuLink} ${location.pathname === '/apps' && !isModalOpen ? s.menuLinkActive : ''}`, to: "/apps", children: [_jsx(SvgSelector, { id: "layers" }), "Apps"] }), _jsxs("div", { className: `${s.menuLink} ${isModalOpen ? s.menuLinkActive : ''}`, onClick: () => tonConnectUI.connected ? toggleProfileModal() : tonConnectUI.connectWallet(), children: [_jsx(SvgSelector, { id: "user" }), "Profile"] })] }));
};
