/* eslint-disable max-len */
import { createEvent, createStore } from 'effector';
// const initialSwapToken1: AssetType = {
//     token: 'TON',
//     tokenLogo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/11419.png',
//     amount: '15',
//     priceUSD: '3.4',
//     priceDiff: '+12%',
//     tokenAddress: [ '0:9a8da514d575d20234c3fb1395ee9138f5f1ad838abc905dc42c2389b46bd015', '0:436b4ff60f7bacf75f70d422cfb6b5ae7cd2846f0f3553ae8bcc798258923608' ]
// }
// const initialSwapToken2: AssetType = {
//     token: 'DFC',
//     tokenLogo: 'https://cache.tonapi.io/imgproxy/G8iWJLhU0kgZtKbA8j11QWRJYyCbq6E-Loq4TkMMMpA/rs:fill:200:200:1/g:no/aHR0cHM6Ly90YW4tdG91Z2gtc2x1Zy0zNTEubXlwaW5hdGEuY2xvdWQvaXBmcy9RbVhRb2pKVVB2a0dDQ2VSOVF1OFd3bWNaRjFnTERZMjhlcExMaFBZdkR5OFRr.webp',
//     amount: '16',
//     priceUSD: '3',
//     priceDiff: '+14%',
//     tokenAddress: [ '0:f6eb371de82aa9cfb5b22ca547f31fdc0fa0fbb41ae89ba84a73272ff0bf2157', '0:c288b9102a934e1a93435f6a194470da340559a95e9c9816c682ccff2215149d' ]
// }
export const updateSwapToken1 = createEvent();
export const updateSwapToken2 = createEvent();
export const swapToken1Store = createStore({});
export const swapToken2Store = createStore({});
swapToken1Store.on(updateSwapToken1, (state, updatedFields) => ({
    ...state,
    ...updatedFields
}));
swapToken2Store.on(updateSwapToken2, (state, updatedFields) => ({
    ...state,
    ...updatedFields
}));
export const currentDexStore = createStore('DeDust');
export const updateDex = createEvent();
currentDexStore.on(updateDex, (_, newMode) => newMode);
export const updateIsFromAssets = createEvent();
export const isFromAsset = createStore(true);
isFromAsset.on(updateIsFromAssets, (_, newMode) => newMode);
export const currentSwapAssets = createStore([]); // all table tokens
export const updateCurrentSwapAssets = createEvent();
currentSwapAssets.on(updateCurrentSwapAssets, (_, newAssets) => newAssets);
