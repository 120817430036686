// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mj3c3HpMhBfo6YWeyQ6V{padding:12px;border-radius:18px;background-color:rgb(var(--background-tertiary)) !important;display:flex;height:44px;align-items:center;justify-content:center;cursor:pointer;transition:all .1s ease 0s;color:rgb(var(--placeholder-primary));transition:transform .1s ease-in-out}.Mj3c3HpMhBfo6YWeyQ6V:hover{opacity:.8}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/HeaderItem/HeaderItem.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,2DAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,0BAAA,CACA,qCAAA,CACA,oCAAA,CACA,4BACI,UAAA","sourcesContent":[".item {\n    padding: 12px;\n    border-radius: 18px;\n    background-color: rgb(var(--background-tertiary)) !important;\n    display: flex;\n    height: 44px;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    transition: all 0.1s ease 0s;\n    color: rgb(var(--placeholder-primary));\n    transition: transform .1s ease-in-out;\n    &:hover {\n        opacity: 0.8;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Mj3c3HpMhBfo6YWeyQ6V`
};
export default ___CSS_LOADER_EXPORT___;
