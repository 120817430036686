// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mPXYuW6LdAaSG9rlXlzO{padding:17px 24px;background-color:rgba(var(--background-primary));color:rgb(var(--placeholder-primary));display:flex;align-items:center;justify-content:space-between;gap:5px;font-size:16px;font-weight:400;line-height:122%;width:100%;border-radius:16px;transition:all .1s ease 0s;cursor:pointer}@media(max-width: 550px){.mPXYuW6LdAaSG9rlXlzO{font-size:14px}}.mPXYuW6LdAaSG9rlXlzO:hover{opacity:.8}.EzrKIrrmooLeGoWGW645{background:#2563eb;color:#fff}`, "",{"version":3,"sources":["webpack://./src/widgets/profile-modal/ui/ProfileAddress/ProfileAddress.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,gDAAA,CACA,qCAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,OAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CACA,kBAAA,CACA,0BAAA,CACA,cAAA,CACA,yBAfJ,sBAgBQ,cAAA,CAAA,CAEJ,4BACI,UAAA,CAIR,sBACI,kBAAA,CACA,UAAA","sourcesContent":[".profileAddress {\n    padding: 17px 24px;\n    background-color: rgba(var(--background-primary));\n    color: rgb(var(--placeholder-primary));\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 5px;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 122%;\n    width: 100%;\n    border-radius: 16px;\n    transition: all 0.1s ease 0s;\n    cursor: pointer;\n    @media (max-width: 550px) {\n        font-size: 14px;\n    }\n    &:hover {\n        opacity: 0.8;\n    }\n}\n\n.alert {\n    background: #2563eb;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileAddress": `mPXYuW6LdAaSG9rlXlzO`,
	"alert": `EzrKIrrmooLeGoWGW645`
};
export default ___CSS_LOADER_EXPORT___;
