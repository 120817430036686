// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uqQ783rdn6ALyoIyucOf{padding:12px 16px 12px 16px;border-radius:18px;background-color:rgb(var(--background-tertiary));display:flex;align-items:center;justify-content:center;gap:6px;cursor:pointer;transition:all .1s ease 0s;color:#8a97aa;transition:transform .1s ease-in-out;white-space:nowrap;font-size:14px;font-weight:510;height:44px}.uqQ783rdn6ALyoIyucOf:hover{opacity:.8}.y3YYjg01oTUl3z5V_R6C{background-color:rgb(var(--brand-primary));color:#fff}.y3YYjg01oTUl3z5V_R6C .GKtJeAQBpsK5UqGjDy_w>svg>path{stroke:#fff}.GKtJeAQBpsK5UqGjDy_w{display:flex;align-items:center;justify-content:center}.GKtJeAQBpsK5UqGjDy_w>svg{height:24px;width:24px}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/TonConnectButton/TonConnectButton.module.scss"],"names":[],"mappings":"AAAA,sBACI,2BAAA,CACA,kBAAA,CACA,gDAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CACA,cAAA,CACA,0BAAA,CAEA,aAAA,CACA,oCAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CACA,4BACI,UAAA,CAGR,sBACI,0CAAA,CACA,UAAA,CACA,qDACI,WAAA,CAGR,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CAEJ,0BACI,WAAA,CACA,UAAA","sourcesContent":[".item {\n    padding: 12px 16px 12px 16px;\n    border-radius: 18px;\n    background-color: rgb(var(--background-tertiary));\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 6px;\n    cursor: pointer;\n    transition: all 0.1s ease 0s;\n    // #8A97AA\n    color: #8a97aa;\n    transition: transform 0.1s ease-in-out;\n    white-space: nowrap;\n    font-size: 14px;\n    font-weight: 510;\n    height: 44px;\n    &:hover {\n        opacity: 0.8;\n    }\n}\n.itemConnected {\n    background-color: rgb(var(--brand-primary));\n    color: #fff;\n    .icon > svg > path {\n        stroke: #fff;\n    }\n}\n.icon {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.icon > svg {\n    height: 24px;\n    width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `uqQ783rdn6ALyoIyucOf`,
	"itemConnected": `y3YYjg01oTUl3z5V_R6C`,
	"icon": `GKtJeAQBpsK5UqGjDy_w`
};
export default ___CSS_LOADER_EXPORT___;
