// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j6OYKg6zJIvIRqmivM4Y{display:flex;align-items:center;justify-content:space-between;gap:10px;padding:16px;border-radius:24px;background-color:rgba(var(--background-primary));margin-bottom:16px}.mCwJ9IXb6pkMLKfPdko1{display:flex;align-items:center;gap:16px;font-size:18px;font-weight:510;line-height:120%;color:rgb(var(--placeholder-primary))}.mCwJ9IXb6pkMLKfPdko1 img{width:40px;height:40px;border-radius:50%}@media(max-width: 550px){.mCwJ9IXb6pkMLKfPdko1 img{width:30px;height:30px}}.j6OYKg6zJIvIRqmivM4Y span{font-size:18px;font-weight:510;line-height:120%;color:rgb(var(--placeholder-primary))}`, "",{"version":3,"sources":["webpack://./src/entities/user/ui/balance-block/balance.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA,CACA,gDAAA,CACA,kBAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,qCAAA,CACA,0BACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,yBAJF,0BAKI,UAAA,CACA,WAAA,CAAA,CAIN,2BACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,qCAAA","sourcesContent":[".balance {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  padding: 16px;\n  border-radius: 24px;\n  background-color: rgba(var(--background-primary));\n  margin-bottom: 16px;\n  &Left {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    font-size: 18px;\n    font-weight: 510;\n    line-height: 120%;\n    color: rgb(var(--placeholder-primary));\n    img {\n      width: 40px;\n      height: 40px;\n      border-radius: 50%;\n      @media (max-width: 550px) {\n        width: 30px;\n        height: 30px;\n      }\n    }\n  }\n  span {\n    font-size: 18px;\n    font-weight: 510;\n    line-height: 120%;\n    color: rgb(var(--placeholder-primary));\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance": `j6OYKg6zJIvIRqmivM4Y`,
	"balanceLeft": `mCwJ9IXb6pkMLKfPdko1`
};
export default ___CSS_LOADER_EXPORT___;
