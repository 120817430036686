// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hkSB85hHv9aHDNtbZyJw{display:flex;align-items:center;gap:8px;padding:14px 16px;border-radius:16px;background-color:rgb(var(--background-tertiary)) !important;width:600px}.hkSB85hHv9aHDNtbZyJw .zxG3CbAt6yFylgrqA0_Y{border:none;outline:none;color:rgb(var(--placeholder-primary));background-color:rgb(var(--background-tertiary)) !important;width:100%}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/InputField/InputField.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA,CACA,kBAAA,CACA,2DAAA,CACA,WAAA,CACA,4CACI,WAAA,CACA,YAAA,CACA,qCAAA,CACA,2DAAA,CACA,UAAA","sourcesContent":[".input_block {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    padding: 14px 16px;\n    border-radius: 16px;\n    background-color: rgb(var(--background-tertiary)) !important;\n    width: 600px;\n    .input {\n        border: none;\n        outline: none;\n        color: rgb(var(--placeholder-primary));\n        background-color: rgb(var(--background-tertiary)) !important;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_block": `hkSB85hHv9aHDNtbZyJw`,
	"input": `zxG3CbAt6yFylgrqA0_Y`
};
export default ___CSS_LOADER_EXPORT___;
