import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { NumberRenderer, TokenImg } from '@/shared';
import { themeStore } from '@/features/theme/model';
import { toggleLite } from '@/features/lite/model';
import { SvgSelector } from '@/shared/lib/assets/svg-selector';
import s from './header-item.module.scss';
export const HeaderItem = ({ address, symbol, name, cdnUrl, imageUrl, changePrice, currentPrice, setResultData, setSearchValue, isLiteEnabled }) => {
    const currentTheme = useUnit(themeStore);
    const navigate = useNavigate();
    const cleanPercent = String(changePrice?.diff_24h ?? 0).replace('−', '').replace('+', '');
    const isDown = String(changePrice?.diff_24h)?.startsWith('−');
    const handleItem = () => {
        setResultData(undefined);
        setSearchValue('');
        navigate(`token/${address}`);
        if (isLiteEnabled) {
            toggleLite();
        }
    };
    return (_jsxs("div", { className: s.item, onClick: () => handleItem(), children: [_jsxs("div", { className: s.itemLeft, children: [_jsx(TokenImg, { imageUrl: cdnUrl ?? imageUrl, width: 32, height: 32, alt: name }), _jsx("div", { className: s.itemTitle, children: symbol.length > 8 ? symbol?.slice(0, 8) + '...' : symbol })] }), _jsxs("div", { className: s.itemRight, children: [_jsxs("div", { className: s.itemPrice, children: ["$", _jsx(NumberRenderer, { value: currentPrice ?? 0 })] }), _jsxs("div", { className: s.itemPercent, style: { display: cleanPercent === '0' ? 'none' : '' }, children: [isDown ? _jsx(SvgSelector, { id: "percent-down" }) : _jsx(SvgSelector, { id: "percent-up" }), _jsxs("div", { className: `${s.percent} ${isDown ? s.down : s.up}`, children: [parseFloat(cleanPercent).toFixed(2) ?? '0', "%"] })] })] })] }));
};
