import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTonAddress } from 'delab-tonconnect-ui-react';
import { useUnit } from 'effector-react';
import { Loader, TradesTokenTable } from '@/shared';
import { TokenChart, TokenInfo, TokenStats } from '@/widgets/token';
import { SwapModule } from '@/widgets/swap';
import { tokensInfo, updateAllTokensLoading } from '@/features/tokens/model';
import { userBaseInfo } from '@/features/user/model';
import { currentPairsStore, getTokenDetail, tokenDetail, } from '@/features/token-detail/model';
import { toggleLiteStore } from '@/features/lite/model';
import { themeStore } from '@/features/theme/model';
import LITE_BG from '@/shared/lib/images/lite-bg.png';
import DARK_BG from '@/shared/lib/images/dark-bg.png';
import s from './Token.module.scss';
const Token = () => {
    const { assets } = useUnit(userBaseInfo);
    const allTokensInfo = useUnit(tokensInfo);
    const { isLiteEnabled } = useUnit(toggleLiteStore);
    const { loadingDetail, currentPair } = useUnit(tokenDetail);
    const currentTheme = useUnit(themeStore);
    const { id } = useParams();
    const rawAddress = useTonAddress();
    const navigate = useNavigate();
    const assetsRef = useRef([]);
    const allPairs = useUnit(currentPairsStore);
    useEffect(() => {
        assetsRef.current = assets;
    }, [assets]);
    useEffect(() => {
        if (!id) {
            navigate('/');
            return;
        }
        if (!allTokensInfo.aggregatedAssets || allTokensInfo.aggregatedAssets.length === 0) {
            navigate('/');
            return;
        }
        updateAllTokensLoading(true);
        getTokenDetail({
            jettonAddress: id,
            allTokens: allTokensInfo.aggregatedAssets,
            userInfo: assetsRef.current ?? [],
            isLiteEnabled,
        });
        updateAllTokensLoading(false);
    }, [
        id,
        allTokensInfo.aggregatedAssets,
        isLiteEnabled,
        assetsRef.current?.length > 1,
        rawAddress,
    ]);
    return (_jsx(_Fragment, { children: loadingDetail ? (_jsx("div", { className: s.loading, children: _jsx(Loader, {}) })) : (_jsx(_Fragment, { children: !isLiteEnabled ? (_jsxs("div", { className: s.token, children: [_jsx(TokenStats, {}), _jsxs("div", { className: s.main, children: [_jsx("div", { className: s.tokenInfo, children: _jsx(TokenInfo, { allPairs: allPairs, isLiteEnabled: isLiteEnabled }) }), _jsx("div", { className: s.swapModule, children: _jsx(SwapModule, {}) }), _jsx("div", { className: s.tokenChart, children: _jsx(TokenChart, { currentPair: currentPair }) })] }), _jsx(TradesTokenTable, {})] })) : (_jsxs("div", { className: s.tokenLite, children: [currentTheme === 'light' ? (_jsx("img", { className: s.tokenLiteBg, src: LITE_BG, alt: "lite bg" })) : (_jsx("img", { className: s.tokenLiteBg, src: DARK_BG, alt: "lite bg" })), _jsxs("div", { className: s.tokenLiteInner, children: [_jsx(TokenInfo, { allPairs: allPairs, isLiteEnabled: isLiteEnabled }), _jsx(SwapModule, {})] })] })) })) }));
};
export default Token;
