import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { useState } from 'react';
import { useUnit } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';
import { v1 } from 'uuid';
import { updateDex, currentDexStore } from '@/features/swap/model';
import { DexSvgSelector, SvgSelector } from '@/shared';
import s from './dropdownSwap.module.scss';
const DexMock = ['DeDust', 'StonFi'];
export const SwapDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const currentDex = useUnit(currentDexStore);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const selectHandler = (el) => {
        updateDex(el);
        toggleDropdown();
    };
    return (_jsxs("div", { className: s.dropdownWrapper, children: [_jsxs("button", { className: s.dropdownButton, onClick: toggleDropdown, children: [_jsxs("div", { className: s.innerWrapper, children: [_jsx("div", { className: s.img, children: _jsx(DexSvgSelector, { id: currentDex }) }), _jsx("h3", { className: s.lable, children: currentDex })] }), _jsx("div", { className: `${isOpen && s.modalOpen} ${s.chevronWrapper}`, children: _jsx(SvgSelector, { id: "chevron-swap" }) })] }), _jsx(AnimatePresence, { children: isOpen && (_jsx(motion.ul, { initial: { opacity: 0, y: -10 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: -10 }, transition: { duration: 0.3 }, className: s.dropdownContent, children: DexMock.map(elem => _jsxs("li", { className: s.dropdownItem, onClick: () => selectHandler(elem), children: [_jsx("div", { className: s.img, children: _jsx(DexSvgSelector, { id: elem }) }), _jsx("h3", { className: s.lable, children: elem })] }, v1())) })) })] }));
};
