/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable implicit-arrow-linebreak */
import { createEvent, createStore } from 'effector';
export const toggleLite = createEvent();
const initialLiteEnabled = localStorage.getItem('initialLite') === 'on' || localStorage.getItem('initialLite') === null;
export const toggleLiteStore = createStore({ isLiteEnabled: initialLiteEnabled })
    .on(toggleLite, state => {
    const newState = { isLiteEnabled: !state.isLiteEnabled };
    localStorage.setItem('initialLite', newState.isLiteEnabled ? 'on' : 'off');
    return newState;
});
toggleLiteStore.watch(({ isLiteEnabled }) => {
    localStorage.setItem('initialLite', isLiteEnabled ? 'on' : 'off');
});
