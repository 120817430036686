import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const TopTokensSelector = ({ id, theme, color }) => {
    const isLightTheme = theme === 'light';
    switch (id) {
        case '1':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 224 224", fill: "none", children: [_jsx("path", { d: "M-29 236.841C-4.33333 190.174 53.1 113.041 85.5 177.841C126 258.841 248.5 182.841 160 111.341C71.5 39.8407 240.5 -57.6593 285.5 19.8407C330.5 97.3407 377.5 94.8407 417.5 43.3407C457.5 -8.15934 552.5 75.3407 493.5 131.341C434.5 187.341 532.5 276.841 588 208.841C643.5 140.841 690 137.341 730.5 208.841C771 280.341 890 214.341 842 150.341C794 86.3407 862.5 10.3407 924.5 70.8407C986.5 131.341 1063.5 116.341 1043.5 43.3407C1023.5 -29.6593 1158.5 -70.6593 1181.5 24.8407C1204.5 120.341 1276.5 125.341 1307.5 70.8407C1338.5 16.3407 1427 26.8407 1446 93.8407C1465 160.841 1618 132.841 1572.5 49.3407C1527 -34.1593 1763 -45.1593 1723 65.8407", stroke: `url(#gradient-${color})`, strokeWidth: "30" }), _jsx("defs", { children: _jsxs("linearGradient", { id: `gradient-${color}`, x1: "848.034", y1: "-31", x2: "848.034", y2: "350.153", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { style: {
                                        stopColor: isLightTheme ? 'white' : '#272B2F',
                                        stopOpacity: isLightTheme ? 0.35 : 1,
                                        offset: isLightTheme ? undefined : '0.26',
                                    } }), _jsx("stop", { offset: "1", style: { stopColor: color } })] }) })] }));
        case '2':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 224 224", fill: "none", children: [_jsx("path", { d: "M-277 236.841C-252.333 190.174 -194.9 113.041 -162.5 177.841C-122 258.841 0.5 182.841 -88 111.341C-176.5 39.8407 -7.5 -57.6593 37.5 19.8407C82.5 97.3407 129.5 94.8407 169.5 43.3407C209.5 -8.15934 304.5 75.3407 245.5 131.341C186.5 187.341 284.5 276.841 340 208.841C395.5 140.841 442 137.341 482.5 208.841C523 280.341 642 214.341 594 150.341C546 86.3407 614.5 10.3407 676.5 70.8407C738.5 131.341 815.5 116.341 795.5 43.3407C775.5 -29.6593 910.5 -70.6593 933.5 24.8407C956.5 120.341 1028.5 125.341 1059.5 70.8407C1090.5 16.3407 1179 26.8407 1198 93.8407C1217 160.841 1370 132.841 1324.5 49.3407C1279 -34.1593 1515 -45.1593 1475 65.8407", stroke: `url(#gradient-${color})`, strokeOpacity: "0.8", strokeWidth: "30" }), _jsx("defs", { children: _jsxs("linearGradient", { id: `gradient-${color}`, x1: "600.034", y1: "-31", x2: "600.034", y2: "350.153", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { style: {
                                        stopColor: isLightTheme ? 'white' : '#272B2F',
                                        stopOpacity: isLightTheme ? 0.35 : 1,
                                        offset: isLightTheme ? undefined : '0.26',
                                    } }), _jsx("stop", { offset: "1", style: { stopColor: color } })] }) })] }));
        case '3':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 224 224", fill: "none", children: [_jsx("path", { d: "M-525 236.841C-500.333 190.174 -442.9 113.041 -410.5 177.841C-370 258.841 -247.5 182.841 -336 111.341C-424.5 39.8407 -255.5 -57.6593 -210.5 19.8407C-165.5 97.3407 -118.5 94.8407 -78.5 43.3407C-38.5 -8.15934 56.5 75.3407 -2.5 131.341C-61.5 187.341 36.5 276.841 92 208.841C147.5 140.841 194 137.341 234.5 208.841C275 280.341 394 214.341 346 150.341C298 86.3407 366.5 10.3407 428.5 70.8407C490.5 131.341 567.5 116.341 547.5 43.3407C527.5 -29.6593 662.5 -70.6593 685.5 24.8407C708.5 120.341 780.5 125.341 811.5 70.8407C842.5 16.3407 931 26.8407 950 93.8407C969 160.841 1122 132.841 1076.5 49.3407C1031 -34.1593 1267 -45.1593 1227 65.8407", stroke: `url(#gradient-${color})`, strokeOpacity: "0.8", strokeWidth: "30" }), _jsx("defs", { children: _jsxs("linearGradient", { id: `gradient-${color}`, x1: "352.034", y1: "-31", x2: "352.034", y2: "350.153", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { style: {
                                        stopColor: isLightTheme ? 'white' : '#272B2F',
                                        stopOpacity: isLightTheme ? 0.35 : 1,
                                        offset: isLightTheme ? undefined : '0.26',
                                    } }), _jsx("stop", { offset: "1", style: { stopColor: color } })] }) })] }));
        case '4':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 224 224", fill: "none", children: [_jsx("path", { d: "M-773 236.841C-748.333 190.174 -690.9 113.041 -658.5 177.841C-618 258.841 -495.5 182.841 -584 111.341C-672.5 39.8407 -503.5 -57.6593 -458.5 19.8407C-413.5 97.3407 -366.5 94.8407 -326.5 43.3407C-286.5 -8.15934 -191.5 75.3407 -250.5 131.341C-309.5 187.341 -211.5 276.841 -156 208.841C-100.5 140.841 -54 137.341 -13.5 208.841C27 280.341 146 214.341 98 150.341C50 86.3407 118.5 10.3407 180.5 70.8407C242.5 131.341 319.5 116.341 299.5 43.3407C279.5 -29.6593 414.5 -70.6593 437.5 24.8407C460.5 120.341 532.5 125.341 563.5 70.8407C594.5 16.3407 683 26.8407 702 93.8407C721 160.841 874 132.841 828.5 49.3407C783 -34.1593 1019 -45.1593 979 65.8407", stroke: `url(#gradient-${color})`, strokeOpacity: "0.8", strokeWidth: "30" }), _jsx("defs", { children: _jsxs("linearGradient", { id: `gradient-${color}`, x1: "104.034", y1: "-31", x2: "104.034", y2: "350.153", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { style: {
                                        stopColor: isLightTheme ? 'white' : '#272B2F',
                                        stopOpacity: isLightTheme ? 0.35 : 1,
                                        offset: isLightTheme ? undefined : '0.26',
                                    } }), _jsx("stop", { offset: "1", style: { stopColor: color } })] }) })] }));
        case '5':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 224 224", fill: "none", children: [_jsx("path", { d: "M-1021 236.841C-996.333 190.174 -938.9 113.041 -906.5 177.841C-866 258.841 -743.5 182.841 -832 111.341C-920.5 39.8407 -751.5 -57.6593 -706.5 19.8407C-661.5 97.3407 -614.5 94.8407 -574.5 43.3407C-534.5 -8.15934 -439.5 75.3407 -498.5 131.341C-557.5 187.341 -459.5 276.841 -404 208.841C-348.5 140.841 -302 137.341 -261.5 208.841C-221 280.341 -102 214.341 -150 150.341C-198 86.3407 -129.5 10.3407 -67.5 70.8407C-5.5 131.341 71.5 116.341 51.5 43.3407C31.5 -29.6593 166.5 -70.6593 189.5 24.8407C212.5 120.341 284.5 125.341 315.5 70.8407C346.5 16.3407 435 26.8407 454 93.8407C473 160.841 626 132.841 580.5 49.3407C535 -34.1593 771 -45.1593 731 65.8407", stroke: `url(#gradient-${color})`, strokeOpacity: "0.8", strokeWidth: "30" }), _jsx("defs", { children: _jsxs("linearGradient", { id: `gradient-${color}`, x1: "-143.966", y1: "-31", x2: "-143.966", y2: "350.153", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { style: {
                                        stopColor: isLightTheme ? 'white' : '#272B2F',
                                        stopOpacity: isLightTheme ? 0.35 : 1,
                                        offset: isLightTheme ? undefined : '0.26',
                                    } }), _jsx("stop", { offset: "1", style: { stopColor: color } })] }) })] }));
        case '6':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 224 224", fill: "none", children: [_jsx("path", { d: "M-1269 236.841C-1244.33 190.174 -1186.9 113.041 -1154.5 177.841C-1114 258.841 -991.5 182.841 -1080 111.341C-1168.5 39.8407 -999.5 -57.6593 -954.5 19.8407C-909.5 97.3407 -862.5 94.8407 -822.5 43.3407C-782.5 -8.15934 -687.5 75.3407 -746.5 131.341C-805.5 187.341 -707.5 276.841 -652 208.841C-596.5 140.841 -550 137.341 -509.5 208.841C-469 280.341 -350 214.341 -398 150.341C-446 86.3407 -377.5 10.3407 -315.5 70.8407C-253.5 131.341 -176.5 116.341 -196.5 43.3407C-216.5 -29.6593 -81.5 -70.6593 -58.5 24.8407C-35.5 120.341 36.5 125.341 67.5 70.8407C98.5 16.3407 187 26.8407 206 93.8407C225 160.841 378 132.841 332.5 49.3407C287 -34.1593 523 -45.1593 483 65.8407", stroke: `url(#gradient-${color})`, strokeOpacity: "0.8", strokeWidth: "30" }), _jsx("defs", { children: _jsxs("linearGradient", { id: `gradient-${color}`, x1: "-391.966", y1: "-31", x2: "-391.966", y2: "350.153", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { style: {
                                        stopColor: isLightTheme ? 'white' : '#272B2F',
                                        stopOpacity: isLightTheme ? 0.35 : 1,
                                        offset: isLightTheme ? undefined : '0.26',
                                    } }), _jsx("stop", { offset: "1", style: { stopColor: color } })] }) })] }));
        case '7':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", viewBox: "0 0 224 224", fill: "none", children: [_jsx("path", { d: "M-1517 236.841C-1492.33 190.174 -1434.9 113.041 -1402.5 177.841C-1362 258.841 -1239.5 182.841 -1328 111.341C-1416.5 39.8407 -1247.5 -57.6593 -1202.5 19.8407C-1157.5 97.3407 -1110.5 94.8407 -1070.5 43.3407C-1030.5 -8.15934 -935.5 75.3407 -994.5 131.341C-1053.5 187.341 -955.5 276.841 -900 208.841C-844.5 140.841 -798 137.341 -757.5 208.841C-717 280.341 -598 214.341 -646 150.341C-694 86.3407 -625.5 10.3407 -563.5 70.8407C-501.5 131.341 -424.5 116.341 -444.5 43.3407C-464.5 -29.6593 -329.5 -70.6593 -306.5 24.8407C-283.5 120.341 -211.5 125.341 -180.5 70.8407C-149.5 16.3407 -61 26.8407 -42 93.8407C-23 160.841 130 132.841 84.5 49.3407C39 -34.1593 275 -45.1593 235 65.8407", stroke: `url(#gradient-${color})`, strokeOpacity: "0.8", strokeWidth: "30" }), _jsx("defs", { children: _jsxs("linearGradient", { id: `gradient-${color}`, x1: "-639.966", y1: "-31", x2: "-639.966", y2: "350.153", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { style: {
                                        stopColor: isLightTheme ? 'white' : '#272B2F',
                                        stopOpacity: isLightTheme ? 0.35 : 1,
                                        offset: isLightTheme ? undefined : '0.26',
                                    } }), _jsx("stop", { offset: "1", style: { stopColor: color } })] }) })] }));
        default:
            return null;
    }
};
