import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useUnit } from 'effector-react';
import Home from './Home';
import Apps from './Apps';
import Token from './Token';
import { sharedConfigRoutes, MainLayout, Loader } from '@/shared';
import { allTokensLoadingStore, getAllTokens } from '@/features/tokens/model';
import { setLoadingDetail } from '@/features/token-detail/model';
import { toggleLiteStore } from '@/features/lite/model';
import 'react-toastify/dist/ReactToastify.css';
const { RouteName } = sharedConfigRoutes;
const { HOME_PAGE, APPS_PAGE, TOKEN_PAGE } = RouteName;
const routes = [
    {
        path: HOME_PAGE,
        component: Home
    },
    {
        path: APPS_PAGE,
        component: Apps
    },
    {
        path: TOKEN_PAGE,
        component: Token
    }
];
const routesContent = routes.map(({ path, component: Component }) => (_jsx(Route, { path: path, element: _jsx(Component, {}) }, path)));
export const Routing = () => {
    const { pathname } = useLocation();
    const { isLiteEnabled } = useUnit(toggleLiteStore);
    const allTokensLoading = useUnit(allTokensLoadingStore);
    useEffect(() => {
        if (allTokensLoading) {
            getAllTokens();
        }
    }, [allTokensLoading]);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (pathname === '/') {
            setLoadingDetail(false);
        }
    }, [pathname]);
    return (_jsx(_Fragment, { children: allTokensLoading ? (_jsx(Loader, {})) : (_jsx(MainLayout, { children: _jsx(Suspense, { fallback: _jsx(_Fragment, {}), children: _jsxs(Routes, { children: [routesContent, _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/" }) })] }) }) })) }));
};
