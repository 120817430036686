import { jsx as _jsx } from "react/jsx-runtime";
import { themeStore } from '@/features/theme/model';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import CoinWhite from '@/shared/lib/images/coin-white.png';
import CoinBlack from '@/shared/lib/images/coin-dark.png';
export const TokenImg = ({ imageUrl, className, alt = "token", width, height, }) => {
    const currentTheme = useUnit(themeStore);
    const fallbackImage = useMemo(() => (currentTheme === 'dark' ? CoinBlack : CoinWhite), [currentTheme]);
    return (_jsx("img", { src: imageUrl ?? fallbackImage, alt: alt, className: className, width: width, height: height, onError: (e) => {
            const target = e.currentTarget;
            target.src = fallbackImage;
            target.alt = "fallback token image";
        } }));
};
