// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UoPcid6DslpTWoJ5zQRI{width:100%;overflow:visible;padding:0 12px}`, "",{"version":3,"sources":["webpack://./src/widgets/token/ui/TopTokensList/TopTokensList.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":[".tokens_list {\n  width: 100%;\n  overflow: visible;\n  padding: 0 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokens_list": `UoPcid6DslpTWoJ5zQRI`
};
export default ___CSS_LOADER_EXPORT___;
