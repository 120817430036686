// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sisW6QWNcsSCqTgr1T_m{width:100%;overflow-x:hidden}`, "",{"version":3,"sources":["webpack://./src/pages/Apps/Apps.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,iBAAA","sourcesContent":[".apps {\n    width: 100%;\n    overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"apps": `sisW6QWNcsSCqTgr1T_m`
};
export default ___CSS_LOADER_EXPORT___;
