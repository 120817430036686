import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useEffect } from 'react';
import { FixedSizeList } from 'react-window';
import { Address } from 'ton-core';
import { useUnit } from 'effector-react';
import { currentDexStore, swapToken1Store, swapToken2Store, updateDex, updateSwapToken1, updateSwapToken2, } from '@/features/swap/model';
import { userBaseInfo } from '@/features/user/model';
import { themeStore } from '@/features/theme/model';
import { getDetailInfo, setPair, tokenDetail } from '@/features/token-detail/model';
import { tokensInfo } from '@/features/tokens/model';
import { TokenPairLite } from '../TokenPairLite';
import s from './token-pairs-lite.module.scss';
import { TokensPairsLiteSkeleton } from '../TokenPairLite/tokens-pair-lite-skeleton';
export const TokenPairsLite = ({ allPairs, isLiteEnabled, firstRender, setFirstRender, }) => {
    const userInfo = useUnit(userBaseInfo);
    const theme = useUnit(themeStore);
    const { currentPair } = useUnit(tokenDetail);
    const allTokensInfo = useUnit(tokensInfo);
    const token1 = useUnit(swapToken1Store);
    const token2 = useUnit(swapToken2Store);
    const currentDex = useUnit(currentDexStore);
    const updatePair = useCallback((pair) => {
        if (!pair || !isLiteEnabled)
            return;
        const parseAddress = (address) => Address.parse(address).toRawString();
        const findAsset = (tokenAddress) => userInfo?.assets?.find((asset) => parseAddress(asset?.tokenAddress[0]) === parseAddress(tokenAddress));
        const getTokenDetails = (token, asset) => ({
            token: token?.symbol,
            tokenLogo: token?.cdnUrl ?? token?.imageUrl,
            amount: asset?.amount ?? '0',
            priceUSD: String(token?.currentPrice),
            priceDiff: token?.changePrice?.diff_24h,
            tokenAddress: [token?.address],
            decimals: asset?.decimals ?? 9,
        });
        const firstAsset = findAsset(pair?.token1?.address);
        const firstToken = getTokenDetails(pair?.token1, firstAsset);
        const secondAsset = findAsset(pair?.token2?.address);
        const secondToken = getTokenDetails(pair?.token2, secondAsset);
        updateDex(pair.dex === 'STON' ? 'StonFi' : 'DeDust');
        updateSwapToken1(firstToken);
        updateSwapToken2(secondToken);
        // @ts-ignore
        setPair(pair);
        setFirstRender(false);
        const token = allTokensInfo?.aggregatedAssets?.find((el) => Address.parse(el?.address).toString() ===
            Address.parse(pair?.token1?.address).toString());
        if (token) {
            getDetailInfo(token);
        }
    }, [userInfo, isLiteEnabled, allTokensInfo]);
    useEffect(() => {
        if (!isLiteEnabled || !allPairs)
            return;
        if (currentPair?.token1?.address === token1?.tokenAddress[0] &&
            currentPair?.token2?.address === token2?.tokenAddress[0])
            return;
        const findSwapPair = allPairs?.find((el) => (el?.token1?.address === token1?.tokenAddress[0] && el?.token2?.address === token2?.tokenAddress[0]) ||
            (el?.token1?.address === token2?.tokenAddress[0] && el?.token2?.address === token1?.tokenAddress[0]));
        if (findSwapPair && findSwapPair !== currentPair) {
            updatePair(findSwapPair);
        }
    }, [currentPair, token1, token2, isLiteEnabled, allPairs]);
    if (!allPairs)
        return _jsx("div", { className: s.noData, children: "No data" });
    return (_jsx("div", { className: s.inner, children: !allPairs.every((el) => el.token1) ? (_jsx(FixedSizeList, { height: 500, itemCount: 10, itemSize: 62, width: '100%', children: ({ index, style }) => (_jsx("div", { style: style, children: _jsx(TokensPairsLiteSkeleton, {}, index) })) })) : (_jsx(FixedSizeList, { height: 500, itemCount: allPairs.length, itemSize: 62, width: '100%', children: ({ index, style }) => {
                const pair = allPairs[index];
                if (!pair)
                    return null;
                return (_jsx("div", { style: style, children: _jsx(MemoizedPairsItem, { updatePair: updatePair, theme: theme, pair: pair, isActive: firstRender
                            ? index === 0
                            : pair?.token1?.address ===
                                currentPair?.token1?.address &&
                                pair?.token2?.address === currentPair?.token2?.address }) }, index));
            } })) }));
};
const MemoizedPairsItem = memo(TokenPairLite);
