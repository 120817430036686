import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { AppsApi } from '@/entities/app';
import { themeStore } from '@/features/theme/model';
import { AllApps, CategoriesApps } from '../';
export const AppsList = () => {
    const currentTheme = useUnit(themeStore);
    const [appsData, setAppsData] = useState(undefined);
    const [appsLoading, setAppsLoading] = useState(false);
    const api = new AppsApi();
    useEffect(() => {
        const fetchData = async () => {
            try {
                setAppsLoading(true);
                const items = await api.getItems('en');
                setAppsData(items);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setAppsLoading(false);
            }
        };
        fetchData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(CategoriesApps, { data: appsData, theme: currentTheme, appsLoading: appsLoading }), _jsx(AllApps, { data: appsData, theme: currentTheme, appsLoading: appsLoading })] }));
};
