import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { useState, useEffect, useCallback, useRef } from 'react';
import { useUnit } from 'effector-react';
import { debounce } from 'lodash';
import { SvgSelector, getSearchValue } from '@/shared';
import { tokensInfo } from '@/features/tokens/model';
import { SearchApi } from '@/entities/search';
import { HeaderList } from '../header-list';
import s from './header-search.module.scss';
export const HeaderSearch = ({ placeholder = 'Search', width, setIsActiveSearch, isLiteEnabled }) => {
    const storeData = useUnit(tokensInfo);
    const [searchValue, setSearchValue] = useState('');
    const [resultData, setResultData] = useState(undefined);
    const searchApi = new SearchApi();
    const inputRef = useRef(null);
    const listRef = useRef(null);
    const debouncedSearch = useCallback(debounce(async (value) => {
        if (!value) {
            setResultData(undefined);
            setIsActiveSearch(false);
            return;
        }
        const transformedSearchValue = getSearchValue(value);
        const lowerCaseSearchValue = transformedSearchValue.toLowerCase();
        const foundTokens = storeData?.aggregatedAssets?.filter(el => el.name.toLowerCase().startsWith(lowerCaseSearchValue)
            || el.symbol.toLowerCase().startsWith(lowerCaseSearchValue)
            || el.address.toLowerCase().startsWith(lowerCaseSearchValue));
        const foundTokensWithTotalLiquidity = foundTokens.map((token) => {
            const totalLiquidity = Object.values(token?.liquidity).reduce((acc, curr) => acc + curr, 0);
            return { ...token, totalLiquidity };
        });
        // Sort by totalLiquidity and slice the first 7 results
        const sortedTokens = foundTokensWithTotalLiquidity.sort((a, b) => b.totalLiquidity - a.totalLiquidity).slice(0, 7);
        if (foundTokens.length >= 1) {
            setResultData(sortedTokens);
        }
        else {
            const res = await searchApi.getSearchData(transformedSearchValue);
            setResultData([
                {
                    name: res?.metadata.symbol ?? '',
                    symbol: res?.metadata?.symbol ?? '',
                    imageUrl: res?.metadata.image ?? '',
                    address: res?.metadata.address ?? '',
                    totalSupply: parseFloat(res?.total_supply ?? '0'),
                    currentPrice: 0,
                    changePrice: {
                        diff_7d: '0',
                        diff_24h: '0',
                        diff_30d: '0'
                    },
                    marketCap: 0,
                    liquidity: {
                        dedust: 0,
                        ston: 0
                    },
                    volume: {
                        dedust: {},
                        ston: {}
                    },
                    score: 0,
                    decimals: 9,
                    holdersCount: 0,
                    description: '',
                    availableDex: []
                }
            ]);
        }
    }, 300), [storeData]);
    useEffect(() => {
        debouncedSearch(searchValue);
    }, [searchValue, debouncedSearch]);
    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef.current && !inputRef.current.contains(event.target) && listRef.current && !listRef.current.contains(event.target)) {
                setResultData(undefined);
                setSearchValue('');
                if (setIsActiveSearch) {
                    setIsActiveSearch(false);
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [inputRef.current && resultData && _jsx("div", { className: s.blur }), _jsxs("div", { className: s.input_block, style: { width: width || '100%' }, children: [_jsx("div", { className: s.icon, children: _jsx(SvgSelector, { id: "search" }) }), _jsx("input", { ref: inputRef, className: s.input, placeholder: placeholder, value: searchValue, onChange: (e) => {
                            setSearchValue(e.target.value);
                            setIsActiveSearch(true);
                        } }), searchValue.length > 0 && (_jsx("button", { className: s.methodInputClear, onClick: () => setSearchValue(''), children: _jsx(SvgSelector, { id: "clear" }) }))] }), resultData && resultData?.length >= 1 && (_jsx(HeaderList, { resultData: resultData, setResultData: setResultData, listRef: listRef, setSearchValue: setSearchValue, isLiteEnabled: isLiteEnabled }))] }));
};
