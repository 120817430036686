// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mjAyHlYOuXhIi30079L0{display:flex;align-items:center;justify-content:center;height:100vh;width:100%}.mjAyHlYOuXhIi30079L0 video{width:200px;height:200px}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,UAAA,CAEA,4BACE,WAAA,CACA,YAAA","sourcesContent":[".loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  width: 100%;\n  // background-color: rgb(var(--background-tertiary));\n  video {\n    width: 200px;\n    height: 200px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `mjAyHlYOuXhIi30079L0`
};
export default ___CSS_LOADER_EXPORT___;
