import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { HeaderItem, LiteToggler, SvgSelector, TonConnectButton } from '@/shared';
import { themeStore, toggleTheme } from '@/features/theme/model';
import { toggleLiteStore } from '@/features/lite/model';
import { HeaderSearch } from '@/widgets/header-search';
import s from './Header.module.scss';
export const Header = () => {
    const currentTheme = useUnit(themeStore);
    const { isLiteEnabled } = useUnit(toggleLiteStore);
    const location = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 769px)' });
    const [isActiveSearch, setIsActiveSearch] = useState(false);
    useEffect(() => {
        document.body.setAttribute('data-theme', currentTheme);
    }, [currentTheme]);
    return (_jsxs("nav", { className: `${s.header} ${!isActiveSearch ? s.headerActive : ''}`, children: [_jsx(HeaderSearch, { placeholder: "Search", width: 440, setIsActiveSearch: setIsActiveSearch, isLiteEnabled: isLiteEnabled }), _jsxs("div", { className: s.actions, children: [location.pathname !== '/' && location.pathname !== '/apps' && (_jsx(LiteToggler, { isLiteEnabled: isLiteEnabled })), _jsx(HeaderItem, { color: "grey", onClick: () => toggleTheme(), children: _jsx(SvgSelector, { id: currentTheme === 'light' ? 'moon' : 'sun' }) }), !isMobile && (_jsx(TonConnectButton, {}))] })] }));
};
