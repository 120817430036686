import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import Chart from './Chart';
import { CoinGeckoApiService } from '@/entities/token/api/coingecko';
import { ContentBlock } from '@/shared';
import s from './TokenChart.module.scss';
const timeframes = [
    { timeframe: 'minute', interval: 1 },
    { timeframe: 'minute', interval: 5 },
    { timeframe: 'minute', interval: 15 },
    { timeframe: 'hour', interval: 1 },
    { timeframe: 'hour', interval: 4 },
    { timeframe: 'hour', interval: 12 },
    { timeframe: 'day', interval: 1 },
];
export const TokenChart = ({ currentPair }) => {
    const [activeTab, setActiveTab] = useState('minute');
    const geckoApi = new CoinGeckoApiService();
    const [candlesData, setCandlesData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            if (currentPair) {
                try {
                    const selectedTimeframe = timeframes.find(item => item.timeframe === activeTab);
                    if (selectedTimeframe) {
                        const res = await geckoApi.getCandlesData(currentPair.pool, selectedTimeframe.timeframe, selectedTimeframe.interval, 'usd', 1, Date.now(), 'base');
                        if (res) {
                            setCandlesData(res);
                        }
                    }
                }
                catch (error) {
                    console.error('Error fetching candlestick data:', error);
                }
            }
        };
        fetchData();
    }, [currentPair, activeTab]);
    return (_jsx(ContentBlock, { className: s.wp, children: _jsx("div", { className: s.top, children: _jsx(Chart, { data: candlesData }) }) }));
};
