import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTonConnectUI } from 'delab-tonconnect-ui-react';
import { SvgSelector } from '@/shared/lib/assets/svg-selector';
import { toggleProfileModal } from '@/features/profile-modal/model';
import s from './LogoutButton.module.scss';
export const LogoutButton = () => {
    const [tonConnectUI, setOptions] = useTonConnectUI();
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://raw.githubusercontent.com/delab-team/manifests-images/main/DeLabLogo.png',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    const handleDisconnect = () => {
        tonConnectUI.disconnect();
        toggleProfileModal();
    };
    return (_jsxs("button", { className: s.logoutBtn, onClick: handleDisconnect, children: [_jsx(SvgSelector, { id: "logout" }), " Logout"] }));
};
