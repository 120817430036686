import { CoinGeckoApiService } from "../../../../entities/token/api/coingecko";
const convertResolutionToNumber = (resolution) => {
    switch (resolution) {
        case '1':
            return 60;
        case '5':
            return 300;
        case '15':
            return 900;
        case '60':
            return 3600;
        case '240':
            return 14400;
        case '720':
            return 43200;
        case '1440':
            return 86400;
        default:
            return 60;
    }
};
const apiGeckoTerminal = new CoinGeckoApiService();
const getPoints = async (poolInfo, before_timestamp) => {
    try {
        const res = await apiGeckoTerminal.getCandlesData(poolInfo.address, poolInfo.period, poolInfo.periodSize, poolInfo.currency, poolInfo.limit, before_timestamp, poolInfo.token);
        return res?.data?.attributes?.ohlcv_list || [];
    }
    catch (error) {
        console.error('Error fetching candlestick data:', error);
        return [];
    }
};
const getPeriod = (number) => {
    switch (number) {
        case 60:
            return {
                period: 'minute',
                periodSize: 1,
            };
        case 300:
            return {
                period: 'minute',
                periodSize: 5,
            };
        case 900:
            return {
                period: 'minute',
                periodSize: 15,
            };
        case 3600:
            return {
                period: 'hour',
                periodSize: 1,
            };
        case 14400:
            return {
                period: 'hour',
                periodSize: 4,
            };
        case 43200:
            return {
                period: 'hour',
                periodSize: 12,
            };
        case 86400:
            return {
                period: 'day',
                periodSize: 1,
            };
        default:
            return {
                period: 'minute',
                periodSize: 1,
            };
    }
};
const map = new Map();
export default (poolInfo, config) => {
    return {
        onReady: (callback) => {
            console.log('[onReady]: Method call');
            setTimeout(() => callback(config.mainConfig));
        },
        resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
            const symbolInfo = {
                ...config.symbolsConfig,
                exchange: 0.0,
                minmov: 1,
                minmovement: 1,
                minmovement2: 0,
                minmov2: 0,
                pricescale: 100,
                has_intraday: true,
                has_no_volume: false,
                supported_resolutions: config.mainConfig.supported_resolutions,
                volume_precision: 2,
                has_weekly_and_monthly: true,
                has_empty_bars: false,
                data_status: 'streaming',
            };
            onSymbolResolvedCallback(symbolInfo);
        },
        getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
            const resolutionRaw = convertResolutionToNumber(resolution);
            try {
                console.log('getBars', periodParams, resolutionRaw);
                const paramsPointsReq = {
                    address: poolInfo.address,
                    period: getPeriod(resolutionRaw).period,
                    periodSize: getPeriod(resolutionRaw).periodSize,
                    currency: poolInfo.currency,
                    limit: periodParams.countBack,
                    token: poolInfo.type,
                };
                let newPoints = await getPoints(paramsPointsReq, periodParams.to);
                let bars = [];
                newPoints = newPoints.sort((a, b) => a[0] - b[0]);
                if (newPoints.length === 0) {
                    onHistoryCallback([], { noData: true });
                    return;
                }
                for (let i = 0; i < newPoints.length; i++) {
                    const [time, open, high, low, close, volume] = newPoints[i];
                    if (!open || !high || !low || !close) {
                        continue;
                    }
                    if (time < periodParams.from || time > periodParams.to) {
                        continue;
                    }
                    bars.push({
                        time: time * 1000,
                        open: open,
                        high: high,
                        low: low,
                        close: close,
                        volume: volume || 0, // Ensure volume is set
                    });
                }
                if (periodParams.firstDataRequest && bars.length > 0) {
                    map.set(symbolInfo.full_name, {
                        ...bars[bars.length - 1],
                    });
                }
                onHistoryCallback(bars);
            }
            catch (error) {
                console.error('Error in getBars:', error);
                onErrorCallback(error);
            }
        },
        subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
            console.log("[subscribeBars]: Method call with subscribeUID:", subscribeUID);
        },
        unsubscribeBars: (subscriberUID) => {
            console.log("[unsubscribeBars]: Method call with subscriberUID:", subscriberUID);
        },
    };
};
