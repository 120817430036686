/* eslint-disable max-len */
export const tokensData = [
    {
        token: 'TON',
        tokenLogo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/11419.png',
        amount: '0',
        amountUSD: '0.00',
        tokenAddress: ['EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c']
    },
    {
        token: 'DFC',
        amount: '0',
        amountUSD: '0.00',
        tokenLogo: 'https://i.tonscan.org/resize/zLizDLindVxRBw4DMg6-fBHQqi3EgyRe2Rs-oUGmOGU/f:jpg/rs:fit:72:72:0/aXBmczovL1FtWFFvakpVUHZrR0NDZVI5UXU4V3dtY1pGMWdMRFkyOGVwTExoUFl2RHk4VGs.jpg',
        tokenAddress: ['0:f6eb371de82aa9cfb5b22ca547f31fdc0fa0fbb41ae89ba84a73272ff0bf2157', '0:c288b9102a934e1a93435f6a194470da340559a95e9c9816c682ccff2215149d']
    },
    {
        token: 'ARBUZ',
        amount: '0',
        amountUSD: '0.00',
        tokenLogo: 'https://s2.coinmarketcap.com/static/img/coins/200x200/29320.png',
        tokenAddress: ['0:0cd8a583a7d94dd18bf1bdf49b234af28c15f033bd2b6a4a4d2076ee1136ad45', '0:0cd8a583a7d94dd18bf1bdf49b234af28c15f033bd2b6a4a4d2076ee1136ad45']
    }
];
