// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OapQHLRpNIIyrrEnIAzL{display:flex;align-items:center;gap:16px}@media(max-width: 550px){.OapQHLRpNIIyrrEnIAzL{margin-bottom:20px}}@media(max-width: 420px){.OapQHLRpNIIyrrEnIAzL{flex-direction:column}.OapQHLRpNIIyrrEnIAzL button{width:100%}}`, "",{"version":3,"sources":["webpack://./src/widgets/profile-modal/ui/ProfileModalInner/ProfileModalInner.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,yBAJF,sBAKI,kBAAA,CAAA,CAEF,yBAPF,sBAQI,qBAAA,CACA,6BACE,UAAA,CAAA","sourcesContent":[".modalActions {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  @media (max-width: 550px) {\n    margin-bottom: 20px;\n  }\n  @media (max-width: 420px) {\n    flex-direction: column;\n    button {\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalActions": `OapQHLRpNIIyrrEnIAzL`
};
export default ___CSS_LOADER_EXPORT___;
