import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { NumberRenderer, SkeletonRound, formatNumber, SvgSelector, TokenImg } from '@/shared';
import { updateIsFromAssets } from '@/features/swap/model';
import { toggleSelectAssetsModal, selectAssetsModalStore, } from '@/features/select-assets-modal/model';
import { themeStore } from '@/features/theme/model';
import s from './amount.module.scss';
export const Amount = ({ value, selectedValue, onlyRead = false, disabled = false, setIsValidAmount, setIsFromAssets, isFromAssets, setEstimateLoading, estimateLoading, setValue, }) => {
    const isModalOpen = useUnit(selectAssetsModalStore);
    const currentTheme = useUnit(themeStore);
    const handleOpenModal = () => {
        if (onlyRead) {
            updateIsFromAssets(false);
            console.log('updateIsFromAssets(false)');
        }
        else if (!onlyRead) {
            updateIsFromAssets(true);
            console.log('updateIsFromAssets(true)');
        }
        toggleSelectAssetsModal();
    };
    const thisAsset = isFromAssets !== onlyRead;
    const [animationStyle, setAnimationStyle] = useState(isModalOpen ? s.modalOpen : s.modalClose);
    useEffect(() => {
        if (thisAsset) {
            setAnimationStyle(isModalOpen ? s.modalOpen : s.modalClose);
        }
        else if (!thisAsset) {
            setAnimationStyle(s.modalClose);
        }
    }, [thisAsset, isModalOpen]);
    const validateButton = (amount) => {
        const amountValue = parseFloat(amount.replace(',', '.'));
        return (/^\d+(\.\d+)?$/.test(String(amountValue)) &&
            amountValue >= 0.00001 &&
            amountValue <= 1500000);
    };
    useEffect(() => {
        const sanitizedAmount = value.replace(',', '.');
        if (validateButton(sanitizedAmount) && setIsValidAmount) {
            setIsValidAmount(true);
        }
        else if (!validateButton(sanitizedAmount) && setIsValidAmount) {
            setIsValidAmount(false);
        }
    }, [value, selectedValue]);
    return (_jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.topWrapper, children: _jsx("p", { className: s.topTitle, children: onlyRead ? 'To' : 'From' }) }), _jsxs("button", { onClick: () => {
                    if (!disabled)
                        handleOpenModal();
                }, className: `${s.selectBtn} ${s.selectBtnMain} ${animationStyle}`, children: [_jsxs("div", { className: s.rowWrapper, children: [_jsxs("div", { className: s.selectBtnInner, children: [_jsx(TokenImg, { imageUrl: selectedValue?.tokenLogo, width: 32, height: 32, alt: selectedValue?.token }), _jsxs("div", { children: [_jsx("p", { className: s.selectBtnToken, children: selectedValue?.token }), _jsxs("div", { className: s.usdPriseWrapper, children: [selectedValue?.priceUSD &&
                                                        selectedValue?.priceUSD !== 'undefined' &&
                                                        !Number.isNaN(Number(selectedValue?.priceUSD)) &&
                                                        selectedValue?.priceUSD !== '0' &&
                                                        selectedValue?.priceUSD !== undefined && (_jsxs("p", { className: s.selectBtnAmount, children: ["$", _jsx(NumberRenderer, { value: Number(selectedValue?.priceUSD ?? 0) })] })), typeof selectedValue?.priceDiff === 'string' &&
                                                        selectedValue?.priceDiff !== '0' &&
                                                        selectedValue?.priceDiff !== undefined &&
                                                        selectedValue?.priceDiff !== 'undefined' && (_jsx("p", { className: `${s.selectBtnAmount} ${selectedValue?.priceDiff?.startsWith('+')
                                                            ? s.upChange
                                                            : s.downChange}`, children: selectedValue?.priceDiff }))] })] })] }), _jsxs("div", { children: [selectedValue?.amount &&
                                        selectedValue?.amount !== '0' &&
                                        selectedValue?.amount !== 'undefined' ? (_jsx("p", { className: `${s.selectBtnToken} ${s.rightText}`, children: formatNumber(selectedValue?.amount) })) : (_jsx("p", { className: `${s.selectBtnToken} ${s.rightText}`, children: "0" })), selectedValue?.priceUSD &&
                                        selectedValue?.priceUSD !== '0' &&
                                        !Number.isNaN(Number(selectedValue?.priceUSD)) &&
                                        selectedValue?.priceUSD !== 'undefined' && (_jsxs("p", { className: `${s.selectBtnAmount} ${s.rightText}`, children: ["$", formatNumber(String(Number(selectedValue?.priceUSD) *
                                                Number(selectedValue?.amount)))] }))] })] }), _jsx(SvgSelector, { id: "chevron-swap" })] }), _jsx("div", { className: s.amountInner, children: onlyRead && estimateLoading ? (_jsx(SkeletonRound, { height: 23, widthCustom: '100%' })) : (_jsx("input", { value: value, 
                    // onFocus={() => {
                    //     storeMain.setShowNavigation(false)
                    // }}
                    // onBlur={() => {
                    //     storeMain.setShowNavigation(true)
                    // }}
                    placeholder: "0", inputMode: "decimal", disabled: onlyRead, pattern: "[0-9]*[.,]?[0-9]*", onChange: (e) => {
                        if (setEstimateLoading) {
                            setEstimateLoading(true);
                        }
                        let inputValue = e.target.value.replace(/,/g, '.');
                        inputValue = inputValue.replace(/^0+(?!$|\.)/, '');
                        inputValue = inputValue.replace(/[^0-9.]+/, '');
                        inputValue = inputValue.length === 0 ? '0' : inputValue;
                        const isValidInput = /^-?\d*\.?\d*$/.test(inputValue);
                        if (isValidInput) {
                            setValue(inputValue);
                        }
                    }, className: s.amount })) })] }));
};
