import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { useState, memo, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { FixedSizeList } from 'react-window';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { Address } from 'ton-core';
import classNames from 'classnames';
import { ContentBlock, TabSelector, SvgSelector, smlAddr, formatLargeNumber } from '@/shared';
import { currentMainAssetStore, setPair, tokenDetail, } from '@/features/token-detail/model';
import { themeStore } from '@/features/theme/model';
import { currentDexStore, swapToken1Store, swapToken2Store, updateDex, updateSwapToken1, updateSwapToken2, } from '@/features/swap/model';
import { userBaseInfo } from '@/features/user/model';
import { TokenPair } from '../TokenPair';
import s from './TokenInfo.module.scss';
import { TokenPairsLite } from '../TokenPairsLite';
import { TokensPairSkeleton } from '../TokenPair/token-pair-skeleton';
export const TokenInfo = ({ allPairs, isLiteEnabled }) => {
    const tabs = ['Pairs', 'Information'];
    const [activeTab, setActiveTab] = useState('Pairs');
    const mainAsset = useUnit(currentMainAssetStore);
    const userInfo = useUnit(userBaseInfo);
    const theme = useUnit(themeStore);
    const { jettonDetail } = useUnit(tokenDetail);
    const [firstRender, setFirstRender] = useState(false);
    const token1 = useUnit(swapToken1Store);
    const token2 = useUnit(swapToken2Store);
    const currentDex = useUnit(currentDexStore);
    useEffect(() => {
        setFirstRender(true);
    }, []);
    const { currentPair } = useUnit(tokenDetail);
    const handleContractCopy = () => {
        if (!jettonDetail?.address)
            return;
        toast('The Jetton contract address has been copied', {
            type: 'success',
            theme: theme === 'dark' ? 'dark' : 'light',
        });
        copy(jettonDetail?.address);
    };
    const updatePair = (currentPair) => {
        console.log('Start Update Pair');
        if (mainAsset === null) {
            console.log('mainAsset === null FAIL');
            return;
        }
        const currentAsset = userInfo.assets.find((asset) => Address.parse(asset.tokenAddress[0]).toRawString() ===
            Address.parse(currentPair.token.address).toRawString());
        const amount = currentAsset ? currentAsset.amount : '0';
        const amountUSD = currentAsset ? currentAsset.amountUSD : '0';
        const decimals = currentAsset ? currentAsset.decimals : 9;
        const secondToken = {
            token: currentPair?.token?.symbol,
            tokenLogo: currentPair?.token?.cdnUrl ?? currentPair?.token?.imageUrl,
            amount,
            amountUSD,
            priceUSD: (currentPair?.token?.currentPrice ?? 0).toString(),
            priceDiff: currentPair?.token?.changePrice?.diff_24h,
            tokenAddress: [currentPair?.token?.address],
            decimals,
        };
        updateDex(currentPair.dex === 'STON' ? 'StonFi' : 'DeDust');
        updateSwapToken1(mainAsset);
        updateSwapToken2(secondToken);
        setPair(currentPair);
    };
    useEffect(() => {
        if (isLiteEnabled || !allPairs || !currentPair || !token1 || !token2)
            return;
        const currDex = currentDex === 'StonFi' ? 'STON' : currentDex;
        const isCurrentPairMatching = currentPair?.mainJettonAddress === token1?.tokenAddress[0] &&
            currentPair?.token?.address === token2?.tokenAddress[0] &&
            currentPair?.dex?.toLowerCase() === currDex?.toLowerCase();
        const isCurrentPairReversed = currentPair?.mainJettonAddress === token2?.tokenAddress[0] &&
            currentPair?.token?.address === token1?.tokenAddress[0] &&
            currentPair?.dex?.toLowerCase() === currDex?.toLowerCase();
        if (isCurrentPairMatching || isCurrentPairReversed) {
            return;
        }
        const findSwapPair = allPairs?.filter((pair) => (pair?.mainJettonAddress === token1?.tokenAddress[0] &&
            pair?.token?.address === token2?.tokenAddress[0]) ||
            (pair?.mainJettonAddress === token2?.tokenAddress[0] &&
                pair?.token?.address === token1?.tokenAddress[0]));
        if (!findSwapPair)
            return;
        const selectedPair = findSwapPair?.find((pair) => pair?.dex?.toLowerCase() === currDex?.toLowerCase());
        if (selectedPair) {
            updatePair(selectedPair);
        }
    }, [token1, token2, currentDex]);
    return (_jsx(ContentBlock, { withoutPadding: true, className: classNames(activeTab === 'Pairs' ? s.pairsInner : s.infoInner, {
            [s.h595]: isLiteEnabled,
        }), children: _jsxs("div", { className: s.token_info, style: { borderRadius: activeTab === 'Pairs' ? '25px' : '' }, children: [_jsx("div", { className: activeTab === 'Pairs' ? s.pairsSelector : '', children: _jsx(TabSelector, { tabs: tabs, activeTab: activeTab, setActiveTab: setActiveTab }) }), activeTab === 'Information' && (_jsxs("div", { className: s.information, children: [(jettonDetail?.description ||
                            jettonDetail?.address ===
                                'EQD26zcd6Cqpz7WyLKVH8x_cD6D7tBrom6hKcycv8L8hV0GP') && (_jsxs("div", { className: s.description, children: [_jsx("div", { className: s.title, children: "Description" }), _jsx("div", { className: s.value, children: jettonDetail?.address ===
                                        'EQD26zcd6Cqpz7WyLKVH8x_cD6D7tBrom6hKcycv8L8hV0GP'
                                        ? 'This is an integral utility social token with a wide open functionality. Technically it has mixed utility properties used in many Web3-services. Economically this is an asset freely wrapped in the crypto market. All actions of DF Capital are aimed at the development of social services and interaction between users of the TON blockchain and other blockchains of the cryptospace.'
                                        : jettonDetail?.description })] })), _jsxs("div", { className: s.stat, children: [_jsx("div", { className: s.title, children: "Total supply" }), _jsx("div", { className: s.amount, children: formatLargeNumber(jettonDetail?.totalSupply ?? 0) })] }), _jsxs("div", { className: s.stat, children: [_jsx("div", { className: s.title, children: "Holders count" }), _jsx("div", { className: s.amount, children: formatLargeNumber(jettonDetail?.holdersCount ?? 0) })] }), jettonDetail?.address && (_jsxs("div", { className: s.contract, children: [_jsx("div", { className: s.title, children: "Contract" }), _jsxs("div", { className: s.address, onClick: handleContractCopy, children: [smlAddr(jettonDetail?.address), _jsx(SvgSelector, { id: "copy" })] })] }))] })), activeTab === 'Pairs' && !isLiteEnabled ? (_jsx(_Fragment, { children: allPairs ? (allPairs.every((el) => el.pool) ? (_jsx(FixedSizeList, { height: 500, itemCount: allPairs.length, itemSize: 72, width: '100%', children: ({ index, style }) => (_jsx("div", { style: style, children: _jsx(MemoizedPairsItem, { updatePair: updatePair, theme: theme, mainAsset: mainAsset, pair: allPairs[index], isActive: currentPair?.pool === allPairs[index]?.pool }) }, allPairs[index].balanceUsd)) })) : (_jsx(FixedSizeList, { height: 500, itemCount: 10, itemSize: 72, width: '100%', children: ({ index, style }) => (_jsx("div", { style: style, children: _jsx(TokensPairSkeleton, {}) }, index)) }))) : (_jsx("div", { className: s.noData, children: "No pairs data" })) })) : (allPairs &&
                    activeTab === 'Pairs' && (_jsx(TokenPairsLite, { allPairs: allPairs, isLiteEnabled: isLiteEnabled, firstRender: firstRender, setFirstRender: setFirstRender })))] }) }));
};
const MemoizedPairsItem = memo(TokenPair);
